import { Card } from 'flowbite-react';
import { Link } from 'react-router-dom';
import trackEvent, { MetricEventType } from '../../utils/trackEvent';

function Account() {
  const actionButtonClassName = `cursor-pointer bg-primary-600 hover:bg-primary-500 select-none text-white focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-primary-900 mt-auto w-full`;

  function handleStartDiscountCampaignScreen() {
    trackEvent(MetricEventType.MAIN_SCREEN_BUTTON1);
  }

  function handleStartUnitEconomyScreen() {
    trackEvent(MetricEventType.MAIN_SCREEN_BUTTON2);
  }

  return (
    <div>
      <div
        style={{ height: 'calc(100vh - 130px)' }}
        className="flex justify-center items-center gap-10">

        <Card className="w-[300px] h-[200px] justify-center">
          <h2 className="text-center text-xl font-semibold">Расчёт акций</h2>
          <Link
            onClick={handleStartDiscountCampaignScreen}
            className={actionButtonClassName}
            to="/account/discount-campaigns">
            Начать
          </Link>
        </Card>

        <Card className="w-[300px] h-[200px] justify-center">
          <h2 className="text-center text-xl font-semibold">
            Расчёт прибыльности закупки
          </h2>
          <Link
            onClick={handleStartUnitEconomyScreen}
            className={actionButtonClassName}
            to="/account/unit-economy">
            Начать
          </Link>
        </Card>

      </div>
    </div>
  );
}

export default Account;
