export default function formatDurationDays(days: number): string {
  let formattedDuration;
  const daysLastDigit = days % 10;
  if (daysLastDigit === 1) {
    formattedDuration = `${days} день`;
  } else if ([2, 3, 4].includes(daysLastDigit)) {
    formattedDuration = `${days} дня`;
  } else {
    formattedDuration = `${days} дней`;
  }

  return formattedDuration;
}
