import { Button, Modal } from 'flowbite-react';
import { useFormik } from 'formik';
import { useEffect, useRef } from 'react';
import { IBuyer } from '../../../api/procurement_api';
import FormInput from '../../ui/FormInput';
import { BuyerFormSchema } from './validationSchema';

export type TBuyerForm = Pick<IBuyer, 'name'>;

const formInitValues: TBuyerForm = {
  name: '',
};

interface IBuyerModalProps {
  onClose: () => void;
  isOpen: boolean;
  onSubmit: (values: TBuyerForm, isEdit: boolean) => Promise<void>;
  buyer?: IBuyer;
  isLoading: boolean;
}

function BuyerModal({
  onClose,
  isOpen,
  onSubmit,
  buyer,
  isLoading,
}: IBuyerModalProps) {
  const isEdit = !!buyer;
  const modalTitle = isEdit ? 'Редактирование байера' : 'Новый байер';
  const buttonTitle = isEdit ? 'Сохранить байера' : 'Создать байера';
  const rootRef = useRef<HTMLDivElement>(null);

  //formik state
  const formik = useFormik<TBuyerForm>({
    initialValues: formInitValues,
    onSubmit: (values) => onSubmit(values, isEdit),
    validationSchema: BuyerFormSchema,
    isInitialValid: false,
  });

  //handle onopen and on select product
  useEffect(() => {
    if (!buyer) {
      formik.resetForm();
      return;
    }

    formik.setValues({
      name: buyer.name,
    });
  }, [buyer, isOpen]);

  //close form function
  async function handleClose() {
    await formik.resetForm();
    onClose();
  }

  return (
    <div ref={rootRef}>
      <Modal
        theme={{
          root: {
            base: 'z-[10000]',
          },
        }}
        show={isOpen}
        onClose={handleClose}
        root={rootRef.current ?? undefined}>
        <Modal.Header>{modalTitle}</Modal.Header>
        <Modal.Body theme={{base: "overflow-visible p-6"}}>
          <form>
            <div className="grid grid-cols-2 gap-x-8 gap-y-3">
              <FormInput
                name="name"
                title="Название байера"
                id="name"
                placeholder="Байер"
                required
                type="text"
                onChange={formik.handleChange}
                value={formik.values.name as string}
                error={formik.errors.name}
                isTouched={!!formik.touched.name}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="flex justify-end">
          <Button onClick={onClose} color="gray">
            Отмена
          </Button>
          <Button
            className="bg-primary-600 enabled:hover:bg-primary-500"
            onClick={() => formik.handleSubmit()}
            disabled={isLoading}
            isProcessing={isLoading}>
            {buttonTitle}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default BuyerModal;
