import {
  Checkbox,
  CheckboxProps,
  Label,
  TextInput,
  TextInputProps,
} from 'flowbite-react';
import React from 'react';
import FormErrorMessage from './FormErrorMessage';

interface IFormCheckboxInputProps extends CheckboxProps {
  error?: string;
  isTouched: boolean;
}

function FormCheckboxInput({
  error,
  isTouched,
  name,
  title,
  ...rest
}: IFormCheckboxInputProps) {
  return (
    <div className='flex flex-col justify-end items-start py-3'>
      <div className="flex gap-3 items-center">
        <Checkbox {...rest} />
        <Label htmlFor={name} value={title} />
      </div>
      <FormErrorMessage error={error} touched={isTouched} />
    </div>
  );
}

export default FormCheckboxInput;
