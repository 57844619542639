import { createColumnHelper } from '@tanstack/react-table';
import moment from 'moment';
import React from 'react';
import { IProcurement } from '../../api/procurement_api';
import { DiscountCampaignSliceState } from '../../redux/discount_campaign/slice';
import EntityAction from '../EntityAction/EntityAction';
import Table from '../ui/Table';

export type DiscountCampaignType = Omit<DiscountCampaignSliceState, 'products'>;
interface IProcurementTableProps extends React.ComponentPropsWithoutRef<'div'> {
  data: IProcurement[];
  paginator?: React.ReactNode;
}

function ProcurementTable({
  data,
  paginator,
  ...props
}: IProcurementTableProps) {
  const columnHelper = createColumnHelper<IProcurement>();

  const columns = [
    columnHelper.display({
      id: 'action',
      header: 'Действия',
      cell: (props: any) => (
        <div className="flex justify-center items-center">
          <EntityAction row={props.row} />
        </div>
      ),
      size: 100,
      maxSize: 100,
    }),
    columnHelper.accessor('name', {
      header: () => (
        <span className="block w-full text-left">Наименование</span>
      ),
      cell: (info) => (
        <span className="block w-full text-left">{info.getValue()}</span>
      ),
      size: 200,
      minSize: 150,
    }),
    columnHelper.accessor('logistician.name', {
      header: 'Логист',
    }),
    columnHelper.accessor('buyer.name', {
      header: 'Байер',
    }),
    columnHelper.accessor('created_at', {
      header: 'Дата',
      cell: (info) => (
        <span>{moment(info.getValue()).format('DD.MM.YYYY')}</span>
      ),
    }),
  ];

  return (
    <Table
      data={data || []}
      columns={columns}
      paginator={paginator}
      className="table-fixed w-full"
      {...props}
    />
  );
}

export default ProcurementTable;
