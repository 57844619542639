import { Button, Modal } from 'flowbite-react';
import React, { useEffect, useRef } from 'react';
import {
  CreateDiscountCampaignProduct,
  CreateDiscountCampaignType,
  FetchDiscountCampaignProduct,
} from '../../api/discount_campaign_api';
import { useFormik } from 'formik';
import { DiscountCampagnProductFormSchema } from './validationSchema';
import DiscountCampaigProductForm from './DiscountCampaigProductForm';
import { useTypedDispatch } from '../../redux/hooks';
import { setStepIndex } from '../../redux/onboarding_tour/slice';
import { OnboardingTourStep } from '../../redux/onboarding_tour/steps';

export type DiscountCampaignProductForm = CreateDiscountCampaignProduct & {
  id?: number;
};
const formInitValues: DiscountCampaignProductForm = {
  brand: '',
  title: '',
  collection: '',
  supplier_num: '' as any,
  nomenclature: '' as any,
  barcode: '' as any,
  site_lifetime: 0,
  turnover_rate: 0,
  marketplace_in_stock_amount: 0,
  seller_in_stock_amount: 0,
  planned_discount_price: 0,
  current_retail_price: 0,
  current_discount: 0,
  final_discount: 0,
  price: 0,
  commision_rate: 0,
  logistic_expense: 0,
  commision_reduction: 0,
  is_enabled: false,
};

interface INewDiscountCampaignModalProps {
  onClose: () => void;
  isOpen: boolean;
  onSubmit: (
    values: DiscountCampaignProductForm,
    isEdit: boolean,
  ) => Promise<void>;
  discountCampaignProduct: FetchDiscountCampaignProduct | null;
  isLoading: boolean;
}

function DiscountCampagnProductModal({
  onClose,
  isOpen,
  onSubmit,
  discountCampaignProduct,
  isLoading,
}: INewDiscountCampaignModalProps) {
  const isEdit = !!discountCampaignProduct;
  const modalTitle = isEdit ? 'Редактирование товара' : 'Новый товар';
  const buttonTitle = isEdit ? 'Сохранить товар' : 'Добавить товар';
  const rootRef = useRef<HTMLDivElement>(null);

  //formik state
  const formik = useFormik<DiscountCampaignProductForm>({
    initialValues: formInitValues,
    onSubmit: (values) => onSubmit(values, isEdit),
    validationSchema: DiscountCampagnProductFormSchema,
    isInitialValid: false,
  });

  //handle onopen and on select product
  useEffect(() => {
    if (!discountCampaignProduct) {
      formik.resetForm();
      return;
    }

    const {
      product: { brand, collection, nomenclature, title },
      id,
      ...rest
    } = discountCampaignProduct;

    formik.setValues({
      id,
      brand,
      title,
      collection,
      nomenclature,
      ...rest,
    });
  }, [discountCampaignProduct, isOpen]);

  //close form function
  async function handleClose() {
    await formik.resetForm();
    onClose();
  }

  return (
    <div ref={rootRef}>
      <Modal
        theme={{
          root: {
            base: 'z-[10000]',
          },
        }}
        show={isOpen}
        onClose={handleClose}
        root={rootRef.current ?? undefined}>
        <Modal.Header>{modalTitle}</Modal.Header>
        <Modal.Body>
          <DiscountCampaigProductForm
            values={formik.values}
            errors={formik.errors}
            handleChange={formik.handleChange}
            isEdit={isEdit}
            touched={formik.touched}
          />
        </Modal.Body>
        <Modal.Footer className="flex justify-end">
          <Button onClick={onClose} color="gray">
            Отмена
          </Button>
          <Button
            className="bg-primary-600 enabled:hover:bg-primary-500"
            onClick={() => formik.handleSubmit()}
            disabled={isLoading}
            isProcessing={isLoading}>
            {buttonTitle}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default DiscountCampagnProductModal;
