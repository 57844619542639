import { FormikErrors, FormikTouched } from 'formik';
import { IFtechSupplierProducts } from '../../../api/supplier_product_api';
import FormInput from '../../ui/FormInput';
import FormSelectInput from '../../ui/FormSelectInput';
import { ICreateProcurementProduct } from './ProcurementProductModal';
import SmartSelectInput from '../../SmartSelectInput/SmartSelectInput';
import {
  useCreateProcurementMutation,
  useLazyFetchAllProcurementsQuery,
} from '../../../api/procurement_api';
import { useEffect, useMemo, useState } from 'react';
import ProcurementModal, {
  TProcurementForm,
} from '../ProcurementModal/ProcurementModal';
import { createPortal } from 'react-dom';
import FormErrorMessage from '../../ui/FormErrorMessage';
// import '../styles/Form.css';

interface INewProductUnitEconomicResearchFormProps {
  values: ICreateProcurementProduct;
  errors: FormikErrors<ICreateProcurementProduct>;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleChooseProduct?: (id: number) => void;
  isEdit: boolean;
  touched: FormikTouched<ICreateProcurementProduct>;
  fetchSupplierProducts?: (data: {
    search?: string;
    take?: number;
  }) => Promise<IFtechSupplierProducts | undefined>;
  isProcurementIdKnown?: boolean;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<ICreateProcurementProduct>>;
  setIsParentModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const currency_options = ['usd', 'rub', 'cny'];

export default function ProcurementProductModalForm({
  values,
  errors,
  handleChange,
  isEdit,
  touched,
  isProcurementIdKnown,
  setFieldValue,
  setIsParentModalVisible,
}: INewProductUnitEconomicResearchFormProps) {
  const [procurementModalOpen, setProcurementModalOpen] = useState(false);
  const [fetchAllProcurements, { data: procurementsData, isLoading }] =
    useLazyFetchAllProcurementsQuery();
  const [createProcurement, { isLoading: isCreateProcurementLoading }] =
    useCreateProcurementMutation();

  useEffect(() => {
    fetchAllProcurements({ take: 'all' });
  }, []);

  const procurementOptions = useMemo(() => {
    if (!procurementsData) return [];

    return procurementsData?.procurements.map((procurement) => ({
      label: procurement.name,
      value: procurement.id,
    }));
  }, [procurementsData]);

  async function handleSubmitProcurementForm(
    values: TProcurementForm,
    isEdit: boolean,
  ) {
    try {
      const res = await createProcurement(values).unwrap();
      setFieldValue('procurement_id', res.id);
      setIsParentModalVisible(true);
      setProcurementModalOpen(false);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <form>
      {createPortal(
        <ProcurementModal
          isLoading={isCreateProcurementLoading}
          isOpen={procurementModalOpen}
          onClose={() => {
            setProcurementModalOpen(false);
            setIsParentModalVisible(true);
          }}
          onSubmit={handleSubmitProcurementForm}
        />,
        document.body,
      )}

      {!isProcurementIdKnown && (
        <div className="grid grid-cols-2 gap-x-8 gap-y-3">
          <div>
            <SmartSelectInput
              label="Закупка"
              placeholder="Выберите закупку"
              isLoading={isLoading}
              options={procurementOptions}
              selectedValue={values.procurement_id}
              onChange={(option) =>
                setFieldValue('procurement_id', option.value)
              }
              onModalOpen={() => {
                setProcurementModalOpen(true);
                setIsParentModalVisible(false);
              }}
            />
            <FormErrorMessage
              error={errors.procurement_id}
              touched={touched.procurement_id}
            />
          </div>
        </div>
      )}

      {!isProcurementIdKnown && <hr className="mt-4 mb-2" />}

      <div className="grid grid-cols-2 gap-x-8 gap-y-3">
        {/* Product's name */}
        <FormInput
          name="name"
          title="Наименование товара"
          id="name"
          placeholder="Продукт"
          required
          type="text"
          onChange={handleChange}
          value={values.name}
          error={errors?.name}
          isTouched={!!touched?.name}
        />

        {/* Competitor URL */}
        <FormInput
          title="Ссылка на товар"
          id="competitor_url"
          placeholder=""
          required
          type="text"
          name="competitor_url"
          onChange={handleChange}
          value={values.competitor_url}
          error={errors?.competitor_url}
          isTouched={!!touched?.competitor_url}
        />

        {/* Analytics URL */}
        <FormInput
          title="Ссылка на аналитику"
          id="analytics_url"
          placeholder=""
          required
          type="text"
          name="analytics_url"
          onChange={handleChange}
          value={values.analytics_url}
          error={errors.analytics_url}
          isTouched={!!touched.analytics_url}
        />
      </div>

      <hr className="mt-4 mb-2" />

      <div className="grid grid-cols-2 gap-x-8 gap-y-3">
        {/* Marketplace product price */}
        <FormInput
          title="Цена продукта на маркетплейсе, руб"
          id="marketplace_product_price"
          placeholder=""
          required
          type="number"
          min={0}
          name="marketplace_product_price"
          onChange={handleChange}
          value={values.marketplace_product_price}
          error={errors.marketplace_product_price}
          isTouched={!!touched.marketplace_product_price}
        />

        {/* Marketplace fee */}
        <FormInput
          title="Комиссия маркетплейса, %"
          id="marketplace_fee"
          placeholder=""
          required
          type="number"
          max={100}
          min={0}
          name="marketplace_fee"
          onChange={handleChange}
          value={values.marketplace_fee}
          error={errors.marketplace_fee}
          isTouched={!!touched.marketplace_fee}
        />

        {/* Marketplace logistic expensse */}
        <FormInput
          title="Логистика маркетплейса, руб"
          id="marketplace_logistics_expense"
          placeholder=""
          required
          type="number"
          min={0}
          name="marketplace_logistics_expense"
          onChange={handleChange}
          value={values.marketplace_logistics_expense}
          error={errors.marketplace_logistics_expense}
          isTouched={!!touched.marketplace_logistics_expense}
        />

        {/* Supplier logistic expensse */}
        <FormInput
          title="Логистика поставщика, руб"
          id="supplier_logistic_expense"
          placeholder=""
          required
          type="number"
          min={0}
          name="supplier_logistic_expense"
          onChange={handleChange}
          value={values.supplier_logistic_expense}
          error={errors.supplier_logistic_expense}
          isTouched={!!touched.supplier_logistic_expense}
        />

        {/* Supplier logistic expensse */}
        <FormInput
          title="Логистика поставщика (факт), руб"
          id="supplier_logistic_expense_fact"
          placeholder=""
          required
          type="number"
          min={0}
          name="supplier_logistic_expense_fact"
          onChange={handleChange}
          value={values.supplier_logistic_expense_fact}
          error={errors.supplier_logistic_expense_fact}
          isTouched={!!touched.supplier_logistic_expense_fact}
        />

        {/* usd to rub */}
        <FormInput
          title="Курс доллара"
          id="usd_rub"
          placeholder=""
          required
          type="number"
          name="usd_rub"
          min={0}
          onChange={handleChange}
          value={values.usd_rub}
          error={errors.usd_rub}
          isTouched={!!touched.usd_rub}
        />

        {/* cny to rub */}
        <FormInput
          title="Курс юаня"
          id="cny_rub"
          placeholder=""
          required
          type="number"
          name="cny_rub"
          min={0}
          onChange={handleChange}
          value={values.cny_rub}
          error={errors.cny_rub}
          isTouched={!!touched.cny_rub}
        />

        {/* Supplier's currency */}
        <FormSelectInput
          name="currency"
          title="Валюта поставщика"
          value={values.currency}
          onChange={handleChange}
          //disabled={!newProduct}
          error={errors?.currency}
          isTouched={!!touched?.currency}>
          <option value="">Не выбрана</option>
          {currency_options.map((el) => (
            <option value={el} key={el}>
              {el}
            </option>
          ))}
        </FormSelectInput>

        {/* Products's price fact */}
        <FormInput
          title="Цена товара в валюте поставщика (факт)"
          id="procurement_fact"
          placeholder=""
          required
          type="number"
          min={0}
          name="procurement_fact"
          onChange={handleChange}
          value={values.procurement_fact}
          error={errors.procurement_fact}
          isTouched={!!touched.procurement_fact}
        />

        {/* Product count */}
        <FormInput
          title="Количество товара в заказе"
          id="product_count"
          placeholder=""
          required
          type="number"
          name="product_count"
          min={0}
          onChange={handleChange}
          value={values.product_count}
          error={errors.product_count}
          isTouched={!!touched.product_count}
        />
      </div>
    </form>
  );
}
