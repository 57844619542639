import { Button, TextInput, TextInputProps, Tooltip } from 'flowbite-react';
import React, { MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import { MdDone, MdClose } from 'react-icons/md';
import useOnBlur from '../../hooks/useOnBlur';
import { BiHelpCircle } from 'react-icons/bi';
import { ControlledTooltip } from './Tooltip';

interface IEditableTableCellProps {
  value: string | number;
  onChange: (val: string | number) => Promise<void>;
  formattedValue?: string;
  type?: TextInputProps['type'];
  inputClassName?: string;
  spanClassName?: string;
  validate: (val: string | number) => boolean;
  canBeEdited?: boolean;
}

function EditableTableCell({
  value,
  onChange,
  formattedValue,
  type = 'text',
  spanClassName = '',
  inputClassName = '',
  validate,
  canBeEdited = true,
}: IEditableTableCellProps) {
  //boolean flag for switching type of cimponent from display to input
  const [isEdit, setIsEdit] = useState<boolean>(false);
  //tmp value for an input
  const [tmpValue, setTmpValue] = useState<string | number>(value);
  const [cellWidth, setCellWidth] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [warningHintOpen, setWarningHintOpen] = useState(false);
  const cellRef = useRef<HTMLDivElement>(null);

  useOnBlur({ ref: cellRef, onBlur, onFocus });

  async function onSave(
    e: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
  ) {
    e.stopPropagation();
    if (tmpValue === value) {
      onBlur();
      return;
    }

    if (!validate(tmpValue)) {
      setError('Некорректно заполнено поле');
      return;
    } else {
      setError(null);
    }

    setLoading(true);
    try {
      await onChange(tmpValue);
    } catch (err) {
      console.log(err);
    }

    onBlur();
    setLoading(false);
  }

  function onFocus() {
    setWarningHintOpen(true);
    if (!canBeEdited) return;
    setIsEdit(true);
  }

  function onBlur() {
    setIsEdit(false);
    setWarningHintOpen(false);
  }

  useEffect(() => {
    if (!cellRef.current) return;
    const rect = cellRef.current.getBoundingClientRect();
    setCellWidth(rect.width);
  }, [cellRef]);

  useEffect(() => {
    if (validate(tmpValue)) {
      setError(null);
    } else setError('Заполните поле!');
  }, [tmpValue]);

  return (
    <div className="relative w-full" onClick={onFocus} ref={cellRef}>
      {isEdit ? (
        <div style={{ width: cellWidth }} className="flex gap-1 items-center">
          <TextInput
            color={error ? 'failure' : ''}
            type={type}
            sizing="sm"
            value={tmpValue}
            onChange={(e) => setTmpValue(e.target.value)}
            className={`flex-1 h-8 ${inputClassName}`}
          />
          <Button
            onClick={(e) => onSave(e)}
            color="gray"
            size="sm"
            className="w-8"
            isProcessing={loading}
            disabled={loading}>
            <MdDone />
          </Button>
        </div>
      ) : (
        <div className="flex items-center justify-center gap-2">
          <span className={`${spanClassName}`}>{formattedValue || value}</span>
        </div>
      )}

      {!canBeEdited && (
        <ControlledTooltip
          tooltipText="Участие в акции для данного товара, может быть изменено только в интерфейсе маркетплейса"
          open={warningHintOpen}
        />
      )}
    </div>
  );
}

export default EditableTableCell;
