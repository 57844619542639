import { Button, Modal } from 'flowbite-react';

interface IInfoModalProps {
  open: boolean;
  onClose: () => void;
  header?: React.ReactNode | string;
  body?: React.ReactNode | string;
  footer?: React.ReactNode | string;
}

export default function InfoModal({
  open,
  onClose,
  header,
  body,
  footer,
}: IInfoModalProps) {
  return (
    <>
      <Modal dismissible show={open} onClose={onClose}>
        <Modal.Header>{header}</Modal.Header>
        <Modal.Body>{body}</Modal.Body>
        <Modal.Footer>{footer}</Modal.Footer>
      </Modal>
    </>
  );
}
