import { TProcurementForm } from '../components/procurement/ProcurementModal/ProcurementModal';
import { ICreateProcurementProduct } from '../components/procurement/ProcurementProductModal/ProcurementProductModal';
import { appApi } from './api_index';

export interface IBuyer {
  id: number;
  name: string;
  procurements: IProcurement[];
}

// Logistician интерфейс
export interface ILogistician {
  id: number;
  name: string;
  procurements: IProcurement[];
}

// Procurement интерфейс
export interface IProcurement {
  id: number;
  name: string;
  organization_id: number;
  created_at: string | null;
  buyer_id: number | null;
  buyer: IBuyer | null;
  logistician_id: number | null;
  logistician: ILogistician | null;
  ProcurementProduct: IProcurementProduct[];
}

// ProcurementProduct интерфейс
export interface IProcurementProduct {
  id: number;
  name: string;
  competitor_url: string;
  marketplace_product_price: number;
  analytics_url: string;
  marketplace_fee: number;
  marketplace_logistics_expense: number;
  supplier_logistic_expense: number;
  usd_rub: number;
  cny_rub: number;
  margin_per_product: number;
  margin_rate: number;
  product_count: number;
  procurement_fact: number;
  supplier_logistic_expense_fact: number;
  soldout_margin: number;
  procurement_id: number | null;
  procurement: IProcurement | null;
  currency: string;
}

export interface IFetchProcurementProducts {
  products: IProcurementProduct[];
  count: number;
  max_page: number;
}

const procurementApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    createProcurement: builder.mutation<IProcurement, TProcurementForm>({
      query: (data) => ({
        url: `procurement`,
        credentials: 'include',
        body: data,
        method: 'POST',
      }),
      invalidatesTags: ['Procurements'],
    }),
    createProcurementProduct: builder.mutation<
      IProcurementProduct,
      { data: ICreateProcurementProduct; procurement_id: number }
    >({
      query: (data) => ({
        url: `procurement-product`,
        credentials: 'include',
        body: data.data,
        method: 'POST',
      }),
      invalidatesTags: ['ProcurementProduct'],
    }),
    updateProcurementProduct: builder.mutation<
      IProcurementProduct,
      { data: ICreateProcurementProduct; id: number }
    >({
      query: ({ data, id }) => ({
        url: `procurement-product/${id}`,
        credentials: 'include',
        body: data,
        method: 'PATCH',
      }),
      invalidatesTags: ['ProcurementProduct'],
    }),
    updateProcurement: builder.mutation<
      IProcurement,
      { data: Partial<TProcurementForm>; id: number }
    >({
      query: ({ data, id }) => ({
        url: `procurement/${id}`,
        credentials: 'include',
        body: data,
        method: 'PATCH',
      }),
      invalidatesTags: ['Procurements'],
    }),
    fetchAllProcurements: builder.query<
      { procurements: IProcurement[]; count: number; max_page: number },
      any
    >({
      query: (args: any) => ({
        url: `procurement`,
        credentials: 'include',
        params: { ...args },
      }),
      providesTags: ['Procurements'],
    }),
    fetchProcurement: builder.query<
      Exclude<IProcurement, 'ProcurementProduct'>,
      number
    >({
      query: (id: number) => ({
        url: `procurement/${id}`,
        credentials: 'include',
      }),
      providesTags: ['Procurements'],
    }),
    fetchProcurementProduct: builder.query<
      Exclude<IProcurement, 'ProcurementProduct'>,
      number
    >({
      query: (id: number) => ({
        url: `procurement-product/${id}`,
        credentials: 'include',
      }),
      providesTags: ['ProcurementProduct'],
    }),
    fetchProcurementProducts: builder.query<IFetchProcurementProducts, any>({
      query: ({ id, ...args }) => ({
        url: `procurement-product`,
        credentials: 'include',
        params: { ...args, procurementId: id },
      }),
      providesTags: ['ProcurementProduct'],
    }),
    deleteProcurement: builder.mutation<IProcurement, number>({
      query: (procurementId) => ({
        url: `procurement/${procurementId}`,
        credentials: 'include',
        method: 'DELETE',
      }),
      invalidatesTags: ['Procurements'],
    }),
    deleteProcurementProduct: builder.mutation<void, number>({
      query: (id) => ({
        url: `procurement-product/${id}`,
        credentials: 'include',
        method: 'DELETE',
      }),
      invalidatesTags: ['ProcurementProduct'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateProcurementMutation,
  useUpdateProcurementMutation,
  useLazyFetchAllProcurementsQuery,
  useFetchAllProcurementsQuery,
  useLazyFetchProcurementProductsQuery,
  useLazyFetchProcurementQuery,
  useFetchProcurementQuery,
  useDeleteProcurementMutation,
  useDeleteProcurementProductMutation,
  useCreateProcurementProductMutation,
  useUpdateProcurementProductMutation,
} = procurementApi;
