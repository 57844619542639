import { Checkbox, Label, Select, TextInput } from 'flowbite-react';
import { FormikErrors, FormikTouched } from 'formik';
import { useState } from 'react';
import {
  IFtechSupplierProducts,
  ISupplierProduct,
  useLazyFetchSupplierProductsQuery,
} from '../../api/supplier_product_api';
import { ICreateNewProductUnitEconomicResearch } from './NewProductUnitModal';
import FormErrorMessage from '../ui/FormErrorMessage';
import './styles/Form.css';
import AsyncSelect from 'react-select/async';
import FormInput from '../ui/FormInput';
import FormSelectInput from '../ui/FormSelectInput';

interface INewProductUnitEconomicResearchFormProps {
  values: ICreateNewProductUnitEconomicResearch;
  errors: FormikErrors<ICreateNewProductUnitEconomicResearch>;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleChooseProduct?: (id: number) => void;
  isEdit: boolean;
  touched: FormikTouched<ICreateNewProductUnitEconomicResearch>;
  fetchSupplierProducts?: (data: {
    search?: string;
    take?: number;
  }) => Promise<IFtechSupplierProducts | undefined>;
}

const currency_options = ['usd', 'rub', 'cny'];

export default function NewProductUnitEconomicResearchForm({
  values,
  errors,
  handleChange,
  isEdit,
  touched,
}: INewProductUnitEconomicResearchFormProps) {
  //const [newProduct, setNewProduct] = useState<boolean>(false);

  // const promiseOptions = async (inputValue: string) => {
  //   const data = await fetchSupplierProducts({ search: inputValue, take: 100 });
  //   if (!data?.products) return [];
  //   return data?.products.map((el) => ({ value: el.id, label: el.name }));
  // };

  return (
    <form>
      {/* {!isEdit && (
        <div className="max-w-full grid grid-cols-2 gap-x-8" id="select">
          <div className="mb-2 block">
            <Label htmlFor="products" value="Выберите существующий товар" />
          </div>
          <div></div>

          <AsyncSelect
            cacheOptions
            defaultOptions
            loadOptions={promiseOptions}
            classNamePrefix="product-select"
            onChange={(e) => handleChooseProduct(e?.value as number)}
            isDisabled={newProduct}
            isClearable
            noOptionsMessage={(obj) => (
              <span>Товар {obj.inputValue} не найден</span>
            )}
            loadingMessage={() => <span>Поиск...</span>}
            placeholder="Выберите товар"
          />
          <div></div>

          <div
            className={`flex items-center gap-2 mt-4 ${isEdit ? 'opacity-40' : 'opacity-100'
              }`}>
            <Checkbox
              id="age"
              checked={newProduct}
              onChange={(e) => setNewProduct(e.target.checked)}
              disabled={isEdit}
            />
            <Label htmlFor="age">Создать новый продукт</Label>
          </div>
        </div>
      )} */}

      <div className="grid grid-cols-2 gap-x-8 gap-y-3">
        {/* Product's name */}
        <FormInput
          name="product.name"
          title="Наименование товара"
          id="product.name"
          placeholder="Продукт"
          required
          type="text"
          onChange={handleChange}
          value={values.product.name}
          error={errors.product?.name}
          isTouched={!!touched.product?.name}
        />

        {/* Product's weight */}
        <FormInput
          id="product.weight"
          title="Вес товара, кг"
          placeholder="Продукт"
          required
          type="number"
          name="product.weight"
          min={0}
          onChange={handleChange}
          value={values.product.weight}
          //disabled={!newProduct}
          error={errors.product?.weight}
          isTouched={!!touched.product?.weight}
        />

        {/* Product's url */}
        <FormInput
          id="product.product_url"
          title="Ссылка на товар поставщика"
          placeholder="Ссылка"
          type="text"
          name="product.product_url"
          onChange={handleChange}
          value={values.product.product_url}
          error={errors.product?.product_url}
          isTouched={!!touched.product?.product_url}
        />

        {/* Product's logistics_rate */}
        <FormInput
          id="product.logistics_rate"
          title="Тариф доставки, $/кг"
          placeholder=""
          required
          type="number"
          name="product.logistics_rate"
          min={0}
          onChange={handleChange}
          value={values.product.logistics_rate}
          //disabled={!newProduct}
          error={errors.product?.logistics_rate}
          isTouched={!!touched.product?.logistics_rate}
        />

        {/* Supplier's currency */}
        <FormSelectInput
          name="product.currency"
          title="Валюта поставщика"
          value={values.product.currency}
          onChange={handleChange}
          //disabled={!newProduct}
          error={errors.product?.currency}
          isTouched={!!touched.product?.currency}>
          <option value="">Не выбрана</option>
          {currency_options.map((el) => (
            <option value={el} key={el}>
              {el}
            </option>
          ))}
        </FormSelectInput>

        {/* Products's price */}
        <FormInput
          title="Цена товара в валюте поставщика"
          id="product.product_price"
          placeholder=""
          required
          type="number"
          min={0}
          name="product.product_price"
          onChange={handleChange}
          value={values.product.product_price}
          //disabled={!newProduct}
          error={errors.product?.product_price}
          isTouched={!!touched.product?.product_price}
        />
      </div>

      <hr className="mt-4 mb-2" />

      <div className="grid grid-cols-2 gap-x-8 gap-y-3">
        {/* Competitor URL */}
        <FormInput
          title="Ссылка на товар"
          id="competitor_url"
          placeholder=""
          required
          type="text"
          name="competitor_url"
          onChange={handleChange}
          value={values.competitor_url}
          error={errors?.competitor_url}
          isTouched={!!touched?.competitor_url}
        />

        {/* Analytics URL */}
        <FormInput
          title="Ссылка на аналитику"
          id="analytics_url"
          placeholder=""
          required
          type="text"
          name="analytics_url"
          onChange={handleChange}
          value={values.analytics_url}
          error={errors.analytics_url}
          isTouched={!!touched.analytics_url}
        />

        {/* Marketplace fee */}
        <FormInput
          title="Комиссия маркетплейса, %"
          id="marketplace_fee"
          placeholder=""
          required
          type="number"
          max={100}
          min={0}
          name="marketplace_fee"
          onChange={handleChange}
          value={values.marketplace_fee}
          error={errors.marketplace_fee}
          isTouched={!!touched.marketplace_fee}
        />

        {/* Marketplace logistic expensse */}
        <FormInput
          title="Логистика маркетплейса, руб"
          id="marketplace_logistics_expense"
          placeholder=""
          required
          type="number"
          min={0}
          name="marketplace_logistics_expense"
          onChange={handleChange}
          value={values.marketplace_logistics_expense}
          error={errors.marketplace_logistics_expense}
          isTouched={!!touched.marketplace_logistics_expense}
        />

        {/* usd to rub */}
        <FormInput
          title="Курс доллара"
          id="usd_rub"
          placeholder=""
          required
          type="number"
          name="usd_rub"
          min={0}
          onChange={handleChange}
          value={values.usd_rub}
          error={errors.usd_rub}
          isTouched={!!touched.usd_rub}
        />

        {/* cny to rub */}
        <FormInput
          title="Курс юаня"
          id="cny_rub"
          placeholder=""
          required
          type="number"
          name="cny_rub"
          min={0}
          onChange={handleChange}
          value={values.cny_rub}
          error={errors.cny_rub}
          isTouched={!!touched.cny_rub}
        />

        {/* Marketplace product price */}
        <FormInput
          title="Цена продукта на маркетплейсе, руб"
          id="marketplace_product_price"
          placeholder=""
          required
          type="number"
          min={0}
          name="marketplace_product_price"
          onChange={handleChange}
          value={values.marketplace_product_price}
          error={errors.marketplace_product_price}
          isTouched={!!touched.marketplace_product_price}
        />

        {/* Product count */}
        <FormInput
          title="Количество товара для заказа"
          id="product_count"
          placeholder=""
          required
          type="number"
          name="product_count"
          min={0}
          onChange={handleChange}
          value={values.product_count}
          error={errors.product_count}
          isTouched={!!touched.product_count}
        />
      </div>
    </form>
  );
}
