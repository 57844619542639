import { isRejectedWithValue, isRejected } from '@reduxjs/toolkit';
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;
axios.defaults.withCredentials = true;

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorHandler: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      if (
        action.meta.arg.endpointName === 'fetchSubscription' &&
        action.payload.status === 404
      ) {
        return;
      }

      if (Array.isArray(action?.payload?.data?.message)) {
        toast.error(action.payload.data?.message[0], {
          position: 'bottom-right',
        });
      } else {
        toast.error(action.payload.data?.message, {
          position: 'bottom-right',
        });
      }
    }

    //handle errors with no value
    if (isRejected(action)) {
      let error = action.payload.error;
      if (!navigator.onLine) {
        error = 'Отсутствует подключение к интернету!';
      }

      toast.error(error, {
        position: 'bottom-right',
      });
    }
    return next(action);
  };
