import React from 'react';

interface IEntityActionContext {
  handleChange: (id: number) => Promise<void>;
  handleDelete: (id: number) => Promise<void>;
  setConfirmOpen?: React.Dispatch<React.SetStateAction<number | null>>;
  deleteLoading: boolean;
  showDeleteButton?: boolean;
}

export const EntityActionContext = React.createContext(
  {} as IEntityActionContext,
);
