import { GetDiscountCampaignProductType } from '../redux/discount_campaign/slice';

export default function validateDiscountCampaignProduct(
  product: GetDiscountCampaignProductType,
) {
  let error = false;

  if (product.price === 0 || product.logistic_expense === 0) {
    error = true;
  }

  return error;
}
