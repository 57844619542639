import { Button, Button as FlButton, TextInput } from 'flowbite-react';
import React, { useEffect, useMemo, useState } from 'react';
import { BsSearch } from 'react-icons/bs';
import { IoClose } from 'react-icons/io5';
import {
  IProductUnitEconomicResearch,
  useCreateUnitEconomyResearchMutation,
  useLazyFetchAllUnitEconomyResearchesQuery,
  useUpdateUnitEconomyResearchMutation,
  useDeleteUnitEconomyResearchMutation,
} from '../../api/unit_economy_api';
import NewProductUnitModal, {
  ICreateNewProductUnitEconomicResearch,
} from '../../components/product_unit_economic_research/NewProductUnitModal';
import { toast } from 'react-toastify';
import ConfirmDialog from '../../components/ui/ConfirmDialog';
import TablePaginator from '../../components/ui/TablePaginator';
import useDebounce from '../../hooks/useDebounce';
import UnitEconomyProductTable from '../../components/product_unit_economic_research/UnitEconomyProductTable';
import { EntityActionProcurementContext } from '../../components/product_unit_economic_research/EntityActionContext';
import usePaginate from '../../hooks/usePaginate';
import trackEvent, { MetricEventType } from '../../utils/trackEvent';
import ProcurementProductModal, {
  ICreateProcurementProduct,
} from '../../components/procurement/ProcurementProductModal/ProcurementProductModal';
import {
  IProcurementProduct,
  useCreateProcurementProductMutation,
} from '../../api/procurement_api';

function AllProductUnitEconomicResearchView() {
  //const [fetchSales, { data: sales }] = useLazyGetAllSalesQuery();
  const [search, setSearch] = useState('');

  const [modalOpen, setModalOpen] = useState(false);
  const [procurementProductModalOpen, setProcurementProductModalOpen] =
    useState(false);
  const [confirmOpen, setConfirmOpen] = useState<number | null>(null);

  const [createProcurementProduct, {isLoading: isCreateProcurementProductLoading}] = useCreateProcurementProductMutation();
  const [fetchProductUnitEconomicResearches, { data: unitEconomyData }] =
    useLazyFetchAllUnitEconomyResearchesQuery();

  const [createProductUnitEconomicResearch, { isLoading: isCreateLoading }] =
    useCreateUnitEconomyResearchMutation();

  const [updateProductUnitEconomicResearch, { isLoading: isUpdateLoading }] =
    useUpdateUnitEconomyResearchMutation();

  const [deleteProductUnitEconomicResearch, { isLoading: isDeleteLoading }] =
    useDeleteUnitEconomyResearchMutation();

  const [productUnitEconomicResearch, setProductUnitEconomicResearch] =
    useState<IProductUnitEconomicResearch | null>(null);

  //for procurement product modal
  const [selectedProduct, setSelectedProduct] =
    useState<IProcurementProduct | null>(null);

  const debouncedSearch = useDebounce(search, 500);
  const { paginationState, handleChangePage, resetState } = usePaginate({
    handlePaginate,
  });

  useEffect(() => {
    trackEvent(MetricEventType.SUPPLY_SCREEN_START);
  }, []);

  useEffect(() => {
    fetchProductUnitEconomicResearches({
      take: 10,
      skip: 0,
      search: debouncedSearch,
    });

    resetState();
  }, [debouncedSearch]);

  async function handleDeleteProductUnitResearch(id: number) {
    try {
      await deleteProductUnitEconomicResearch(id).unwrap();
      setConfirmOpen(null);
      trackEvent(MetricEventType.SUPPLY_WORK_DELETE);
    } catch (err) {
      console.log(err);
    }
  }

  async function handleUpdateProductUnitResearch(id: number) {
    setModalOpen(true);
    const data = unitEconomyData?.researches.find((el) => el.id === id);
    trackEvent(MetricEventType.SUPPLY_WORK_EDIT);
    if (data) {
      setProductUnitEconomicResearch(data);
    }
  }

  async function handleSubmit(
    values: ICreateNewProductUnitEconomicResearch,
    isEdit: boolean,
  ) {
    try {
      //update entity
      if (isEdit) {
        await updateProductUnitEconomicResearch({
          data: values,
          id: productUnitEconomicResearch?.id as number,
        }).unwrap();
      } else {
        //create entity
        await createProductUnitEconomicResearch(values).unwrap();

        trackEvent(MetricEventType.SUPPLY_ADD_SUCCESS);
      }
      toast.success(
        isEdit ? 'Расчет успешно обновлён' : 'Расчет успешно сохранён',
        { position: 'bottom-right' },
      );
      setModalOpen(false);
    } catch (err) {
      console.log(err);
    }
  }

  function handleCreate() {
    setModalOpen(true);
    setProductUnitEconomicResearch(null);
    trackEvent(MetricEventType.SUPPLY_BUTTON_ADDNEW);
  }

  async function handlePaginate(take: number, page: number) {
    try {
      const params = { take, page };
      if (search) {
        (params as any).search = search;
      }
      const resp = await fetchProductUnitEconomicResearches(params);
      return resp.data?.max_page;
    } catch (err) {
      console.log(err);
    }
  }

  function handleCloseModal() {
    setProductUnitEconomicResearch(null);
    setModalOpen(false);
    trackEvent(MetricEventType.SUPPLY_ADD_CANCEL);
  }

  const ActionButtons = () => (
    <Button
      className="bg-primary-600 enabled:hover:bg-primary-500 w-auto "
      onClick={handleCreate}>
      Добавить новый товар
    </Button>
  );

  async function handleChooseProduct(id: number) {
    setProcurementProductModalOpen(true);
    const data = unitEconomyData?.researches.find((el) => el.id === id);
    if (data) {
      const {
        supplier_product: { name, currency, product_price },
        id,
        ...procurementProductData
      } = data;

      const procurementProduct: IProcurementProduct = {
        ...procurementProductData,
        procurement_id: id,
        name,
        currency,
        procurement_fact: product_price,
        supplier_logistic_expense_fact: data.supplier_logistic_expense,
      } as any;
      setSelectedProduct(procurementProduct);
    }
  }

  async function handleAddProductToProcurement(
    values: ICreateProcurementProduct,
    isEdit: boolean,
  ) {
    try {
      await createProcurementProduct({
        data: values,
        procurement_id: values.procurement_id as number,
      }).unwrap();
      setProcurementProductModalOpen(false)
      toast.success(
        'Товар успешно добавлен в закупку',
        { position: 'bottom-right' },
      );
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div>
      <NewProductUnitModal
        isOpen={modalOpen}
        onClose={handleCloseModal}
        onSubmit={handleSubmit}
        unitResearch={productUnitEconomicResearch}
        isLoading={isCreateLoading || isUpdateLoading}
      />

      <ProcurementProductModal
        isOpen={procurementProductModalOpen}
        onClose={() => setProcurementProductModalOpen(false)}
        onSubmit={handleAddProductToProcurement}
        isLoading={isCreateProcurementProductLoading}
        procurementProduct={selectedProduct}
        isProcurementIdKnown={false}
      />

      <ConfirmDialog
        open={!!confirmOpen}
        onClose={() => setConfirmOpen(null)}
        title="Удалить расчёт"
        info="Вы уверены, что хотите удалить расчёт?"
        actionButton={
          <Button
            className="bg-red-500 text-white hover:bg-red-400 disabled:hover:bg-red-500"
            isProcessing={isDeleteLoading}
            disabled={isDeleteLoading}
            onClick={() =>
              handleDeleteProductUnitResearch(confirmOpen as number)
            }>
            Удалить
          </Button>
        }
      />

      <div className="flex flex-row justify-between">
        <h2 className="font-bold text-3xl">Юнит-экономика закупок</h2>
      </div>

      <div className="flex flex-row gap-4 my-7">
        <TextInput
          icon={BsSearch}
          id="search"
          placeholder="Поиск"
          required
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Button
          onClick={() => setSearch('')}
          style={{ height: 42, width: 42 }}
          className="!focus:ring-0 bg-gray-50 enabled:hover:bg-gray-300 !ring-0 !ring-transparent !outline-none !border-gray-300">
          <IoClose size={20} color="black" />
        </Button>
      </div>

      <EntityActionProcurementContext.Provider
        value={{
          deleteLoading: isDeleteLoading,
          handleChange: handleUpdateProductUnitResearch,
          handleAddToProcurement: handleChooseProduct,
          handleDelete: handleDeleteProductUnitResearch,
          setConfirmOpen,
          showDeleteButton: true,
        }}>
        <UnitEconomyProductTable
          data={unitEconomyData}
          addonComponent={<ActionButtons />}
          paginator={
            <TablePaginator
              handleChangePage={handleChangePage}
              paginationState={paginationState}
              maxPage={unitEconomyData?.max_page}
            />
          }
        />
      </EntityActionProcurementContext.Provider>
    </div>
  );
}
export default AllProductUnitEconomicResearchView;
