import moment from 'moment';
import { ISubscription } from '../../api/subscription';
import formatDurationDays from '../../utils/formatDurationDays';

interface ISubscriptionTimeoutCounterProps {
  subscription?: ISubscription;
}

export default function SubscriptionTimeoutCounter({
  subscription,
}: ISubscriptionTimeoutCounterProps) {
  //do not display counter if there is no subscription
  if (!subscription) {
    return null;
  }

  const counterTitle = subscription.trial
    ? 'До конца пробного периода: '
    : 'До конца подписки осталось: ';

  const now = new Date();

  const subscriptionTimeLeft =
    new Date(subscription.end_date).getTime() - now.getTime();
  const subscriptionDaysLeft = Math.floor(
    moment.duration(subscriptionTimeLeft).asDays(),
  );

  return (
    <span>
      <b>{counterTitle}</b>
      <span>{formatDurationDays(subscriptionDaysLeft)}</span>
    </span>
  );
}
