import { Button, Label, Select, Spinner, TextInput } from 'flowbite-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import useDebounce from '../../hooks/useDebounce';
import useOnBlur from '../../hooks/useOnBlur';
import { FaPlus } from 'react-icons/fa6';
import { FaChevronDown } from 'react-icons/fa6';

type SelectOption<T> = {
  label: string;
  value: T;
};

interface ISmartSelectInputProps<T> {
  options: SelectOption<T>[];
  selectedValue: T;
  onChange: (option: SelectOption<T>) => void;
  onModalOpen?: () => void;
  label?: string;
  isLoading: boolean;
  placeholder?: string;
}

function SmartSelectInput<T>({
  options,
  selectedValue,
  onChange,
  onModalOpen,
  label,
  isLoading,
  placeholder,
}: ISmartSelectInputProps<T>) {
  const containerRef = useRef(null);

  const selectedLabel = useMemo(() => {
    return options.find((o) => o.value === selectedValue)?.label;
  }, [selectedValue, options]);

  const [isSelectOpen, setIsSelectOpen] = useState(false);

  useOnBlur({ ref: containerRef, onBlur: () => setIsSelectOpen(false) });

  const [search, setSearch] = useState<string>(selectedLabel as string);
  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    setSearch(selectedLabel as string);
  }, [selectedLabel]);

  const filteredOptions = useMemo(() => {
    if (!options) return [];
    if (!debouncedSearch) return options;

    return options?.filter((o) =>
      o.label.toLowerCase().includes(debouncedSearch.toLowerCase()),
    );
  }, [debouncedSearch, options]);

  const selectOptions = useMemo(() => {
    return (
      <div
        className={`flex flex-col w-full absolute z-50 shadow-md rounded-md bg-white ${
          isSelectOpen ? 'visible' : 'invisible'
        }`}>
        {isLoading && <Spinner />}
        {!isLoading &&
          filteredOptions.map((option, idx) => (
            <div
              key={option.label}
              className="hover:bg-primary-200 bg-white rounded-md py-1 px-3"
              onClick={(e) => {
                onChange(option);
                setIsSelectOpen(false);
              }}>
              {option.label}
            </div>
          ))}
      </div>
    );
  }, [filteredOptions, isSelectOpen]);

  return (
    <div>
      <div className="mb-2 block">
        <Label htmlFor="main" value={label} />
      </div>
      <div className="flex items-center gap-2">
        <div className="relative h-10" ref={containerRef}>
          <TextInput
            placeholder={placeholder}
            name="main"
            onFocus={() => setIsSelectOpen(true)}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <FaChevronDown
            className={`absolute right-4 top-3.5 h-3 w-3 ${
              isSelectOpen ? 'rotate-180' : 'rotate-0'
            }`}
          />
          {selectOptions}
        </div>
        <Button
          color="gray"
          onClick={() => onModalOpen?.()}
          className="h-10 w-10">
          <FaPlus className="" />
        </Button>
      </div>
    </div>
  );
}

export default SmartSelectInput;
