import * as Yup from 'yup';

export const ProcurementProductFormSchema = Yup.object().shape({
  name: Yup.string().required('Введите наименование товара'),
  competitor_url: Yup.string()
    .url('Неправильный формат ссылки')
    .notRequired()
    .default(''),

  marketplace_product_price: Yup.number()
    .moreThan(0, 'Цена товара на маркетплейсе должна быть больше нуля')
    .required('Введите цену товара на маркетплейсе'),

  analytics_url: Yup.string()
    .url('Неправильный формат ссылки')
    .notRequired()
    .default(''),

  marketplace_fee: Yup.number().required('Введите комиссию маркетплейса'),

  marketplace_logistics_expense: Yup.number().required(
    'Введите стоимость логистики маркетплейса',
  ),

  supplier_logistic_expense: Yup.number().required(
    'Введите стоимость логистики поставщика',
  ),

  usd_rub: Yup.number().required('Введите курс доллара'),
  cny_rub: Yup.number().required('Введите курс юаня'),

  product_count: Yup.number()
    .min(0, 'Количество товаров для заказа должно быть больше нуля')
    .required('Введите количество товаров для заказа'),

  currency: Yup.string()
    .matches(/(usd|cny|rub)/, {
      excludeEmptyString: true,
      message: 'Валюта должна быть usd, rub или cny',
    })
    .required('Заполните валюту продавца'),
});

export const ProcurementProductWithProcurementIdFormSchema = Yup.object().shape({
  procurement_id: Yup.number()
  .required('Выберите закупку или создайте новую'),

  name: Yup.string().required('Введите наименование товара'),
  competitor_url: Yup.string()
    .url('Неправильный формат ссылки')
    .notRequired()
    .default(''),

  marketplace_product_price: Yup.number()
    .moreThan(0, 'Цена товара на маркетплейсе должна быть больше нуля')
    .required('Введите цену товара на маркетплейсе'),

  analytics_url: Yup.string()
    .url('Неправильный формат ссылки')
    .notRequired()
    .default(''),

  marketplace_fee: Yup.number().required('Введите комиссию маркетплейса'),

  marketplace_logistics_expense: Yup.number().required(
    'Введите стоимость логистики маркетплейса',
  ),

  supplier_logistic_expense: Yup.number().required(
    'Введите стоимость логистики поставщика',
  ),

  usd_rub: Yup.number().required('Введите курс доллара'),
  cny_rub: Yup.number().required('Введите курс юаня'),

  product_count: Yup.number()
    .min(0, 'Количество товаров для заказа должно быть больше нуля')
    .required('Введите количество товаров для заказа'),

  currency: Yup.string()
    .matches(/(usd|cny|rub)/, {
      excludeEmptyString: true,
      message: 'Валюта должна быть usd, rub или cny',
    })
    .required('Заполните валюту продавца'),
});
