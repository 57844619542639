import { CellContext, createColumnHelper } from '@tanstack/react-table';
import { ToggleSwitch } from 'flowbite-react';
import React from 'react';
import { BiHelpCircle } from 'react-icons/bi';
import {
  IFetchProductUnitEconomicResearches,
  IProductUnitEconomicResearch,
} from '../../api/unit_economy_api';
import useColumnVisibility from '../../hooks/useColumnVisibility';
import { unitCellColorFormatter } from '../../utils/cellColorFormatter';
import { priceLocalFormat } from '../../utils/priceFormatter';
import trackEvent, { MetricEventType } from '../../utils/trackEvent';
import {
  TableComissionHint,
  TableLogisticsHint,
  TableProductCostHint,
} from '../discount_campaign/TableHint';
import Popover from '../ui/Popover';
import Table from '../ui/Table';
import EntityActionProcurement from './EntityAction';

interface IEconomyProductTableProps
  extends React.ComponentPropsWithoutRef<'div'> {
  data?: IFetchProductUnitEconomicResearches;
  paginator?: React.ReactNode;
  addonComponent?: React.ReactNode;
}

export default function UnitEconomyProductTable({
  data,
  paginator,
  addonComponent,
  ...props
}: IEconomyProductTableProps) {
  const { columnVisibility, onToggle, mode } = useColumnVisibility([
    'competitor_url',
    'analytics_url',
    'supplier_product_product_url',
    'usd_rub',
    'cny_rub',
    'supplier_product_logistics_rate',
    'supplier_product_weight',
  ]);
  const columnHelper = createColumnHelper<IProductUnitEconomicResearch>();

  const columns = [
    {
      header: 'Действия',
      cell: (props: any) => <EntityActionProcurement row={props.row} />,
    },
    columnHelper.accessor('supplier_product.name', {
      header: 'Название товара',
      cell: (info) => (
        <span className="whitespace-nowrap">{info.getValue()}</span>
      ),
    }),
    columnHelper.accessor('competitor_url', {
      header: 'Ссылка на товар',
      enableSorting: false,
      cell: (info) => {
        const link = info.getValue();

        if (!link) {
          return <span>—</span>;
        }

        return (
          <a
            href={link}
            target="_blank"
            rel="noreferrer"
            className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
            Перейти
          </a>
        );
      },
    }),
    columnHelper.accessor('analytics_url', {
      header: 'Ссылка на аналитику',
      enableSorting: false,
      cell: (info) => {
        const link = info.getValue();

        if (!link) {
          return <span>—</span>;
        }

        return (
          <a
            href={link}
            target="_blank"
            rel="noreferrer"
            className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
            Перейти
          </a>
        );
      },
    }),
    columnHelper.accessor('marketplace_product_price', {
      header: 'Цена продажи на WB',
      cell: (info) => (
        <span>{priceLocalFormat(info.getValue(), 'RUB', 'ru')}</span>
      ),
    }),
    columnHelper.accessor('marketplace_fee', {
      header: () => (
        <div className="flex items-center gap-2 relative">
          <span>Комиссия маркетплейса</span>
          <Popover
            button={<BiHelpCircle size={20} />}
            title="Комиссия маркетплейса"
            body={<TableComissionHint />}
          />
        </div>
      ),
      cell: (info) => (
        <span>
          {info.getValue()}
          {' %'}
        </span>
      ),
    }),
    columnHelper.accessor('marketplace_logistics_expense', {
      header: 'Логистика маркетплейса',
      cell: (info) => (
        <span>{priceLocalFormat(info.getValue(), 'RUB', 'ru')}</span>
      ),
    }),
    columnHelper.accessor('supplier_product.product_url', {
      header: 'Ссылка на поставщика',
      enableSorting: false,
      cell: (info) => {
        const link = info.getValue();

        if (!link) {
          return <span>—</span>;
        }

        return (
          <a
            href={link}
            target="_blank"
            rel="noreferrer"
            className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
            Перейти
          </a>
        );
      },
    }),
    columnHelper.accessor('supplier_product.product_price', {
      header: () => (
        <div className="flex items-center gap-2 relative">
          <span>Стоимость товара у поставщика</span>
          <Popover
            button={<BiHelpCircle size={20} />}
            title="Стоимость товара у поставщика"
            body={<TableProductCostHint />}
          />
        </div>
      ),
      cell: (info) => (
        <span>
          {priceLocalFormat(
            info.getValue(),
            info.row.original.supplier_product.currency,
            'ru',
          )}
        </span>
      ),
    }),
    columnHelper.accessor('supplier_product.weight', {
      header: () => <span className="whitespace-nowrap">Вес, кг</span>,
      cell: (info) => <span>{info.getValue().toFixed(3)}</span>,
    }),
    columnHelper.accessor('usd_rub', {
      header: 'Курс доллара',
      cell: (info) => (
        <span>{priceLocalFormat(info.getValue(), 'RUB', 'ru')}</span>
      ),
    }),
    columnHelper.accessor('cny_rub', {
      header: 'Курс юаня',
      cell: (info) => (
        <span>{priceLocalFormat(info.getValue(), 'RUB', 'ru')}</span>
      ),
    }),
    columnHelper.accessor('supplier_product.logistics_rate', {
      header: 'Тариф доставки, $/кг',
      cell: (info) => (
        <span>{priceLocalFormat(info.getValue(), 'USD', 'ru')}</span>
      ),
    }),
    columnHelper.accessor('supplier_logistic_expense', {
      header: () => (
        <div className="flex items-center gap-2 relative">
          <span>Логистика</span>
          <Popover
            button={<BiHelpCircle size={20} />}
            title="Логистика"
            body={<TableLogisticsHint />}
          />
        </div>
      ),
      cell: (info) => (
        <span>{priceLocalFormat(info.getValue(), 'RUB', 'ru')}</span>
      ),
    }),
    columnHelper.accessor('margin_per_product', {
      header: 'Маржа с товара',
      cell: (info) => (
        <span>{priceLocalFormat(info.getValue(), 'RUB', 'ru')}</span>
      ),
    }),
    columnHelper.accessor('margin_rate', {
      header: 'Процент маржинальности',
      cell: (info) => (
        <span>
          {info.getValue().toFixed(2)}
          {' %'}
        </span>
      ),
      meta: {
        getCellContext: (
          context: CellContext<IProductUnitEconomicResearch, unknown>,
        ) => {
          return {
            className: unitCellColorFormatter(context.row.original.margin_rate),
          };
        },
      },
    }),
    columnHelper.accessor('product_count', {
      header: 'Количество для заказа',
    }),
    columnHelper.accessor('fund', {
      header: 'Капитал',
      cell: (info) => (
        <span className="whitespace-nowrap">
          {priceLocalFormat(info.getValue(), 'RUB', 'ru')}
        </span>
      ),
    }),
    columnHelper.accessor('soldout_margin', {
      header: 'Маржинальность после распродажи',
      cell: (info) => (
        <span className="whitespace-nowrap">
          {priceLocalFormat(info.getValue(), 'RUB', 'ru')}
        </span>
      ),
    }),
  ];

  function onToggleLocal(checked: boolean) {
    onToggle(checked);
    trackEvent(MetricEventType.SUPPLY_WORK_EXPAND);
  }

  return (
    <Table
      columns={columns}
      data={data?.researches || []}
      paginator={paginator}
      {...props}
      fixedColumns={{ Действия: 0, supplier_product_name: 140 }}
      columnVisibility={columnVisibility}
      tableModeComponent={
        <div className="flex items-center justify-between pb-4">
          {addonComponent}
          <ToggleSwitch
            checked={mode === 'full'}
            onChange={onToggleLocal}
            label="Расширенный режим"
            theme={{
              toggle: { checked: { color: { blue: 'bg-primary-600' } } },
            }}
          />
        </div>
      }
    />
  );
}
