import { Label, TextInput, TextInputProps } from 'flowbite-react';
import React, { useRef } from 'react';
import FormErrorMessage from './FormErrorMessage';

interface IFormInputProps extends TextInputProps {
  value: string | number;
  error?: string;
  isTouched: boolean;
}

function FormInput({ error, isTouched, ...rest }: IFormInputProps) {
  const inputRef = useRef<HTMLInputElement>(null)
  return (
    <div>
      <div className="mb-2 block">
        <Label htmlFor={rest.name} value={rest.title} />
      </div>
      <TextInput {...rest} onFocus={()=>inputRef.current?.select()} ref={inputRef}/>
      <FormErrorMessage error={error} touched={isTouched} />
    </div>
  );
}

export default FormInput;
