import {
  Button,
  FileInput,
  Label,
  Modal,
  Radio,
  TextInput,
} from 'flowbite-react';
import React, { useEffect, useRef, useState } from 'react';
// import DatePicker from '../ui/DatePicker';
import DatePicker from '../ui/DatePicker';
import { CreateDiscountCampaignType } from '../../api/discount_campaign_api';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import FileInputHelperText from './FileInputHelperText';
import { useTypedDispatch, useTypedSelector } from '../../redux/hooks';
import {
  pauseTour,
  setCollapseOpen,
  setStepIndex,
  startTour,
  stopTour,
} from '../../redux/onboarding_tour/slice';
import { OnboardingTourStep } from '../../redux/onboarding_tour/steps';
import trackEvent, { MetricEventType } from '../../utils/trackEvent';

const formInitValues: CreateDiscountCampaignType = {
  name: '',
  status: 'new',
  start_date: new Date().toISOString(),
  end_date: new Date().toISOString(),
  file: null,
  marketplace: 'wb',
};
interface INewDiscountCampaignModalProps {
  onClose: () => void;
  onSuccess: (form: CreateDiscountCampaignType) => void;
  isOpen: boolean;
  isLoading?: boolean;
}

function NewDiscountCampaignModal({
  onClose,
  isOpen,
  onSuccess,
  isLoading,
}: INewDiscountCampaignModalProps) {
  const dispatch = useTypedDispatch();
  const { stepIndex, active } = useTypedSelector(
    (state) => state.oboarding_tour,
  );
  const rootRef = useRef<HTMLDivElement>(null);

  const formik = useFormik<CreateDiscountCampaignType>({
    initialValues: formInitValues,
    onSubmit: (values) => {
      dispatch(setStepIndex(OnboardingTourStep.STEP4_FULFILL_PRODUCT_COST));
      dispatch(pauseTour());
      onSuccess(values);
    },
    validateOnBlur: true,
    validate: (values) => {
      const errors: any = {};
      if (!values.start_date) {
        errors.start_date = 'Начальная дата не может быть пустой';
      }

      if (!values.end_date) {
        errors.end_date = 'Конечная дата не может быть пустой';
      }

      if (new Date(values.end_date) <= new Date(values.start_date)) {
        errors.end_date = 'Конечная дата должна быть позже начальной';
      }

      if (!values.name) {
        errors.name = 'Имя акции не может быть пустым';
      }

      if (!values.file?.name.endsWith('.xlsx')) {
        errors.file = 'Неправильный формат файла, выберите xlsx файл';
      }

      if (!values.file) {
        errors.file = 'Загрузите файл xlsx с товарами ';
      }

      return errors;
    },
  });

  useEffect(() => {
    if (formik.values.file?.name) {
      trackEvent(MetricEventType.ADDPROMO_FORM_UPLOAD);
    }
  }, [formik.values.file]);

  function onCloseLocal() {
    onClose();
  }

  //reset form
  useEffect(() => {
    if (!isOpen) formik.resetForm();

    console.log(isOpen, stepIndex, active)
    if (
      isOpen &&
      stepIndex === OnboardingTourStep.STEP3_ADD_DISCOUNT_CAMPAIGN &&
      active
    ) {
      setTimeout(()=>dispatch(startTour()), 200);
    }
  }, [isOpen, stepIndex]);

  return (
    <div ref={rootRef}>
      <Modal
        theme={{
          root: {
            base: 'z-[10000]',
          },
        }}
        show={isOpen}
        onClose={onCloseLocal}
        root={rootRef.current ?? undefined}
        id="onboarding-step-3">
        <Modal.Header>Добавление акции</Modal.Header>
        <Modal.Body>
          <form
            className="flex w-full flex-col gap-4"
            onSubmit={formik.handleSubmit}>
            <div>
              <div className="mb-2 block">
                <Label
                  htmlFor="name"
                  value="Имя акции *"
                  color={formik.errors.name && 'failure'}
                />
              </div>
              <TextInput
                id="name"
                placeholder="Имя"
                required
                type="text"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                color={formik.errors.name && 'failure'}
                onBlur={formik.handleBlur}
              />
              {formik.touched.name && (
                <i className="text-red-500 text-sm pt-4">
                  {formik.errors.name}
                </i>
              )}
            </div>

            <fieldset className="flex max-w-md flex-col gap-4" id="radio">
              <legend className="mb-4 text-sm font-medium text-gray-900">
                Выберите маркетплейс
              </legend>
              <div className="flex items-center gap-2">
                <Radio
                  onChange={(e) =>
                    formik.setFieldValue('marketplace', (e.target as any).value)
                  }
                  checked={formik.values.marketplace === 'wb'}
                  id="wb"
                  name="marketplace"
                  value="wb"
                  theme={{
                    root: {
                      base: 'text-primary-600',
                    },
                  }}
                />
                <Label htmlFor="wb">Wildberries</Label>
              </div>
              <div className="flex items-center gap-2">
                <Radio
                  onChange={(e) =>
                    formik.setFieldValue('marketplace', (e.target as any).value)
                  }
                  checked={formik.values.marketplace === 'ozon'}
                  id="ozon"
                  name="marketplace"
                  value="ozon"
                  theme={{
                    root: {
                      base: 'text-primary-600',
                    },
                  }}
                />
                <Label htmlFor="ozon">Ozon</Label>
              </div>
            </fieldset>

            {/* Range picker for sale date */}
            <div className="flex flex-row gap-5">
              <div className="basis-1/2">
                <div className="mb-2 block">
                  <Label
                    value="Начало акции *"
                    color={formik.errors.start_date && 'failure'}
                  />
                </div>
                <DatePicker
                  onChange={(date) => {
                    formik.setFieldValue('start_date', date.toISOString());
                    formik.setFieldTouched('start_date', true);
                  }}
                  title="Начало акции"
                />

                {formik.touched.start_date && (
                  <i className="text-red-500 text-sm pt-4">
                    {formik.errors.start_date}
                  </i>
                )}
              </div>
              <div className="basis-1/2">
                <div className="mb-2 block">
                  <Label
                    value="Конец акции *"
                    color={formik.errors.end_date && 'failure'}
                  />
                </div>
                <DatePicker
                  onChange={(date) => {
                    formik.setFieldTouched('end_date', true);
                    formik.setFieldValue('end_date', date.toISOString());
                    //formik.setFieldTouched('end_date', true);
                  }}
                  title="Конец акции"
                />
                {formik.touched.end_date && (
                  <i className="text-red-500 text-sm pt-4">
                    {formik.errors.end_date}
                  </i>
                )}
              </div>
            </div>

            <div className="flex-auto" id="fileUpload">
              <div className="mb-2 block">
                <Label
                  htmlFor="file"
                  value="Загрузите файл акции"
                  color={formik.errors.file && 'failure'}
                />
              </div>
              <FileInput
                onChange={(e) => {
                  if (!e.target?.files) return;
                  if (!e?.target?.files[0]?.name.endsWith('.xlsx')) {
                    toast.error(
                      'Ошибка! Можно импортировать только xlsx файл.',
                      { position: 'bottom-right' },
                    );
                  }
                  formik.setFieldValue(
                    'file',
                    e.target.files ? e.target.files[0] : null,
                  );
                }}
                helperText={
                  <FileInputHelperText
                    marketplace={formik.values.marketplace}
                    onLinkClick={() =>
                      trackEvent(MetricEventType.ADDPROMO_FORM_LINK)
                    }
                  />
                }
                id="file"
              />

              {formik.touched.file && (
                <i className="text-red-500 text-sm pt-4">
                  {formik.errors.file}
                </i>
              )}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="flex justify-end">
          <Button onClick={onCloseLocal} color="gray">
            Отмена
          </Button>
          <Button
            className="bg-primary-600 enabled:hover:bg-primary-500"
            onClick={() => formik.handleSubmit()}
            disabled={isLoading}
            isProcessing={isLoading}>
            Добавить акцию
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default NewDiscountCampaignModal;
