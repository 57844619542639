import { Spinner } from 'flowbite-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  LoginResponse,
  useGoogleLoginMutation,
  useYandexLoginMutation,
} from '../../api/api_index';
import { setUser } from '../../redux/auth/slice';
import { useTypedDispatch } from '../../redux/hooks';
import AuthButtonWrapper from './buttons/AuthButtonWrapper';
import GoogleAuthButton from './buttons/GoogleAuthButton';
import VkAuthButton from './buttons/VkAuthButton';

const oauthQueryParams = {
  client_id: process.env.REACT_APP_YANDEX_OAUTH_CLIENT_ID,
  response_type: 'token',
  redirect_uri: process.env.REACT_APP_YANDEX_OAUTH_REDIRECT_URI,
};

function ThirdPartyAuthWidget() {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();

  const [yandexLogin] = useYandexLoginMutation();
  const [googleLogin] = useGoogleLoginMutation();

  const [isYandexScriptInit, setIsYandexScriptInit] = useState(false);
  const [isGoogleScriptInit, setIsGoogleScriptInit] = useState(false);
  const [isVkScriptInit, setIsVkScriptInit] = useState(false);

  const areAllScriptsInit =
    isYandexScriptInit && isGoogleScriptInit && isVkScriptInit;

  function yandexAuthCleaner() {
    document.querySelector(`script[data-script-type="yandex"]`)?.remove();
    document.querySelector(`.yaPersonalButtonContainer`)?.remove();
    document.getElementById('iframe')?.remove();
    document?.querySelector('#yaPersonalButtonStyle')?.remove();
  }

  //remove yandex id button, script and styling on component unmount
  useEffect(() => {
    return yandexAuthCleaner;
  }, []);

  // yandex
  useEffect(() => {
    const yandex_script = document.createElement('script');
    yandex_script.setAttribute('data-script-type', 'yandex');
    yandex_script.src =
      'https://yastatic.net/s3/passport-sdk/autofill/v1/sdk-suggest-with-polyfills-latest.js';

    yandex_script.onload = function () {
      (window as any).YaAuthSuggest.init(
        oauthQueryParams,
        process.env.REACT_APP_SITE_ORIGIN,
        {
          view: 'button',
          parentId: 'yandex-auth-btn',
          buttonSize: 's',
          buttonView: 'icon',
          buttonTheme: 'light',
          buttonBorderRadius: '18',
          buttonIcon: 'ya',
        },
      )
        .then(({ handler }: any) => {
          setIsYandexScriptInit(true);
          handler();
        })
        .then((data: any) => handleOAuthSignin(data, 'yandex'))
        .catch((error: any) => console.log('Обработка ошибки', error));
    };

    document.head.appendChild(yandex_script);
    window.addEventListener('message', handleReceiveMessage, false);

    return () =>
      window.removeEventListener('message', handleReceiveMessage, false);
  }, []);

  function handleReceiveMessage(event: any) {
    if (
      event.origin !== process.env.REACT_APP_SITE_ORIGIN ||
      event.data.type !== 'token'
    )
      return;

    console.log('token: ', event.data.payload.access_token);
    handleOAuthSignin(event.data.payload, 'yandex');
  }

  // vk
  useEffect(() => {
    const vk_script = document.createElement('script');
    vk_script.src = 'https://unpkg.com/@vkid/sdk@latest/dist-sdk/umd/index.js'; // whatever url you want here
    vk_script.charset = 'utf-8';
    vk_script.async = true;
    vk_script.onload = function () {
      setIsVkScriptInit(true);

      (window as any).VKIDSDK.Config.set({
        app: process.env.REACT_APP_VK_OAUTH_APP_ID, // Идентификатор приложения.
        redirectUrl: process.env.REACT_APP_VK_OAUTH_REDIRECT_URI, // Адрес для перехода после авторизации.
      });

      const button = document.getElementById('vk-auth-btn');
      // Проверка наличия кнопки в разметке.
      if (button) {
        // Добавление обработчика клика по кнопке.
        button.onclick = () => {
          (window as any).VKIDSDK.Auth.login();
        };
      }
    };

    document.head.appendChild(vk_script);
  }, []);

  // google
  useEffect(() => {
    const google_script = document.createElement('script');
    google_script.src = 'https://accounts.google.com/gsi/client'; // whatever url you want here
    google_script.charset = 'utf-8';
    google_script.async = true;
    google_script.onload = function () {
      (window as any).google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
        callback: (response: any) => handleOAuthSignin(response, 'google'),
      });

      setIsGoogleScriptInit(true);

      (window as any).google.accounts.id.renderButton(
        document.getElementById('google-auth-btn'),
        { theme: 'dark', size: 'large', width: 50 }, // customization attributes
      );
    };

    document.head.appendChild(google_script);
  }, []);

  async function handleOAuthSignin(
    data: any,
    type: 'yandex' | 'vk' | 'google',
  ) {
    if (!data) return;
    let res: LoginResponse | null = null;

    try {
      if (type === 'yandex') {
        res = await yandexLogin(data.access_token).unwrap();
      } else if (type === 'google') {
        res = await googleLogin({ jwt_token: data.credential }).unwrap();
      }

      if (res) {
        dispatch(
          setUser({
            name: res.user.name as string,
            username: res.user.email,
            uid: res.user.id,
          }),
        );
        await sessionStorage.setItem('access_token', res.access_token);
        navigate('/account/unit-economy');
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <p className="w-full text-center text-gray-500">Или войти с помощью</p>
      <div id="google-auth-btn" style={{ display: 'none' }}></div>
      <div className="flex justify-center items-center gap-4">
        <AuthButtonWrapper
          type="yandex"
          id="yandex-auth-btn"
          button={
            <div className="relative border border-gray-300 rounded-md flex justify-center items-center bg-gray-50 w-14 h-14 hover:bg-gray-100 cursor-pointer transition-all">
              {!isYandexScriptInit && (
                <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                  <Spinner className="fill-black" />
                </div>
              )}
              <div
                id="yandex-auth-btn"
                className="transition-opacity w-14"
                style={{ opacity: isYandexScriptInit ? 1 : 0.1 }}></div>
            </div>
          }
        />
        <AuthButtonWrapper
          type="google"
          id="google-auth-btn"
          button={<GoogleAuthButton />}
        />
        <VkAuthButton />
      </div>
    </>
  );
}

export default ThirdPartyAuthWidget;
