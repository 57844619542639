import { Button, TextInput } from 'flowbite-react';
import { useEffect, useState } from 'react';
import { BsSearch } from 'react-icons/bs';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  useCreateProcurementMutation,
  useDeleteProcurementMutation,
  useLazyFetchAllProcurementsQuery,
} from '../../api/procurement_api';
import { EntityActionContext } from '../../components/EntityAction/EntityActionContext';
import ProcurementModal, {
  TProcurementForm,
} from '../../components/procurement/ProcurementModal/ProcurementModal';
import ProcurementTable from '../../components/procurement/ProcurementTable';
import TablePaginator from '../../components/ui/TablePaginator';
import useDebounce from '../../hooks/useDebounce';
import usePaginate from '../../hooks/usePaginate';
import { useTypedDispatch } from '../../redux/hooks';

function AllProcurementView() {
  const [searchParams, setSearchParams] = useSearchParams();
  const isCreateNew = searchParams.get('create') ? true : false;

  const [modalOpen, setModalOpen] = useState(isCreateNew);
  const [confirmOpen, setConfirmOpen] = useState<number | null>(null);

  const [createProcurement, { isLoading: isCreateProcurementLoading }] =
    useCreateProcurementMutation();

  const [fetchAllProcurements, { data: allProcurementsData }] =
    useLazyFetchAllProcurementsQuery();

  const [search, setSearch] = useState('');
  const [deleteProcurement, { isLoading: isDeleteLoading }] =
    useDeleteProcurementMutation();

  const onModalOpen = () => {
    setModalOpen(true);
  };

  const onModalClose = () => {
    setModalOpen(false);
    if (isCreateNew) {
      navigate('/account/procurement');
    }
  };

  const dispatch = useTypedDispatch();
  const navigate = useNavigate();

  const onSubmitProcurementForm = async (
    data: TProcurementForm,
    isEdit: boolean,
  ) => {
    try {
      const res = await createProcurement(data).unwrap();
      //redirect to sale page
      navigate(`/account/procurement/${res.id}`);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    async function getProcurements() {
      try {
        await fetchAllProcurements({ take: 10, page: 0 }).unwrap();
      } catch {}
    }

    getProcurements();
  }, []);

  useEffect(() => {
    setModalOpen(searchParams.get('create') ? true : false);
  }, [isCreateNew]);

  async function handleDeleteDiscountCampaign(id: number) {
    try {
      await deleteProcurement(id).unwrap();
    } catch (err) {
      console.log(err);
    }
  }

  const debouncedSearch = useDebounce(search, 500);
  const { paginationState, handleChangePage, resetState } = usePaginate({
    handlePaginate,
  });

  async function handlePaginate(take: number, page: number) {
    try {
      const params = { take, page };
      if (search) {
        (params as any).search = search;
      }
      const resp = await fetchAllProcurements(params);

      return (resp as any).data?.max_page;
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchAllProcurements({
      take: 10,
      page: 0,
      search: debouncedSearch,
    });
    resetState();
  }, [debouncedSearch]);

  return (
    <div>
      <div className="flex flex-row justify-between">
        <h2 className="font-bold text-3xl">Закупки</h2>
      </div>

      <div className="flex flex-row gap-4 my-7 items-center">
        <TextInput
          icon={BsSearch}
          id="search"
          placeholder="Поиск"
          required
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />

        {/* <Button color="gray">Поиск</Button> */}

        <Button
          className="bg-primary-600 w-auto ml-auto enabled:hover:bg-primary-500"
          onClick={onModalOpen}>
          Добавить новую закупку
        </Button>
      </div>

      <EntityActionContext.Provider
        value={{
          deleteLoading: isDeleteLoading,
          handleChange: async (id) => {
            navigate(`/account/procurement/${id}`);
          },
          handleDelete: handleDeleteDiscountCampaign,
          setConfirmOpen: setConfirmOpen,
          showDeleteButton: true,
        }}>
        <ProcurementTable
          data={allProcurementsData?.procurements || []}
          paginator={
            <TablePaginator
              handleChangePage={handleChangePage}
              paginationState={paginationState}
              maxPage={allProcurementsData?.max_page}
            />
          }
        />
      </EntityActionContext.Provider>

      <ProcurementModal
        isOpen={modalOpen}
        onClose={onModalClose}
        onSubmit={onSubmitProcurementForm}
        isLoading={isCreateProcurementLoading}
      />
    </div>
  );
}
export default AllProcurementView;
