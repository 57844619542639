import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CreateDiscountCampaignProductDto } from '../../../../backend/src/discount_campaign/dto/create_discount_campaign_product.dto';
import { GetDiscountCampaignProductDto } from '../../../../backend/src/discount_campaign/dto/get_discount_campaign_product.dto';

type DiscountCampaignProductDto = CreateDiscountCampaignProductDto & {
  id: number;
};
export type GetDiscountCampaignProductType = GetDiscountCampaignProductDto;
interface DiscountCampaignSliceState {
  id: number | null;
  file: string;
  name: string;
  start_date: string | null;
  end_date: string | null;
  status: string;
  marketplace: { code: 'wb' | 'ozon'; name?: string };
  products: DiscountCampaignProductDto[];
}

const initialState: DiscountCampaignSliceState = {
  id: null,
  file: '',
  name: '',
  start_date: null,
  end_date: null,
  status: '',
  marketplace: {
    code: 'wb',
  },
  products: [],
};

const saleSlice = createSlice({
  name: 'discount_campaign',
  initialState: initialState,
  reducers: {
    setDiscountCampaignProduct: (
      state,
      action: PayloadAction<{
        idx: number;
        product: DiscountCampaignProductDto;
      }>,
    ) => {
      state.products[action.payload.idx] = action.payload.product;
    },
    addDiscountCampaignProduct: (
      state,
      action: PayloadAction<DiscountCampaignProductDto>,
    ) => {
      state.products = [...state.products, { ...action.payload }];
    },
    removeDiscountCampaignProduct: (state, action: PayloadAction<number>) => {
      const tmp = [...state.products];
      tmp.splice(action.payload, 1);
      state.products = tmp;
    },
    setDiscountCampaign: (
      state,
      action: PayloadAction<DiscountCampaignSliceState>,
    ) => {
      state.name = action.payload.name;
      state.id = action.payload.id;
      state.file = action.payload.file;
      state.start_date = action.payload.start_date;
      state.end_date = action.payload.end_date;
      state.status = action.payload.status;
      state.products = [];
      state.products = action.payload.products;
    },
    clearDiscountCampaign: (state) => {
      state.name = initialState.name;
      state.start_date = initialState.start_date;
      state.id = initialState.id;
      state.file = initialState.file;
      state.end_date = initialState.end_date;
      state.status = initialState.status;
      state.products = initialState.products;
    },
  },
});

export const {
  setDiscountCampaign,
  clearDiscountCampaign,
  setDiscountCampaignProduct,
  addDiscountCampaignProduct,
  removeDiscountCampaignProduct,
} = saleSlice.actions;

export default saleSlice.reducer;
export { initialState as initDiscountCampaignState };
export type { DiscountCampaignSliceState, DiscountCampaignProductDto };
