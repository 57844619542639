import { Button, Modal } from 'flowbite-react';
import React, { useEffect, useRef, useState } from 'react';
import {
  CreateDiscountCampaignProduct,
  CreateDiscountCampaignType,
  FetchDiscountCampaignProduct,
} from '../../../api/discount_campaign_api';
import { useFormik } from 'formik';
import { ProcurementFormSchema } from './validationSchema';
import ProcurementModalForm from './ProcurementModalForm';
import { useTypedDispatch } from '../../../redux/hooks';
import { setStepIndex } from '../../../redux/onboarding_tour/slice';
import { OnboardingTourStep } from '../../../redux/onboarding_tour/steps';
import { IProcurement } from '../../../api/procurement_api';

export type TProcurementForm = Pick<
  IProcurement,
  'name' | 'buyer_id' | 'logistician_id'
>;

const formInitValues: TProcurementForm = {
  name: '',
  buyer_id: null,
  logistician_id: null,
};

interface IProcurementModalProps {
  onClose: () => void;
  isOpen: boolean;
  onSubmit: (values: TProcurementForm, isEdit: boolean) => Promise<void>;
  procurement?: IProcurement;
  isLoading: boolean;
}

function ProcurementModal({
  onClose,
  isOpen,
  onSubmit,
  procurement,
  isLoading,
}: IProcurementModalProps) {
  const [isVisible, setIsVisible] = useState(true);
  const isEdit = !!procurement;
  const modalTitle = isEdit ? 'Редактирование закупки' : 'Новая закупка';
  const buttonTitle = isEdit ? 'Сохранить закупку' : 'Создать закупку';
  const rootRef = useRef<HTMLDivElement>(null);

  //formik state
  const formik = useFormik<TProcurementForm>({
    initialValues: formInitValues,
    onSubmit: (values) => onSubmit(values, isEdit),
    validationSchema: ProcurementFormSchema,
    isInitialValid: false,
  });

  //handle onopen and on select product
  useEffect(() => {
    if (!procurement) {
      formik.resetForm();
      return;
    }

    formik.setValues({
      name: procurement.name,
      buyer_id: procurement.buyer_id,
      logistician_id: procurement.logistician_id,
    });
  }, [procurement, isOpen]);

  //close form function
  async function handleClose() {
    await formik.resetForm();
    onClose();
  }

  return (
    <div ref={rootRef}>
      <Modal
        className={isVisible ? 'visible' : 'invisible'}
        theme={{
          root: {
            base: 'z-[10000]',
          },
        }}
        show={isOpen}
        onClose={handleClose}
        root={rootRef.current ?? undefined}>
        <Modal.Header>{modalTitle}</Modal.Header>
        <Modal.Body theme={{ base: 'overflow-visible p-6' }}>
          <ProcurementModalForm
            values={formik.values}
            errors={formik.errors}
            handleChange={formik.handleChange}
            setFieldValue={formik.setFieldValue}
            isEdit={isEdit}
            touched={formik.touched}
            setIsParentModalVisible={setIsVisible}
          />
        </Modal.Body>
        <Modal.Footer className="flex justify-end">
          <Button onClick={onClose} color="gray">
            Отмена
          </Button>
          <Button
            className="bg-primary-600 enabled:hover:bg-primary-500"
            onClick={() => formik.handleSubmit()}
            disabled={isLoading}
            isProcessing={isLoading}>
            {buttonTitle}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ProcurementModal;
