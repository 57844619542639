import { appApi } from './api_index';

export interface IProductUnitEconomicResearch {
  id: number;
  competitor_url: string;
  marketplace_product_price: number;
  analytics_url: string;
  marketplace_fee: number;
  marketplace_logistics_expense: number;
  supplier_logistic_expense: number;
  usd_rub: number;
  cny_rub: number;
  margin_per_product: number;
  margin_rate: number;
  product_count: number;
  fund: number;
  soldout_margin: number;
  supplier_product_id: number;
  supplier_product: ISupplierProduct;
}

interface ISupplierProduct {
  id: number;
  name: string;
  weight: number;
  product_url: string;
  logistics_rate: number;
  currency: string;
  product_price: number;
}

interface ICreateProductUnitEconomicResearch {
  competitor_url: string;
  marketplace_product_price: number;
  analytics_url: string;
  marketplace_fee: number;
  marketplace_logistics_expense: number;
  usd_rub: number;
  cny_rub: number;
  product_count: number;
  product: Omit<ISupplierProduct, 'id'>;
}

export interface IFetchProductUnitEconomicResearches {
  researches: IProductUnitEconomicResearch[];
  count: number;
  max_page: number;
}

const unitEconomyApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchAllUnitEconomyResearches: builder.query<
      IFetchProductUnitEconomicResearches,
      any
    >({
      query: (args: any) => ({
        url: `product-unit-economic-research`,
        credentials: 'include',
        params: { ...args },
      }),
      providesTags: ['ProductUnitEconomicResearch'],
    }),
    createUnitEconomyResearch: builder.mutation<
      IProductUnitEconomicResearch,
      ICreateProductUnitEconomicResearch
    >({
      query: (body) => ({
        url: `product-unit-economic-research`,
        credentials: 'include',
        body: body,
        method: 'POST',
      }),
      invalidatesTags: ['ProductUnitEconomicResearch'],
    }),
    updateUnitEconomyResearch: builder.mutation<
      IProductUnitEconomicResearch,
      { data: ICreateProductUnitEconomicResearch; id: number }
    >({
      query: ({ data, id }) => ({
        url: `product-unit-economic-research/${id}`,
        credentials: 'include',
        body: data,
        method: 'PATCH',
      }),
      invalidatesTags: ['ProductUnitEconomicResearch'],
    }),
    deleteUnitEconomyResearch: builder.mutation<
      IProductUnitEconomicResearch,
      number
    >({
      query: (id) => ({
        url: `product-unit-economic-research/${id}`,
        credentials: 'include',
        method: 'DELETE',
      }),
      invalidatesTags: ['ProductUnitEconomicResearch'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyFetchAllUnitEconomyResearchesQuery,
  useCreateUnitEconomyResearchMutation,
  useUpdateUnitEconomyResearchMutation,
  useDeleteUnitEconomyResearchMutation,
} = unitEconomyApi;
