import { CellContext, createColumnHelper } from '@tanstack/react-table';
import { ToggleSwitch } from 'flowbite-react';
import React from 'react';
import { BiHelpCircle } from 'react-icons/bi';
import { CreateDiscountCampaignProduct } from '../../api/discount_campaign_api';
import useColumnVisibility from '../../hooks/useColumnVisibility';
import { GetDiscountCampaignProductType } from '../../redux/discount_campaign/slice';
import { useTypedDispatch } from '../../redux/hooks';
import { pauseTour, startTour } from '../../redux/onboarding_tour/slice';
import { cellColorFormatter } from '../../utils/cellColorFormatter';
import { priceLocalFormat } from '../../utils/priceFormatter';
import trackEvent, { MetricEventType } from '../../utils/trackEvent';
import validateDiscountCampaignProduct from '../../utils/validateDiscountCampaignProduct';
import EntityAction from '../EntityAction/EntityAction';
import EditableTableCell from '../ui/EditableTableCell';
import Popover from '../ui/Popover';
import Table from '../ui/Table';
import SimpleTooltip from '../ui/Tooltip';
import ProductIsEnabledCheckbox from './ProductIsEnabledCheckbox';
import {
  TableComissionHint,
  TableLogisticsHint,
  TableProductCostHint,
} from './TableHint';

interface IProductTableProps extends React.ComponentPropsWithoutRef<'div'> {
  data: GetDiscountCampaignProductType[];
  paginator?: React.ReactNode;
  addonComponent?: React.ReactNode;
  handlePatchProduct: (
    data: Partial<CreateDiscountCampaignProduct> & { id: number },
  ) => Promise<void>;
}

function ProductTable({
  data,
  addonComponent,
  handlePatchProduct,
  ...props
}: IProductTableProps) {
  const { columnVisibility, onToggle, mode } = useColumnVisibility([
    'margin_rate',
    'product_nomenclature',
    'current_retail_price',
    'current_discount',
    'final_discount',
  ]);

  const columnHelper = createColumnHelper<GetDiscountCampaignProductType>();
  const dispatch = useTypedDispatch();

  function onToggleLocal(checked: boolean) {
    dispatch(pauseTour());
    setTimeout(() => {
      dispatch(startTour());
    }, 500);

    onToggle(checked);
  }

  const columns = [
    {
      header: 'Действия',
      cell: (props: any) => <EntityAction row={props.row} />,
    },
    columnHelper.accessor('product.title', {
      header: () => <span className="block text-left w-full">Предмет</span>,
      cell: (info) => (
        <EditableTableCell
          canBeEdited={!info.row.original.is_enabled_by_default}
          value={info.getValue()}
          spanClassName="block w-full text-left"
          validate={(val) => (val as string)?.length > 0}
          onChange={async (val) =>
            handlePatchProduct({
              title: val as string,
              id: info.row.original.id,
            })
          }
          type="text"
        />
      ),
    }),
    columnHelper.accessor('supplier_num', {
      header: 'Артикул поставщика',
      cell: (info) => (
        <CellWithBlur isBlur={info.row.original.hidden}>
          <EditableTableCell
            canBeEdited={!info.row.original.is_enabled_by_default}
            value={info.getValue()}
            validate={(val) => (val as string)?.length > 0}
            onChange={async (val) =>
              handlePatchProduct({
                supplier_num: val as string,
                id: info.row.original.id,
              })
            }
          />
        </CellWithBlur>
      ),
    }),
    columnHelper.accessor('is_enabled', {
      header: 'Участие в акции',
      cell: (info) => (
        <CellWithBlur isBlur={info.row.original.hidden}>
          <ProductIsEnabledCheckbox
            info={info}
            handlePatchProduct={handlePatchProduct}
          />
        </CellWithBlur>
      ),
    }),
    columnHelper.accessor('discount_margin_rate', {
      header: () => <span id="onboarding-step-7">Маржинальность в акции</span>,
      cell: (info) => {
        const error = validateDiscountCampaignProduct(info.row.original);
        if (error) {
          return (
            <CellWithBlur isBlur={info.row.original.hidden}>
              <div className="flex items-center justify-center gap-2">
                <span>Н/Д</span>
                <SimpleTooltip content="Нужно заполнить все поля!">
                  <BiHelpCircle size={20} />
                </SimpleTooltip>
              </div>
            </CellWithBlur>
          );
        }

        return (
          <CellWithBlur isBlur={info.row.original.hidden}>
            <span>{`${(info.getValue() * 100).toFixed(2)}%`}</span>
          </CellWithBlur>
        );
      },
      meta: {
        getCellContext: (
          context: CellContext<GetDiscountCampaignProductType, unknown>,
        ) => {
          if (validateDiscountCampaignProduct(context.row.original)) {
            return {
              className: 'bg-red-100 text-red-700',
            };
          }
          return {
            className: cellColorFormatter(
              context.row.original.discount_margin_rate,
            ),
          };
        },
      },
    }),
    columnHelper.accessor('planned_discount_price', {
      header: 'Плановая цена для акции',
      cell: (info) => (
        <CellWithBlur isBlur={info.row.original.hidden}>
          <EditableTableCell
            canBeEdited={!info.row.original.is_enabled_by_default}
            value={info.getValue()}
            validate={(val) => (val as number) >= 0}
            onChange={async (val) =>
              handlePatchProduct({
                planned_discount_price: +val,
                id: info.row.original.id,
              })
            }
            formattedValue={priceLocalFormat(info.getValue(), 'RUB', 'ru')}
            type="number"
          />
        </CellWithBlur>
      ),
    }),
    columnHelper.accessor('margin_profit', {
      header: 'Маржинальный доход',
      cell: (info) => (
        <CellWithBlur isBlur={info.row.original.hidden}>
          <span>{priceLocalFormat(info.getValue(), 'RUB', 'ru')}</span>
        </CellWithBlur>
      ),
    }),
    columnHelper.accessor('marketplace_in_stock_amount', {
      header: () => <span className="whitespace-nowrap">Остаток, шт</span>,
      cell: (info) => (
        <CellWithBlur isBlur={info.row.original.hidden}>
          <EditableTableCell
            canBeEdited={!info.row.original.is_enabled_by_default}
            value={info.getValue()}
            validate={(val) => (val as number) >= 0}
            onChange={async (val) =>
              handlePatchProduct({
                marketplace_in_stock_amount: +val,
                id: info.row.original.id,
              })
            }
            type="number"
          />
        </CellWithBlur>
      ),
    }),
    columnHelper.accessor('product.nomenclature', {
      header: 'Номенклатура 1С',
      cell: (info) => (
        <CellWithBlur isBlur={info.row.original.hidden}>
          <EditableTableCell
            canBeEdited={!info.row.original.is_enabled_by_default}
            value={info.getValue()}
            validate={(val) => (val as number) >= 0}
            onChange={async (val) =>
              handlePatchProduct({
                nomenclature: +val,
                id: info.row.original.id,
              })
            }
            type="number"
          />
        </CellWithBlur>
      ),
    }),
    columnHelper.accessor('current_price', {
      header: 'Цена текущая',
      cell: (info) => (
        <CellWithBlur isBlur={info.row.original.hidden}>
          <span>{priceLocalFormat(info.getValue(), 'RUB', 'ru')}</span>
        </CellWithBlur>
      ),
    }),
    columnHelper.accessor('price', {
      header: () => (
        <div
          className="flex items-center gap-2 relative"
          id="onboarding-step-4">
          <span>Себеcтоимость</span>
          <Popover
            button={<BiHelpCircle size={20} />}
            title="Себестоимость"
            body={<TableProductCostHint />}
          />
        </div>
      ),
      cell: (info) => (
        <CellWithBlur isBlur={info.row.original.hidden}>
          <EditableTableCell
            value={info.getValue()}
            validate={(val) => (val as number) > 0}
            onChange={async (val) => {
              handlePatchProduct({
                price: +val,
                id: info.row.original.id,
              });
              trackEvent(MetricEventType.ADDPROMO_WORK_FIELDS, { cost: true });
            }}
            formattedValue={priceLocalFormat(info.getValue(), 'RUB', 'ru')}
            type="number"
          />
        </CellWithBlur>
      ),
      meta: {
        getCellContext: (
          context: CellContext<GetDiscountCampaignProductType, unknown>,
        ) => {
          return {
            className:
              context.row.original.price === 0 ? 'bg-red-100 text-red-700' : '',
          };
        },
      },
    }),
    columnHelper.accessor('commision_rate', {
      header: () => (
        <div className="flex items-center gap-2 relative">
          <span>Комиссия маркетплейса</span>
          <Popover
            button={<BiHelpCircle size={20} />}
            title="Комиссия маркетплейса"
            body={<TableComissionHint />}
          />
        </div>
      ),
      cell: (info) => (
        <CellWithBlur isBlur={info.row.original.hidden}>
          <EditableTableCell
            canBeEdited={!info.row.original.is_enabled_by_default}
            value={info.getValue()}
            validate={(val) => (val as number) >= 0}
            onChange={async (val) =>
              handlePatchProduct({
                commision_rate: +val,
                id: info.row.original.id,
              })
            }
            formattedValue={`${info.getValue()}%`}
            type="number"
          />
        </CellWithBlur>
      ),
    }),
    columnHelper.accessor('logistic_expense', {
      header: () => (
        <div
          className="flex items-center gap-2 relative"
          id="onboarding-step-5">
          <span>Логистика</span>
          <Popover
            button={<BiHelpCircle size={20} />}
            title="Логистика маркетплейса"
            body={<TableLogisticsHint />}
          />
        </div>
      ),
      cell: (info) => (
        <CellWithBlur isBlur={info.row.original.hidden}>
          <EditableTableCell
            value={info.getValue()}
            validate={(val) => (val as number) > 0}
            onChange={async (val) => {
              handlePatchProduct({
                logistic_expense: +val,
                id: info.row.original.id,
              });
              trackEvent(MetricEventType.ADDPROMO_WORK_FIELDS, {
                shipping: true,
              });
            }}
            formattedValue={priceLocalFormat(info.getValue(), 'RUB', 'ru')}
            type="number"
          />
        </CellWithBlur>
      ),
      meta: {
        getCellContext: (
          context: CellContext<GetDiscountCampaignProductType, unknown>,
        ) => {
          return {
            className:
              context.row.original.logistic_expense === 0
                ? 'bg-red-100 text-red-700'
                : '',
          };
        },
      },
    }),
    columnHelper.accessor('commision_reduction', {
      header: () => (
        <div className="px-3" id="onboarding-step-6">
          <span className="block w-full text-center">
            Снижение комиссии в акции
          </span>
        </div>
      ),
      cell: (info) => (
        <CellWithBlur isBlur={info.row.original.hidden}>
          <EditableTableCell
            canBeEdited={!info.row.original.is_enabled_by_default}
            value={info.getValue()}
            validate={(val) => (val as number) >= 0}
            onChange={async (val) => {
              handlePatchProduct({
                commision_reduction: +val,
                id: info.row.original.id,
              });
              trackEvent(MetricEventType.ADDPROMO_WORK_FIELDS, {
                discount: true,
              });
            }}
            formattedValue={`${info.getValue()}%`}
            type="number"
          />
        </CellWithBlur>
      ),
      meta: {
        getCellContext: (
          context: CellContext<GetDiscountCampaignProductType, unknown>,
        ) => {
          return {
            className:
              context.row.original.commision_reduction === 0
                ? 'bg-yellow-100 text-yellow-700'
                : '',
          };
        },
      },
    }),
    columnHelper.accessor('total_commission_rate', {
      header: 'Комиссия в акции',
      cell: (info) => (
        <CellWithBlur isBlur={info.row.original.hidden}>
          <span>{`${info.getValue()}%`}</span>
        </CellWithBlur>
      ),
    }),
    columnHelper.accessor('margin_rate', {
      header: 'Маржинальность',
      cell: (info) => (
        <CellWithBlur isBlur={info.row.original.hidden}>
          <span>{`${(info.getValue() * 100).toFixed(2)}%`}</span>
        </CellWithBlur>
      ),
    }),
    columnHelper.accessor('current_retail_price', {
      header: 'Текущая розничная цена',
      cell: (info) => (
        <CellWithBlur isBlur={info.row.original.hidden}>
          <EditableTableCell
            canBeEdited={!info.row.original.is_enabled_by_default}
            value={info.getValue()}
            validate={(val) => (val as number) >= 0}
            onChange={async (val) =>
              handlePatchProduct({
                current_retail_price: +val,
                id: info.row.original.id,
              })
            }
            formattedValue={priceLocalFormat(info.getValue(), 'RUB', 'ru')}
            type="number"
          />
        </CellWithBlur>
      ),
    }),
    columnHelper.accessor('current_discount', {
      header: () => <div className="px-3">Текущая скидка на сайте, %</div>,
      cell: (info) => (
        <CellWithBlur isBlur={info.row.original.hidden}>
          <EditableTableCell
            canBeEdited={!info.row.original.is_enabled_by_default}
            value={info.getValue()}
            validate={(val) => (val as number) >= 0}
            onChange={async (val) =>
              handlePatchProduct({
                current_discount: +val,
                id: info.row.original.id,
              })
            }
            formattedValue={`${info.getValue()}%`}
            type="number"
          />
        </CellWithBlur>
      ),
    }),
    columnHelper.accessor('final_discount', {
      header: 'Загружаемая скидка для участия в акции',
      cell: (info) => (
        <CellWithBlur isBlur={info.row.original.hidden}>
          <EditableTableCell
            canBeEdited={!info.row.original.is_enabled_by_default}
            value={info.getValue()}
            validate={(val) => (val as number) >= 0}
            onChange={async (val) =>
              handlePatchProduct({
                final_discount: +val,
                id: info.row.original.id,
              })
            }
            formattedValue={`${info.getValue()}%`}
            type="number"
          />
        </CellWithBlur>
      ),
    }),
  ];

  return (
    <Table
      columns={columns}
      data={data || []}
      paginator={null}
      {...props}
      fixedColumns={{ Действия: 0, product_title: 93.34 }}
      columnVisibility={columnVisibility}
      cellsToColorFormat={{ discount_margin_rate: true }}
      tableModeComponent={
        <div className="flex items-center justify-between pb-4">
          {addonComponent}
          <ToggleSwitch
            className="my-3"
            checked={mode === 'full'}
            onChange={onToggleLocal}
            label="Расширенный режим"
            theme={{
              toggle: { checked: { color: { blue: 'bg-primary-600' } } },
            }}
          />
        </div>
      }
    />
  );
}

function CellWithBlur({
  isBlur,
  children,
}: {
  isBlur?: boolean;
  children: React.ReactNode;
}) {
  const style: React.CSSProperties = isBlur
    ? { filter: 'blur(4px)', pointerEvents: 'none', userSelect: 'none' }
    : {};
  return (
    <div style={style} onClick={(e) => e.stopPropagation()}>
      {children}
    </div>
  );
}
export default ProductTable;
