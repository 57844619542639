import React from 'react';

function FormErrorMessage(props: { error?: string; touched?: boolean }) {
  const { error, touched } = props;
  if (!error || !touched) {
    return null;
  }

  return <i className="text-red-600 text-sm">{error}</i>;
}

export default FormErrorMessage;
