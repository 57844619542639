import { priceLocalFormat } from '../../utils/priceFormatter';
import { TariffPlan } from '../../views/tariff/SelectTariffView';

interface ITariffPlanItemCardProps {
  tariff: TariffPlan;
  onSelect: () => void;
  isActive: boolean;
}

export default function TariffPlanItemCard({
  tariff,
  onSelect,
  isActive,
}: ITariffPlanItemCardProps) {
  const actionButtonText = isActive ? 'Текущий' : 'Выбрать';
  const actionButtonClassName = `select-none text-white focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-primary-900 mt-auto w-full ${
    isActive
      ? 'bg-primary-300 cursor-not-allowed'
      : 'cursor-pointer bg-primary-600 hover:bg-primary-500'
  }`;

  return (
    <div
      className={`flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 rounded-lg border dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white w-full items-center ${
        isActive ? 'bg-gray-100 border-gray-200 shadow-xl -translate-y-2' : 'bg-white border-gray-100 shadow-md'
      }`}>
      <h3 className="mb-4 text-2xl font-semibold">{tariff.title}</h3>
      <div className="flex justify-center items-baseline my-8">
        <span className="mr-2 text-5xl font-extrabold">
          {priceLocalFormat(tariff.price, 'RUB', 'ru')}
        </span>
        <span className="text-gray-500 dark:text-gray-400">/месяц</span>
      </div>
      <ul role="list" className="mb-8 space-y-4 text-left">
        {tariff.features.map((tariffFeature, tariffFeatureIndex) => (
          <li className="flex items-center space-x-3" key={tariffFeatureIndex}>
            <svg
              className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clipRule="evenodd"></path>
            </svg>
            <span>{tariffFeature}</span>
          </li>
        ))}
      </ul>
      <a onClick={onSelect} className={actionButtonClassName}>
        {actionButtonText}
      </a>
    </div>
  );
}
