const opacity = 'bg-opacity-100';
const saturation = 100;
export function cellColorFormatter(value: number): string {
  let color = '';

  if (value < 0.1) {
    color = `bg-red-${saturation} text-red-700 ${opacity}`;
  } else if (value < 0.25) {
    color = `bg-yellow-${saturation} text-yellow-700 ${opacity}`;
  } else if (value >= 0.25) {
    color = `bg-green-${saturation} text-green-700 ${opacity}`;
  }

  return color;
}

export function unitCellColorFormatter(value: number): string {
  let color = '';

  if (value <= 0.1) {
    color = `bg-red-${saturation} text-red-700 ${opacity}`;
  } else if (value <= 0.2) {
    color = `bg-yellow-${saturation} text-yellow-700 ${opacity}`;
  } else if (value <= 0.3) {
    color = `bg-lime-${saturation} text-lime-700 ${opacity}`;
  } else if (value > 0.3) {
    color = `bg-green-${saturation} text-green-700 ${opacity}`;
  }

  return color;
}
