export enum MetricEventType {
  REG_START = 'reg_start',
  REG_BUTTON = 'reg_button',
  OAUTH_BUTTON = 'oauth_button',
  REG_ERROR = 'reg_error',
  REG_SUCCESS = 'reg_success',
  MAIN_SCREEN = 'main_screen',
  MAIN_SCREEN_BUTTON1 = 'main_screen_button1',
  MAIN_SCREEN_BUTTON2 = 'main_screen_button2',
  SUPPLY_SCREEN_START = 'supply_screen_start',
  SUPPLY_ADD_CANCEL = 'supply_add_cancel',
  SUPPLY_ADD_SUCCESS = 'supply_add_success',
  SUPPLY_BUTTON_ADDNEW = 'supply_button_addnew',
  SUPPLY_ADD_FORM = 'supply_add_form',
  SUPPLY_WORK_EXPAND = 'supply_work_expand',
  SUPPLY_WORK_EDIT = 'supply_work_edit',
  SUPPLY_WORK_DELETE = 'supply_work_delete',
  ADDPROMO_BUTTON_MENU = 'addpromo_button_menu',
  ADDPROMO_BUTTON_MAIN = 'addpromo_button_main',
  ADDPROMO_FORM_UPLOAD = 'addpromo_form_upload',
  ADDPROMO_FORM_LINK = 'addpromo_form_link',
  ADDPROMO_FORM_START = 'addpromo_form_start',
  ADDPROMO_FORM_CANCEL = 'addpromo_form_cancel',
  ADDPROMO_FORM_SUCCESS = 'addpromo_form_success',
  ADDPROMO_WORK_FIELDS = 'addpromo_work_fields',
  ADDPROMO_WORK_EDIT = 'addpromo_work_edit',
  ADDPROMO_WORK_DELETE = 'addpromo_work_delete',
  ADDPROMO_WORK_SUCCESS = 'addpromo_work_success',
  HELP_PAGE_LINK = 'help_page_link',
}

interface SupplyButtonAddNewBody {
  amount: number;
}

interface SupplyAddFormBody {
  start?: boolean;
  error?: boolean;
  currency?: boolean;
}

interface AddPromoWorkFieldsBody {
  cost?: boolean;
  shipping?: boolean;
  discount?: boolean;
  choice?: boolean;
}

interface OAuthBody {
  type: 'yandex' | 'google' | 'vk';
}
interface HelpLinkBody {
  source: string;
}
//conditional type
type TrackEventBody<T> = T extends MetricEventType.SUPPLY_BUTTON_ADDNEW
  ? SupplyButtonAddNewBody
  : T extends MetricEventType.SUPPLY_ADD_FORM
  ? SupplyAddFormBody
  : T extends MetricEventType.ADDPROMO_WORK_FIELDS
  ? AddPromoWorkFieldsBody
  : T extends MetricEventType.OAUTH_BUTTON
  ? OAuthBody
  : T extends MetricEventType.HELP_PAGE_LINK
  ? HelpLinkBody
  : void;

export default function trackEvent<T extends MetricEventType>(
  eventName: T,
  trackEventBody?: TrackEventBody<T>,
) {
  const eventParams = trackEventBody || {};

  (window as any).ym?.(
    process.env.REACT_APP_YANDEX_METRICA_KEY,
    'reachGoal',
    eventName,
    eventParams,
  );

  (window as any).gtag?.('event', eventName, eventParams);
}
