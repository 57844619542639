import { Step } from 'react-joyride';

enum OnboardingTourStep {
  STEP0_OPEN_SIDEBAR,
  STEP1_OPEN_DISCOUNT_COLLAPSE,
  STEP2_CLICK_NEW_DISCOUNT_CAMPAIGN,
  STEP3_ADD_DISCOUNT_CAMPAIGN,
  STEP4_FULFILL_PRODUCT_COST,
  STEP5_FULFILL_PRODUCT_LOGISTIC_EXPENSE,
  STEP6_FULFILL_PRODUCT_COMMISION_REDUCTION,
  STEP7_ANALYZE_MARGIN_RATE,
  STEP8_DOWNLOAD_FILE,
}

export const onboardingTourSteps: Step[] = [
  {
    target: '#onboarding-step-0',
    content: 'Нажмите, чтобы раскрыть меню',
    //disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    locale: { close: 'Закрыть', skip: 'Не показывать подсказки' },
    placement: 'right',
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    title: 'Меню',
  },
  {
    target: '#onboarding-step-1',
    content: 'Нажмите на элемент меню для просмотра списка ваших акций',
    //disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    locale: { close: 'Закрыть', skip: 'Не показывать подсказки' },
    placement: 'right',
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    title: 'Меню акций',
  },
  {
    target: '#onboarding-step-2',
    content: 'Нажмите кнопку для добавления новой акции',
    //disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    locale: { close: 'Закрыть', skip: 'Не показывать подсказки' },
    placement: 'bottom',
    spotlightClicks: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    title: 'Добавление акции',
  },
  {
    target: '#onboarding-step-3',
    content: 'Заполните поля акции',
    disableBeacon: true,
    disableOverlayClose: true,
    hideCloseButton: true,
    hideFooter: true,
    placement: 'bottom',
    spotlightClicks: true,
    locale: { close: 'Закрыть', skip: 'Не показывать подсказки' },
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    title: 'Добавление акции',
  },
  {
    target: '#onboarding-step-4',
    content:
      'Заполните себестоимость товаров а акции (закупка + доставка до маркетплейса)',
    disableBeacon: true,
    disableOverlayClose: true,
    locale: { close: 'Далее', skip: 'Не показывать подсказки' },
    placement: 'bottom',
    spotlightClicks: true,
    disableScrollParentFix: true,
    disableScrolling: true,
    disableOverlay: true,
    styles: {
      options: {
        zIndex: 10000,
      },
      spotlight: {
        zIndex: 1,
      },
    },
    title: 'Себестоимость',
  },
  {
    target: '#onboarding-step-5',
    locale: { close: 'Далее', skip: 'Не показывать подсказки' },
    content: (
      <p>
        Рассчитайте логистику для каждой позиции по{' '}
        <a
          href="https://seller.wildberries.ru/dynamic-product-categories/calculator"
          target="_blank">
          ссылке
        </a>
      </p>
    ),
    disableOverlay: true,
    disableBeacon: true,
    disableOverlayClose: true,
    placement: 'bottom',
    spotlightClicks: true,
    disableScrollParentFix: true,
    disableScrolling: true,
    styles: {
      options: {
        zIndex: 10000,
      },
      spotlight: {
        zIndex: 1,
      },
    },
    title: 'Логистика',
  },
  {
    target: '#onboarding-step-6',
    content:
      'Обычно в акциях дается условие - снижается ли комиссия и насколько или нет, если снижается (например на 5%) - то обязательно внесите',
    disableOverlayClose: true,
    locale: { close: 'Далее', skip: 'Не показывать подсказки' },
    placement: 'bottom',
    spotlightClicks: true,
    disableBeacon: true,
    disableOverlay: true,
    disableScrollParentFix: true,
    disableScrolling: true,
    styles: {
      options: {
        zIndex: 10000,
      },
      spotlight: {
        zIndex: 1,
      },
    },
    title: 'Снижение комиссии',
  },
  {
    target: '#onboarding-step-7',
    locale: { close: 'Далее', skip: 'Не показывать подсказки' },
    content:
      'Проанализируйте маржинальность. Там, где маржинальность в норме или хорошая и вы готовы участвовать в акциии - поставьте галочку “участие в акции”. Если участие товара не нужно - просто оставьте поле для галочкуи пустым.',
    disableOverlayClose: true,
    placement: 'bottom',
    disableBeacon: true,
    disableOverlay: true,
    spotlightClicks: true,
    disableScrollParentFix: true,
    disableScrolling: true,
    styles: {
      options: {
        zIndex: 10000,
      },
      spotlight: {
        zIndex: 1,
      },
    },
    title: 'Анализ маржинальности',
  },
  {
    target: '#onboarding-step-8',
    content: 'Нажмите кнопку для скачивания файла акции',
    disableOverlayClose: true,
    locale: { close: 'Далее', skip: 'Не показывать подсказки' },
    placement: 'bottom',
    disableBeacon: true,
    spotlightClicks: true,
    disableScrollParentFix: true,
    disableScrolling: true,
    hideFooter: true,
    styles: {
      options: {
        zIndex: 10000,
      },
    },
    title: 'Скачивание файла акции',
  },
];

export { OnboardingTourStep };
