import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useVkLoginMutation } from '../../../api/api_index';
import { useTypedDispatch } from '../../../redux/hooks';
import { setUser } from '../../../redux/auth/slice';

function VkTokenPage() {
  const [params] = useSearchParams();
  const data = params.get('payload');
  const data_obj = JSON.parse(data as string);
  const navigate = useNavigate();
  const [vkLogin] = useVkLoginMutation();
  const dispatch = useTypedDispatch();

  useEffect(() => {
    if(!data_obj) return;
    console.log(data_obj)
    handleLogin(data_obj.token, data_obj.uuid);
  }, []);

  async function handleLogin(token: string, uuid: string) {
    try {
      const res = await vkLogin({
        access_token: token,
        uuid: uuid,
      }).unwrap();

      dispatch(
        setUser({
          name: res.user.name as string,
          username: res.user.email,
          uid: res.user.id,
        }),
      );
      await sessionStorage.setItem('access_token', res.access_token);
      navigate('/account/unit-economy');

      console.log('VK auth was successfull!');
    } catch (err) {
      console.log(err);
    }
  }

  return <div>VkTokenPage</div>;
}

export default VkTokenPage;
