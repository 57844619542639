import { useEffect, useLayoutEffect, useState } from 'react';
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS } from 'react-joyride';
import { Outlet, useNavigate } from 'react-router-dom';
import { useLazyGetAllDiscountCampaignsQuery } from '../api/discount_campaign_api';
import { useLazyFetchSubscriptionQuery } from '../api/subscription';
import { useTypedDispatch, useTypedSelector } from '../redux/hooks';
import { setStepIndex, stopTour } from '../redux/onboarding_tour/slice';
import { onboardingTourSteps } from '../redux/onboarding_tour/steps';
//import initOnBoardingTour from '../utils/initOnboardingTour';
import { useLazyFetchAllProcurementsQuery } from '../api/procurement_api';
import initOnBoardingTour from '../utils/initOnboardingTour';
import TopNavbar from './Navbar';
import AppSidebar from './Sidebar';

function Layout() {
  const { user } = useTypedSelector((state) => state.auth);
  const onboardingTourState = useTypedSelector((state) => state.oboarding_tour);
  const dispatch = useTypedDispatch();
  const [getAllDiscountCampaigns, { data: sales }] =
    useLazyGetAllDiscountCampaignsQuery();

  const [getAllProcurements, { data: procurementsData }] =
    useLazyFetchAllProcurementsQuery();

  const [getUserSubscription, { data: subscription }] =
    useLazyFetchSubscriptionQuery();

  const [open, setOpen] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const requests = [
      getAllDiscountCampaigns(),
      getAllProcurements({
        take: 5,
        page: 0,
        sort: 'created_at',
        order: 'desc',
      }),
    ];
    Promise.all(requests).then(() => {
      initOnBoardingTour(dispatch, navigate, onboardingTourState);
    });
  }, []);

  useEffect(() => {
    if (user) {
      getUserSubscription();
    }
  }, [user]);

  useLayoutEffect(() => {
    (function () {
      (function c(d, w, m, i) {
        (window as any).supportAPIMethod = m;
        const s = d.createElement('script');
        s.id = 'supportScript';
        const id = '7d14570d6c8dd266c07de304d94c61ac';
        s.src =
          (!i
            ? 'https://admin.verbox.ru/support/support.js'
            : 'https://static.site-chat.me/support/support.int.js') +
          '?h=' +
          id;
        s.onerror = i
          ? undefined
          : function () {
              c(d, w, m, true);
            };
        w[m] = w[m]
          ? w[m]
          : function () {
              (w[m].q = w[m].q ? w[m].q : []).push(arguments);
            };
        (d.head ? d.head : d.body).appendChild(s);
      })(document, window, 'Verbox');
    })();
  }, []);

  useEffect(() => {
    if (!user) {
      return;
    }
    (window as any).Verbox('setClientInfo', {
      email: user.username,
      name: user.name,
    });
  }, [user]);

  function handleJoyrideCallback(data: CallBackProps) {
    const { action, index, status, type, lifecycle } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type as any)) {
      console.log('Update state to advance the tour');
      dispatch(setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1)));
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status as any)) {
      console.log(
        'Need to set our running state to false, so we can restart if we click start again.',
      );
      // Need to set our running state to false, so we can restart if we click start again.
      dispatch(stopTour());
    }
  }

  return (
    <>
      {/* New user onboarding component */}
      <Joyride
        callback={handleJoyrideCallback}
        steps={onboardingTourSteps}
        run={onboardingTourState.run}
        stepIndex={onboardingTourState.stepIndex}
        showProgress
        showSkipButton={true}
        scrollToFirstStep
        hideBackButton
        hideCloseButton
        disableCloseOnEsc
      />

      <TopNavbar subscription={subscription} />
      <AppSidebar
        className="fixed top-16 left-0 shadow-md z-10"
        sales={sales}
        procurements={procurementsData?.procurements || []}
        open={open}
        setOpen={setOpen}
      />
      <main className={`p-8 ${open ? 'ml-64' : 'ml-16'}`}>
        <Outlet />
      </main>
    </>
  );
}

export default Layout;
