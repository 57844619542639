import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useFetchSubscriptionQuery,
  useInitSubscriptionPaymentMutation,
} from '../../api/subscription';
import TariffPlanItemCard from '../../components/tariff/TariffPlanItemCard';
import InfoModal from '../../components/ui/InfoModal';

export interface TariffPlan {
  title: string;
  price: number;
  features: string[];
  code?: 'calculators' | 'effective-seller';
}

const tariffs: TariffPlan[] = [
  {
    title: 'Базовый тариф',
    price: 0,
    features: ['Базовые возможности'],
  },
  {
    title: 'Все калькуляторы',
    price: 499,
    features: ['Неограниченное количество товаров в калькуляторах'],
    code: 'calculators',
  },
  {
    title: 'Эффективный селлер',
    price: 1990,
    features: [
      'Неограниченное количество товаров в калькуляторах',
      'Доступ к планировщику задач',
    ],
    code: 'effective-seller',
  },
];

function SelectTariffView() {
  const [initSubscriptionPayment] = useInitSubscriptionPaymentMutation();
  const {data: subscription} = useFetchSubscriptionQuery();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  async function handleTariffSelect(selectedTariffPlan: TariffPlan) {
    if (!selectedTariffPlan.code) {
      return navigate('/account/');
    }

    if (selectedTariffPlan.code == 'calculators') {
      try {
        const paymentData = await initSubscriptionPayment({
          code: selectedTariffPlan.code,
          period: 'month',
        }).unwrap();
        if (!paymentData.PaymentURL)
          throw new Error('Unexpected error occured');

        window.open(paymentData.PaymentURL, '_blank');
      } catch (initPaymentError) {
        console.log(initPaymentError);
      }
    } else {
      setModalOpen(true);
    }
  }

  const isTariffPlanActive = (tariff: TariffPlan): boolean => {
    if(!subscription){
      return false;
    }

    if (tariff.code === subscription?.tariff_plan.code) {
      return true;
    }

    return false;
  };

  return (
    <section
      className="bg-white dark:bg-gray-900 w-full flex justify-center items-center"
      style={{ height: 'calc(100vh - 130px)' }}>
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
            Тарифные планы Sellerator
          </h2>
        </div>
        <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
          {tariffs.map((tariffItem, tariffItemIndex) => (
            <TariffPlanItemCard
              tariff={tariffItem}
              key={tariffItemIndex}
              onSelect={() => handleTariffSelect(tariffItem)}
              isActive={isTariffPlanActive(tariffItem)}
            />
          ))}
        </div>
      </div>

      <InfoModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        header="Эффективный селлер"
        body="Спасибо за интерес, тариф в разработке, мы оповестим вас в первую очередь"
      />
    </section>
  );
}

export default SelectTariffView;
