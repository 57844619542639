import React, { ReactElement } from 'react';

interface ISimpleTooltipProps {
  children: React.ReactNode;
  content: string | React.ReactNode;
}

function SimpleTooltip(props: ISimpleTooltipProps) {
  return (
    <div className="group cursor-pointer relative inline-block w-fit text-center">
      {props.children}
      <div className="opacity-0 w-fit bg-black text-white text-center text-xs rounded-lg py-2 absolute z-10 group-hover:opacity-100 bottom-full left-0 px-3 pointer-events-none whitespace-nowrap">
        {props.content}
      </div>
    </div>
  );
}
interface IControlledTooltip {
  tooltipText: string;
  open: boolean;
}

function ControlledTooltip(props: IControlledTooltip) {
  const tooltipClassName = `w-[200px] -translate-x-1/2 -translate-y-1 bg-black text-white text-center text-xs rounded-lg py-2 absolute z-[10000] bottom-full left-1/2 px-3 pointer-events-none ${
    props.open ? 'opacity-100' : 'opacity-0'
  }`;

  return (
    <div className={tooltipClassName}>
      {props.tooltipText}
      <svg
        className="absolute text-black h-2 w-full left-0 top-full"
        x="0px"
        y="0px"
        viewBox="0 0 255 255">
        <polygon className="fill-current" points="0,0 127.5,127.5 255,0" />
      </svg>
    </div>
  );
}

export default SimpleTooltip;
export { ControlledTooltip };
