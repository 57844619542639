import { Button, TextInput } from 'flowbite-react';
import { useEffect, useMemo, useState } from 'react';
import { BsSearch } from 'react-icons/bs';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  CreateDiscountCampaignType,
  useCreateDiscountCampaignMutation,
  useLazyGetAllDiscountCampaignsQuery,
  useRemoveDiscountCampaignMutation,
} from '../../api/discount_campaign_api';
import DiscountCampaignTable from '../../components/discount_campaign/DiscountCampaignTable';
import NewDiscountCampaignModal from '../../components/discount_campaign/NewDiscountCampaignModal';
import { EntityActionContext } from '../../components/EntityAction/EntityActionContext';
import { setDiscountCampaign } from '../../redux/discount_campaign/slice';
import { useTypedDispatch } from '../../redux/hooks';
import { OnboardingTourStep } from '../../redux/onboarding_tour/steps';
//import { moveToOnbordingTourStep } from '../../utils/initOnboardingTour';
import trackEvent, { MetricEventType } from '../../utils/trackEvent';
import { pauseTour, setStepIndex } from '../../redux/onboarding_tour/slice';

const ButtonGroupTheme = {
  theme: {
    color: {
      info: 'bg-primary-600 text-white !focus:ring-0',
    },
  },
};

function AllDiscountCampaignView() {
  const [searchParams, setSearchParams] = useSearchParams();
  const isCreateNew = searchParams.get('create') ? true : false;

  const [modalOpen, setModalOpen] = useState(isCreateNew);
  const [confirmOpen, setConfirmOpen] = useState<number | null>(null);

  const [
    createDiscountCampaign,
    { isLoading: isCreateDiscountCampaignLoading },
  ] = useCreateDiscountCampaignMutation();
  const [fetchDiscountCampaigns, { data: dicountCampaignList }] =
    useLazyGetAllDiscountCampaignsQuery();
  const [search, setSearch] = useState('');
  const [view, setView] = useState<'all' | 'active' | 'archive'>('all');
  const [removeDiscountCampaign, { isLoading: isDeleteLoading }] =
    useRemoveDiscountCampaignMutation();

  const onModalOpen = () => {
    setModalOpen(true);
    trackEvent(MetricEventType.ADDPROMO_BUTTON_MAIN);
  };

  const onModalClose = () => {
    setModalOpen(false);
    if (isCreateNew) {
      navigate('/account/discount-campaigns');
    }

    //move back  to step 2
    dispatch(
      setStepIndex(OnboardingTourStep.STEP2_CLICK_NEW_DISCOUNT_CAMPAIGN),
    );

    trackEvent(MetricEventType.ADDPROMO_FORM_CANCEL);
  };
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();

  const onSuccess = async (modalForm: CreateDiscountCampaignType) => {
    try {
      //create formdata
      let bodyFormData = new FormData();
      bodyFormData.append('file', modalForm.file as File, modalForm.file?.name);
      bodyFormData.append('name', modalForm.name);
      bodyFormData.append('start_date', modalForm.start_date);
      bodyFormData.append('end_date', modalForm.end_date);
      bodyFormData.append('status', modalForm.status);
      bodyFormData.append('marketplace', modalForm.marketplace);
      //create new sale
      const res = await createDiscountCampaign(bodyFormData).unwrap();

      //dispatch new sale to redux state
      dispatch(setDiscountCampaign(res));

      //send success metric event
      trackEvent(MetricEventType.ADDPROMO_FORM_SUCCESS);

      dispatch(
        setStepIndex(OnboardingTourStep.STEP5_FULFILL_PRODUCT_LOGISTIC_EXPENSE),
      );
      dispatch(pauseTour());

      //redirect to sale page
      navigate(`/account/discount-campaigns/${res.id}`);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getSales();
  }, []);

  useEffect(() => {
    setModalOpen(searchParams.get('create') ? true : false);
  }, [isCreateNew]);

  async function getSales() {
    try {
      await fetchDiscountCampaigns().unwrap();
    } catch {}
  }

  async function handleDeleteDiscountCampaign(id: number) {
    try {
      await removeDiscountCampaign(id).unwrap();
      trackEvent(MetricEventType.SUPPLY_WORK_DELETE);
    } catch (err) {
      console.log(err);
    }
  }

  const filteredSales = useMemo(() => {
    if (!dicountCampaignList) return [];

    let tmp = dicountCampaignList.filter((dicountCampaign) => {
      return dicountCampaign.name.toLowerCase().includes(search.toLowerCase());
    });

    if (view === 'active') {
      return tmp.filter((sale) => {
        const now = new Date();
        const start = new Date(sale.start_date as string);
        start.setHours(0, 0);
        const end = new Date(sale.end_date as string);
        end.setHours(23, 59);
        return now < end && now >= start;
      });
    }

    if (!search) return [...dicountCampaignList];

    return tmp;
  }, [view, search, dicountCampaignList]);

  const howTo = () => {
    trackEvent(MetricEventType.HELP_PAGE_LINK, { source: 'all_campaigns' });
    window.open(
      'https://gleaming-find-c09.notion.site/Sellerator-a346f8427b9f4fefb47f24f9937bebda',
      '_blank',
    );
  };

  return (
    <div>
      <div className="flex flex-row justify-between">
        <h2 className="font-bold text-3xl">Акции</h2>
        <Button.Group>
          <Button
            {...ButtonGroupTheme}
            color={view === 'all' ? 'info' : 'gray'}
            onClick={() => setView('all')}>
            Все
          </Button>
          <Button
            {...ButtonGroupTheme}
            color={view === 'active' ? 'info' : 'gray'}
            onClick={() => setView('active')}>
            Активные
          </Button>
          <Button
            {...ButtonGroupTheme}
            color={view === 'archive' ? 'info' : 'gray'}
            onClick={() => setView('archive')}>
            Архив
          </Button>
        </Button.Group>
      </div>

      <div className="flex flex-row gap-4 my-7 items-center justify-between">
        <div className={'flex flex-row gap-4'}>
          <TextInput
            icon={BsSearch}
            id="search"
            placeholder="Поиск"
            required
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />

          <Button color="gray">Поиск</Button>
        </div>
        <div className={'flex flex-row gap-4'}>
          <Button
            className="bg-green-700 w-auto ml-auto enabled:hover:bg-green-600"
            onClick={howTo}>
            Как пользоваться?
          </Button>
          <Button
            className="bg-primary-600 w-auto ml-auto enabled:hover:bg-primary-500"
            onClick={onModalOpen}>
            Добавить новую акцию
          </Button>
        </div>
      </div>

      <EntityActionContext.Provider
        value={{
          deleteLoading: isDeleteLoading,
          handleChange: async (id) => {
            navigate(`/account/discount-campaigns/${id}`);
          },
          handleDelete: handleDeleteDiscountCampaign,
          setConfirmOpen: setConfirmOpen,
          showDeleteButton: true,
        }}>
        <DiscountCampaignTable data={filteredSales} paginator={null} />
      </EntityActionContext.Provider>

      <NewDiscountCampaignModal
        isOpen={modalOpen}
        onClose={onModalClose}
        onSuccess={onSuccess}
        isLoading={isCreateDiscountCampaignLoading}
      />
    </div>
  );
}
export default AllDiscountCampaignView;
