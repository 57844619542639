import { CellContext } from '@tanstack/react-table';
import { Checkbox } from 'flowbite-react';
import React, { useState } from 'react';
import { GetDiscountCampaignProductDto } from '../../../../backend/src/discount_campaign/dto/get_discount_campaign_product.dto';
import { CreateDiscountCampaignProduct } from '../../api/discount_campaign_api';
import trackEvent, { MetricEventType } from '../../utils/trackEvent';
import { ControlledTooltip } from '../ui/Tooltip';

interface IProductIsEnabledCheckbox {
  info: CellContext<GetDiscountCampaignProductDto, boolean>;
  handlePatchProduct: (
    data: Partial<CreateDiscountCampaignProduct> & {
      id: number;
    },
  ) => Promise<void>;
}
function ProductIsEnabledCheckbox({
  info,
  handlePatchProduct,
}: IProductIsEnabledCheckbox) {
  const [warningHintOpen, setWarningHintOpen] = useState(false);

  function handleFocus() {
    if (info.row.original.is_enabled_by_default) {
      setWarningHintOpen(true);
    }
  }

  function handleBlur() {
    if (warningHintOpen) {
      setWarningHintOpen(false);
    }
  }

  return (
    <div className='relative'>
      <Checkbox
        onFocus={handleFocus}
        onBlur={handleBlur}
        checked={info.row.original.is_enabled_by_default || info.getValue()}
        onChange={(e) => {
          if (info.row.original.is_enabled_by_default) return;

          handlePatchProduct({
            is_enabled: e.target.checked,
            id: info.row.original.id,
          });
          trackEvent(MetricEventType.ADDPROMO_WORK_FIELDS, {
            choice: true,
          });
        }}
      />

      <ControlledTooltip
        tooltipText="Участие в акции для данного товара, может быть изменено только в интерфейсе маркетплейса"
        open={warningHintOpen}
      />
    </div>
  );
}

export default ProductIsEnabledCheckbox;
