import React, { useState } from 'react';
import {
  useReactTable,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
} from '@tanstack/react-table';
import { ToggleSwitch } from 'flowbite-react';
import { cellColorFormatter } from '../../utils/cellColorFormatter';

interface ITableProps extends React.ComponentPropsWithoutRef<'div'> {
  columns: any[];
  data: any[];
  paginator: React.ReactNode;
  fixedColumns?: Record<string, number>;
  columnVisibility?: Record<string, boolean>;
  tableModeComponent?: React.ReactNode;
  cellsToColorFormat?: Record<string, boolean>;
}

const Table = ({
  columns,
  data,
  paginator,
  fixedColumns = {},
  columnVisibility = {},
  tableModeComponent,
  cellsToColorFormat = {},
  ...props
}: ITableProps) => {
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnVisibility,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const isEmpty = table.getRowModel().rows.length === 0;

  return (
    <div>
      {tableModeComponent}
      <div
        className={`w-full overflow-x-auto overflow-y-hidden flex-1 ${
          isEmpty ? 'overflow-hidden' : ''
        }`}>
        <table
          className={`min-w-full divide-y divide-gray-200 ${props.className}`}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const fixed = fixedColumns[header.id] >= 0;
                  const style: React.CSSProperties = fixed
                    ? {
                        position: 'sticky',
                        left: fixedColumns[header.id],
                        zIndex: 1000,
                      }
                    : {};
                  return (
                    <th
                      key={header.id}
                      style={{ ...style, width: header.getSize() }}
                      className="px-3 py-3 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                      {header.isPlaceholder ? null : (
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                          {{
                            asc: ' 🔼',
                            desc: ' 🔽',
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {isEmpty && (
              <tr>
                <th
                  colSpan={1}
                  className="sticky whitespace-nowrap left-1/2 py-4"
                  style={{ transform: 'translateX(-50%)' }}>
                  Ничего не найдено
                </th>
              </tr>
            )}
            {table.getRowModel().rows.map((row, rowIdx) => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell, idx) => {
                    let hasMeta = cell.getContext().cell.column.columnDef.meta;
                    const props =
                      (hasMeta as any)?.getCellContext(cell.getContext()) || {};

                    const fixed = fixedColumns[cell.column.id] >= 0;
                    const style: React.CSSProperties = fixed
                      ? {
                          position: 'sticky',
                          left: fixedColumns[cell.column.id],
                          zIndex: 1000,
                        }
                      : {};

                    let bgColor = '';

                    if (rowIdx % 2 && !props.className) {
                      bgColor = 'bg-gray-50';
                    } else if (!props.className) {
                      bgColor = 'bg-white';
                    }

                    return (
                      <td
                        key={cell.id}
                        style={{ ...style, width: cell.column.getSize() }}
                        className={`text-center px-3 ${bgColor} ${
                          props.className || ''
                        }`}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {paginator}
    </div>
  );
};

export default Table;
