import { FormikErrors, FormikTouched } from 'formik';
import FormInput from '../../ui/FormInput';
import { CreateDiscountCampaignProduct } from '../../../api/discount_campaign_api';
import FormCheckboxInput from '../../ui/FormCheckboxInput';
import { TProcurementForm } from './ProcurementModal';
import SmartSelectInput from '../../SmartSelectInput/SmartSelectInput';
import { useEffect, useMemo, useState } from 'react';
import BuyerModal, { TBuyerForm } from '../BuyerModal/BuyerModal';
import LogisticianModal, {
  TLogisticianForm,
} from '../LogisticianModal/LogisticianModal';
import {
  useCreateBuyerMutation,
  useLazyFetchAllBuyersQuery,
} from '../../../api/buyer_api';
import {
  useCreateLogisticianMutation,
  useLazyFetchAllLogisticiansQuery,
} from '../../../api/logistician_api';
import { createPortal } from 'react-dom';
import FormErrorMessage from '../../ui/FormErrorMessage';

interface IProcurementFormProps {
  values: TProcurementForm;
  errors: FormikErrors<TProcurementForm>;
  handleChange: (e: React.ChangeEvent<any>) => void;
  isEdit: boolean;
  touched: FormikTouched<TProcurementForm>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<TProcurementForm>>;
  setIsParentModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ProcurementModalForm({
  values,
  errors,
  handleChange,
  setFieldValue,
  touched,
  setIsParentModalVisible,
}: IProcurementFormProps) {
  const [buyerModalOpen, setBuyerModalOpen] = useState(false);
  const [logisticianModalOpen, setLogisticianModalOpen] = useState(false);
  const [fetchAllBuyers, { data: buyers, isLoading: isFetchAllBuyersLoading }] =
    useLazyFetchAllBuyersQuery();
  const [
    fetchAllLogisticians,
    { data: logisticians, isLoading: isFetchAllLogisticiansLoading },
  ] = useLazyFetchAllLogisticiansQuery();

  const [createBuyer, { isLoading: isCreateBuyerLoading }] =
    useCreateBuyerMutation();
  const [createLogistician, { isLoading: isCreateLogisticianLoading }] =
    useCreateLogisticianMutation();

  useEffect(() => {
    fetchAllBuyers();
    fetchAllLogisticians();
  }, []);

  async function onBuyerCreate(values: TBuyerForm, isEdit: boolean) {
    try {
      const new_buyer = await createBuyer(values).unwrap();
      setFieldValue('buyer_id', new_buyer.id);
      setBuyerModalOpen(false);
      setIsParentModalVisible(true);
    } catch (err) {
      console.log(err);
    }
  }

  async function onLogisticianCreate(
    values: TLogisticianForm,
    isEdit: boolean,
  ) {
    try {
      const new_logistician = await createLogistician(values).unwrap();
      setFieldValue('logistician_id', new_logistician.id);
      setLogisticianModalOpen(false);
      setIsParentModalVisible(true);
    } catch (err) {
      console.log(err);
    }
  }

  const buyerOptions = useMemo(() => {
    return buyers?.map((buyer) => ({ label: buyer.name, value: buyer.id }));
  }, [buyers]);

  const logisticianOptions = useMemo(() => {
    return logisticians?.map((logistician) => ({
      label: logistician.name,
      value: logistician.id,
    }));
  }, [logisticians]);

  return (
    <form>
      <div className="grid grid-cols-2 gap-x-8 gap-y-3">
        {/* Product's name */}
        <FormInput
          name="name"
          title="Название закупки"
          id="name"
          placeholder="Закупка"
          required
          type="text"
          onChange={handleChange}
          value={values.name as string}
          error={errors.name}
          isTouched={!!touched.name}
        />

        {/* empty div */}
        <div></div>

        <div>
          <SmartSelectInput
            options={buyerOptions || []}
            selectedValue={values.buyer_id as number}
            onChange={(option) => setFieldValue('buyer_id', option.value)}
            onModalOpen={() => {
              setBuyerModalOpen(true);
              setIsParentModalVisible(false);
            }}
            placeholder="Выберите байера"
            label='Байер'
            isLoading={isFetchAllBuyersLoading}
          />
          <FormErrorMessage
            error={errors.buyer_id}
            touched={touched.buyer_id}
          />
        </div>

        <div>
          <SmartSelectInput
            options={logisticianOptions || []}
            selectedValue={values.logistician_id as number}
            onChange={(option) => setFieldValue('logistician_id', option.value)}
            onModalOpen={() => {
              setLogisticianModalOpen(true);
              setIsParentModalVisible(false);
            }}
            placeholder="Выберите логиста"
            label="Логист"
            isLoading={isFetchAllLogisticiansLoading}
          />

          <FormErrorMessage
            error={errors.logistician_id}
            touched={touched.logistician_id}
          />
        </div>
      </div>

      {createPortal(
        <BuyerModal
          isOpen={buyerModalOpen}
          isLoading={false}
          onClose={() => {
            setBuyerModalOpen(false);
            setIsParentModalVisible(true);
          }}
          onSubmit={onBuyerCreate}
        />,
        document.body,
      )}

      {createPortal(
        <LogisticianModal
          isOpen={logisticianModalOpen}
          isLoading={false}
          onClose={() => {
            setLogisticianModalOpen(false);
            setIsParentModalVisible(true);
          }}
          onSubmit={onLogisticianCreate}
        />,
        document.body,
      )}
    </form>
  );
}
