import { Avatar, Button, Dropdown, Navbar } from 'flowbite-react';
import { IoMdNotifications } from 'react-icons/io';
import { useTypedDispatch, useTypedSelector } from '../redux/hooks';
import { ISubscription } from '../api/subscription';
import { useNavigate } from 'react-router-dom';
import TariffActionButton from '../components/tariff/TariffActionButton';
import SubscriptionTimeoutCounter from '../components/tariff/SubscriptionTimeoutCounter';
import { useLogoutMutation } from '../api/api_index';
import { logout } from '../redux/auth/slice';

interface ITopNavbarProps {
  subscription?: ISubscription;
}

export default function TopNavbar({ subscription }: ITopNavbarProps) {
  const [triggerLogout] = useLogoutMutation();
  const { user } = useTypedSelector((state) => state.auth);
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();

  async function handleLogout() {
    try {
      await triggerLogout().unwrap();
      sessionStorage.removeItem('access_token');
      navigate('/', { replace: true });
    } catch (e) {
      console.log(e);
      navigate('/signin', { replace: true });
    }
  }

  return (
    <Navbar
      fluid
      rounded
      theme={{ base: 'bg-white shadow-sm px-6 sticky top-0 z-10' }}>
      <Navbar.Brand href={`${window.location.origin}/account`} className="h-16">
        <img
          alt="Sellerator Logo"
          className="mr-3 h-16 sm:h-16"
          src="/logo.jpg"
        />
      </Navbar.Brand>
      <div className="flex md:order-2 items-center h-16 gap-8">
        <SubscriptionTimeoutCounter subscription={subscription} />
        <TariffActionButton isSubscriptionActive={!!subscription} />
        <IoMdNotifications size={24} />
        <Dropdown
          inline
          label={
            <div className="flex items-center gap-4 z-10">
              <span>{user?.name}</span>
              <Avatar
                alt="User settings"
                img="https://flowbite.com/docs/images/people/profile-picture-5.jpg"
                rounded
              />
            </div>
          }>
          <Dropdown.Header>
            <span className="block text-sm">{user?.name}</span>
            <span className="block truncate text-sm font-medium">
              {user?.username}
            </span>
          </Dropdown.Header>
          <Dropdown.Item onClick={handleLogout}>Выход</Dropdown.Item>
        </Dropdown>
        <Navbar.Toggle />
      </div>
    </Navbar>
  );
}
