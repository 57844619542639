import * as Yup from 'yup';

export const ProductUnitEconomicResearchSchema = Yup.object().shape({
  product: Yup.object({
    name: Yup.string().required("Введите наименование товара"),
    weight: Yup.number().required("Введите вес товара"),
    product_url: Yup.string().url('Неправильный формат ссылки').required("Введите ссылку на товар поставщика").notRequired().default(""),
    logistics_rate: Yup.number().required("Введите тариф доставки"),
    currency: Yup.string()
      .matches(/(usd|cny|rub)/, {
        excludeEmptyString: true,
        message: 'Валюта должна быть usd, rub или cny',
      })
      .required('Заполните валюту продавца'),
    product_price: Yup.number().required("Введите стоимость продукта в валюте поставщика"),
  }),
  competitor_url: Yup.string()
    .url('Неправильный формат ссылки')
    .notRequired().default(""),
  analytics_url: Yup.string()
    .url('Неправильный формат ссылки')
    .notRequired().default(""),
  marketplace_fee: Yup.number().required("Введите комиссию маркетплейса"),
  marketplace_logistics_expense: Yup.number().required("Введите стоимость логистики маркетплейса"),
  usd_rub: Yup.number().required(
    'Введите курс доллара',
  ),
  cny_rub: Yup.number().required('Введите курс юаня'),
  marketplace_product_price: Yup.number()
    .moreThan(0, "Цена товара на маркетплейсе должна быть больше нуля")
    .required('Введите цену товара на маркетплейсе'),
  product_count: Yup.number()
    .min(0, 'Количество товаров для заказа должно быть больше нуля')
    .required('Введите количество товаров для заказа'),
});
