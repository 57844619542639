import { Button } from 'flowbite-react';
import { useState } from 'react';
import { MdNavigateNext, MdNavigateBefore } from 'react-icons/md';

interface ITablePaginatorProps {
  maxPage?: number;
  paginationState: { take: number; page: number };
  handleChangePage: (dir: number) => Promise<void>
}

export default function TablePaginator({
  handleChangePage,
  maxPage,
  paginationState
}: ITablePaginatorProps) {

  return (
    <div className="flex w-full justify-end mt-4 items-center gap-4">
      <span>
        Страница {maxPage === 0 ? 0 : paginationState.page + 1} из {maxPage}
      </span>
      <Button.Group>
        <Button
          color="gray"
          onClick={() => handleChangePage(-1)}
          className="focus:ring-0"
          disabled={paginationState.page === 0}>
          <MdNavigateBefore size={20} />
        </Button>
        <Button
          color="gray"
          onClick={() => handleChangePage(1)}
          className="focus:ring-0"
          disabled={paginationState.page >= (maxPage as number) - 1}>
          <MdNavigateNext size={20} />
        </Button>
      </Button.Group>
    </div>
  );
}
