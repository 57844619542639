import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { onboardingTourSteps } from './steps';
import { Step } from 'react-joyride';

export interface ICollapseOpen {
  research: boolean;
  task: boolean;
  file: boolean;
  add: boolean;
  procurement: boolean;
}

export interface IOnboardingTourSliceState {
  run: boolean;
  sidebarOpen: boolean;
  collapseOpen: ICollapseOpen;
  stepIndex: number;
  active: boolean;
  //steps: Step[];
}
const initialState: IOnboardingTourSliceState = {
  run: false,
  sidebarOpen: true,
  collapseOpen: {
    research: false,
    task: false,
    file: false,
    add: false,
    procurement: false,
  },
  stepIndex: 0,
  active: true,
  //steps: onboardingTourSteps,
};

const onboardingTourSlice = createSlice({
  name: 'onboarding_tour',
  initialState: initialState,
  reducers: {
    setSidebarOpen(state, action: PayloadAction<boolean>) {
      state.sidebarOpen = action.payload;
    },
    setCollapseOpen(
      state,
      action: PayloadAction<{ type: keyof ICollapseOpen; open: boolean }>,
    ) {
      state.collapseOpen[action.payload.type] = action.payload.open;
    },
    resetCollapse(state) {
      state.collapseOpen = initialState.collapseOpen;
    },
    pauseTour(state) {
      state.run = false;
    },
    stopTour(state) {
      state.run = false;
      state.active = false;

      const localStorageData = {
        active: false,
        stepIndex: 0,
      };

      localStorage.setItem(
        'onboarding_tour_data',
        JSON.stringify(localStorageData),
      );
    },
    startTour(state) {
      if (state.active) {
        state.run = true;
      }
    },
    prevStepIndex(state) {
      if (state.stepIndex > 0) {
        state.stepIndex--;
      }
    },
    nextStepIndex(state) {
      const nextStepIndex = state.stepIndex + 1;

      if (state.stepIndex < onboardingTourSteps.length - 1) {
        state.stepIndex = nextStepIndex;
      }
    },
    setStepIndex(state, action: PayloadAction<number>) {
      if (!state.active) return;
      state.stepIndex = action.payload;

      const localStorageData = {
        active: true,
        stepIndex: action.payload,
      };

      localStorage.setItem(
        'onboarding_tour_data',
        JSON.stringify(localStorageData),
      );
    },
  },
});

export const {
  //setSteps,
  setSidebarOpen,
  setCollapseOpen,
  stopTour,
  startTour,
  nextStepIndex,
  prevStepIndex,
  setStepIndex,
  resetCollapse,
  pauseTour,
} = onboardingTourSlice.actions;

export default onboardingTourSlice.reducer;
export { initialState as initOnboardingTourState };
