import { useNavigate } from 'react-router-dom';
import Button from '../ui/Button';

interface ITariffActionButtonProps {
  isSubscriptionActive: boolean;
}

export default function TariffActionButton({
  isSubscriptionActive,
}: ITariffActionButtonProps) {
  const navigate = useNavigate();

  const buttonText = isSubscriptionActive
    ? 'Повысить тариф'
    : 'Перейти на премиум';

  return (
    <Button
      onClick={() => navigate('/account/tariffs')}
      className="bg-primary-600 enabled:hover:bg-primary-500">
      {buttonText}
    </Button>
  );
}
