import { Button, TextInput } from 'flowbite-react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { BsSearch } from 'react-icons/bs';
import { IoClose } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  IProcurementProduct,
  useCreateProcurementMutation,
  useCreateProcurementProductMutation,
  useDeleteProcurementProductMutation,
  useFetchProcurementQuery,
  useLazyFetchProcurementProductsQuery,
  useUpdateProcurementMutation,
  useUpdateProcurementProductMutation,
} from '../../api/procurement_api';
import { IProductUnitEconomicResearch } from '../../api/unit_economy_api';
import ProcurementModal, {
  TProcurementForm,
} from '../../components/procurement/ProcurementModal/ProcurementModal';
import ProcurementProductModal, {
  ICreateProcurementProduct,
} from '../../components/procurement/ProcurementProductModal/ProcurementProductModal';
import ProcurementProductTable from '../../components/procurement/ProcurementProductTable';
import { EntityActionContext } from '../../components/EntityAction/EntityActionContext';
import ConfirmDialog from '../../components/ui/ConfirmDialog';
import TablePaginator from '../../components/ui/TablePaginator';
import useDebounce from '../../hooks/useDebounce';
import usePaginate from '../../hooks/usePaginate';
import trackEvent, { MetricEventType } from '../../utils/trackEvent';

function ProcurementEditView() {
  const { procurementId } = useParams();
  const [search, setSearch] = useState('');

  const [selectedProcurementProductData, setSelectedProcurementProductData] =
    useState<IProcurementProduct | null>(null);

  const [procurementModalOpen, setProcurementModalOpen] = useState(false);
  const [procurementProductModalOpen, setProcurementProductModalOpen] =
    useState(false);
  const [confirmOpen, setConfirmOpen] = useState<number | null>(null);

  const { data: procurementData } = useFetchProcurementQuery(
    +(procurementId as string),
  );
  // const [fetchProcurement, { data: procurementData }] =
  //   useLazyFetchProcurementQuery();

  const [fetchProcurementProducts, { data: procurementProductsData }] =
    useLazyFetchProcurementProductsQuery();

  const [createProcurement, { isLoading: isCreateLoading }] =
    useCreateProcurementMutation();

  const [
    createProcurementProduct,
    { isLoading: isCreateProcurementProductLoading },
  ] = useCreateProcurementProductMutation();

  const [
    updateProcurementProduct,
    { isLoading: isUpdateProcurementProductLoading },
  ] = useUpdateProcurementProductMutation();

  const [updateProcurement, { isLoading: isUpdateLoading }] =
    useUpdateProcurementMutation();

  const [deleteProcurementProduct, { isLoading: isDeleteLoading }] =
    useDeleteProcurementProductMutation();

  const [productUnitEconomicResearch, setProductUnitEconomicResearch] =
    useState<IProductUnitEconomicResearch | null>(null);

  const debouncedSearch = useDebounce(search, 500);
  const { paginationState, handleChangePage, resetState } = usePaginate({
    handlePaginate,
  });

  useEffect(() => {
    trackEvent(MetricEventType.SUPPLY_SCREEN_START);
  }, []);

  useEffect(() => {
    fetchProcurementProducts({
      id: procurementId,
      take: 10,
      skip: 0,
      search: debouncedSearch,
    });

    resetState();
  }, [debouncedSearch, procurementId]);

  async function handleDeleteProductUnitResearch(id: number) {
    try {
      await deleteProcurementProduct(id).unwrap();
      setConfirmOpen(null);
    } catch (err) {
      console.log(err);
    }
  }

  async function handleUpdateProductUnitResearch(id: number) {
    setProcurementProductModalOpen(true);
    const data = procurementProductsData?.products.find((el) => el.id === id);
    if (data) {
      setSelectedProcurementProductData(data);
    }
  }

  async function handleUpdateProcurement(
    values: TProcurementForm,
    isEdit: boolean,
  ) {
    try {
      //update entity
      if (isEdit) {
        await updateProcurement({
          data: values,
          id: +(procurementId as string),
        }).unwrap();
      } else {
        //create entity
        await createProcurement(values).unwrap();
      }
      toast.success(
        isEdit ? 'Закупка успешно сохранена' : 'Закупка успешно создана',
        { position: 'bottom-right' },
      );
      setProcurementModalOpen(false);
    } catch (err) {
      console.log(err);
    }
  }

  async function handleSubmitProcurementProductForm(
    values: ICreateProcurementProduct,
    isEdit: boolean,
  ) {
    try {
      //update entity
      if (isEdit) {
        await updateProcurementProduct({
          data: values,
          id: selectedProcurementProductData?.id as number,
        }).unwrap();
      } else {
        //create entity
        await createProcurementProduct({
          data: { ...values, procurement_id: +(procurementId as string) },
          procurement_id: +(procurementId as string),
        }).unwrap();
      }
      toast.success(
        isEdit ? 'Товар успешно сохранён' : 'Товар успешно создан',
        { position: 'bottom-right' },
      );
      setProcurementProductModalOpen(false);
    } catch (err) {
      console.log(err);
    }
  }

  function handleCreate() {
    setProcurementProductModalOpen(true);
  }

  async function handlePaginate(take: number, page: number) {
    try {
      const params = { take, page };
      if (search) {
        (params as any).search = search;
      }
      const resp = await fetchProcurementProducts({
        id: procurementId,
        ...params,
      });
      return resp.data?.max_page;
    } catch (err) {
      console.log(err);
    }
  }

  const ActionButtons = () => (
    <Button
      className="bg-primary-600 enabled:hover:bg-primary-500 w-auto "
      onClick={handleCreate}>
      Добавить новый товар
    </Button>
  );

  return (
    <div>
      <ProcurementModal
        isOpen={procurementModalOpen}
        onClose={() => setProcurementModalOpen(false)}
        onSubmit={handleUpdateProcurement}
        procurement={procurementData}
        isLoading={isCreateLoading || isUpdateLoading}
      />

      <ProcurementProductModal
        isOpen={procurementProductModalOpen}
        onClose={() => {
          setProcurementProductModalOpen(false);
          setSelectedProcurementProductData(null);
        }}
        isLoading={
          isCreateProcurementProductLoading || isUpdateProcurementProductLoading
        }
        onSubmit={handleSubmitProcurementProductForm}
        procurementProduct={selectedProcurementProductData}
        isProcurementIdKnown
      />

      <ConfirmDialog
        open={!!confirmOpen}
        onClose={() => setConfirmOpen(null)}
        title="Удалить расчёт"
        info="Вы уверены, что хотите удалить расчёт?"
        actionButton={
          <Button
            className="bg-red-500 text-white hover:bg-red-400 disabled:hover:bg-red-500"
            isProcessing={isDeleteLoading}
            disabled={isDeleteLoading}
            onClick={() =>
              handleDeleteProductUnitResearch(confirmOpen as number)
            }>
            Удалить
          </Button>
        }
      />

      <div className="flex flex-row justify-between">
        <div className="flex items-center">
          <h2 className="font-bold text-3xl mr-5">{procurementData?.name}</h2>
          <span className="text-lg font-light">
            {moment(procurementData?.created_at).format('DD.MM.YYYY')}
          </span>
        </div>
        <Button
          className="bg-primary-600 enabled:hover:bg-primary-500 w-auto "
          isProcessing={isDeleteLoading}
          disabled={isDeleteLoading}
          onClick={() => setProcurementModalOpen(true)}>
          Редактировать закупку
        </Button>
      </div>

      <div className="flex flex-row gap-4 my-7">
        <TextInput
          icon={BsSearch}
          id="search"
          placeholder="Поиск"
          required
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Button
          onClick={() => setSearch('')}
          style={{ height: 42, width: 42 }}
          className="!focus:ring-0 bg-gray-50 enabled:hover:bg-gray-300 !ring-0 !ring-transparent !outline-none !border-gray-300">
          <IoClose size={20} color="black" />
        </Button>
      </div>

      <EntityActionContext.Provider
        value={{
          deleteLoading: isDeleteLoading,
          handleChange: handleUpdateProductUnitResearch,
          handleDelete: handleDeleteProductUnitResearch,
          setConfirmOpen,
          showDeleteButton: true,
        }}>
        <ProcurementProductTable
          data={procurementProductsData}
          addonComponent={<ActionButtons />}
          paginator={
            <TablePaginator
              handleChangePage={handleChangePage}
              paginationState={paginationState}
              maxPage={procurementProductsData?.max_page}
            />
          }
        />
      </EntityActionContext.Provider>
    </div>
  );
}
export default ProcurementEditView;
