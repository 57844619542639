import * as Yup from 'yup';

export const DiscountCampagnProductFormSchema = Yup.object().shape({
  brand: Yup.string().optional(),
  title: Yup.string().required('Введите название товара'),
  collection: Yup.string().optional(),
  supplier_num: Yup.string().required('Введите артикул поставщика'),
  nomenclature: Yup.number().required('Введите номенклатуру товара'),
  barcode: Yup.string().required('Введите штрихкод товара'),
  site_lifetime: Yup.number().required('Введите время жизни товара на сайте'),
  turnover_rate: Yup.number().required('Введите оборачиваемость товара'),
  marketplace_in_stock_amount: Yup.number().min(0, "Остаток товара не может быть меньше нуля").required(
    'Введите остаток товара на складе маркетплейса',
  ),
  //seller_in_stock_amount: Yup.number().required('Введите вес товара'),
  planned_discount_price: Yup.number().moreThan(0, "Планируемая цена должна быть больше нуля").required('Введите планируемую цену товара в акции'),
  current_retail_price: Yup.number().moreThan(0, "Текущая розничная должна быть больше нуля").required('Введите текущую розничную цену товара'),
  current_discount: Yup.number().min(0, "Текушая скидка не может быть меньше нуля").required('Введите текущую скидку товара'),
  //final_discount: Yup.number().required('Введите вес товара'),
  price: Yup.number().moreThan(0, "Себестоимость должна быть больше нуля").required('Введите себестоимость товара'),
  commision_rate: Yup.number().integer().min(0, "Комиссия маркетплейса не может быть меньше нуля").required('Введите комиссию маркетплейса'),
  logistic_expense: Yup.number().min(0, "Затраты на логистику не могут быть меньше нуля").required('Введите затраты на логистику товара'),
  commision_reduction: Yup.number().integer().min(0, "Снижение комиссии дне могут быть меньше нуля").required('Введите уменьшение комиссии маркетплейса'),
  //is_enabled: Yup.boolean().required('Введите  товара'),
});
