import axios from 'axios';

import { DiscountCampaignProductForm } from '../components/discount_campaign/DiscountCampagnProductModal';
import { layout } from '../constants/excel_layout';
import {
  DiscountCampaignProductDto,
  DiscountCampaignSliceState,
} from '../redux/discount_campaign/slice';
import { appApi } from './api_index';
axios.defaults.baseURL = `${process.env.REACT_APP_BACKEND_URL}/api`;

//intercept 401 unauthorized error
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        console.log('axios 401 error');
        const { data } = await refreshToken();
        if (!data.access_token) {
          return;
        }
        error.config.headers['Authorization'] = `Bearer ${data.access_token}`;
        sessionStorage.setItem('access_token', data.access_token || null);
        return axios(error.config);
      }
    }

    return Promise.reject(error);
  },
);

async function refreshToken() {
  return await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/api/auth/refresh`,
  );
}

type CreateDiscountCampaignType = {
  name: string;
  start_date: string;
  end_date: string;
  status: string;
  file: File | null;
  marketplace: 'wb' | 'ozon';
};

export type FetchDiscountCampaignType = {
  name: string;
  start_date: string;
  end_date: string;
  status: string;
  file: string | null;
};

export type CreateDiscountCampaignProduct = {
  brand?: string;
  title?: string;
  collection?: string | null;
  supplier_num: string;
  nomenclature: number;
  barcode: string;
  site_lifetime: number;
  turnover_rate: number;
  marketplace_in_stock_amount: number;
  seller_in_stock_amount: number;
  planned_discount_price: number;
  current_retail_price: number;
  current_discount: number;
  final_discount: number;
  price: number;
  commision_rate: number;
  logistic_expense: number;
  commision_reduction: number;
  //total_commission_rate: number;
  //margin_profit: number;
  //margin_rate: number;
  //discount_margin_rate: number;
  //current_price: number;
  is_enabled?: boolean;
};

export interface FetchDiscountCampaignProduct
  extends Omit<
    CreateDiscountCampaignProduct,
    'brand' | 'title' | 'nomenclature' | 'collection'
  > {
  id: number;
  product: {
    brand: string;
    collection: string;
    title: string;
    nomenclature: number;
  };
}

export async function exportDiscountCampaign(
  id: string,
  marketplace?: 'ozon' | 'wb',
) {
  return axios.post(
    `discount-campaign/${id}/export`,
    { layout, marketplace },
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('access_token')}`,
      },
      responseType: 'blob',
    },
  );
}

const discountCampaignApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    createDiscountCampaign: builder.mutation<
      DiscountCampaignSliceState,
      FormData
    >({
      query: (body) => {
        return {
          url: `discount-campaign/import`,
          method: 'POST',
          formData: true,
          body: body,
        };
      },
      invalidatesTags: ['DiscountCampaigns'],
    }),
    updateDiscountCampaignProduct: builder.mutation<
      DiscountCampaignProductDto,
      {
        discountCampaignId: number;
        product: Partial<DiscountCampaignProductForm>;
      }
    >({
      query: (body) => {
        return {
          url: `discount-campaign/${body.discountCampaignId}/product/${body.product.id}`,
          method: 'PATCH',
          formData: true,
          body: body.product,
        };
      },
      invalidatesTags: ['DiscountCampaign'],
    }),
    createDiscountCampaignProduct: builder.mutation<
      DiscountCampaignProductDto[],
      { id: number; product: CreateDiscountCampaignProduct }
    >({
      query: (body) => {
        return {
          url: `discount-campaign/${body.id}/add-product`,
          method: 'POST',
          body: body.product,
        };
      },
      invalidatesTags: ['DiscountCampaign'],
    }),
    getDiscountCampaign: builder.query<
      { data: DiscountCampaignSliceState; count: number; max_page: number },
      {
        id: number;
        take?: number;
        page?: number;
        search?: string;
        skip?: number;
      }
    >({
      query: ({ id, ...params }) => ({
        url: `discount-campaign/${id}`,
        credentials: 'include',
        params: params,
      }),
      providesTags: ['DiscountCampaign'],
    }),
    getAllDiscountCampaigns: builder.query<DiscountCampaignSliceState[], void>({
      query: () => ({
        url: `discount-campaign`,
        credentials: 'include',
      }),
      providesTags: ['DiscountCampaigns'],
    }),
    removeDiscountCampaign: builder.mutation<void, number>({
      query: (id) => {
        return {
          url: `discount-campaign/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['DiscountCampaigns'],
    }),
    removeDiscountCampaignProducts: builder.mutation<
      void,
      { id: number; productsIds: number[] }
    >({
      query: ({ id, productsIds }) => {
        return {
          url: `discount-campaign/${id}/remove-products`,
          method: 'DELETE',
          body: productsIds,
        };
      },
      invalidatesTags: ['DiscountCampaign'],
    }),
  }),
  overrideExisting: false,
});

export type { CreateDiscountCampaignType };
export const {
  useCreateDiscountCampaignMutation,
  useLazyGetAllDiscountCampaignsQuery,
  useLazyGetDiscountCampaignQuery,
  useUpdateDiscountCampaignProductMutation,
  useCreateDiscountCampaignProductMutation,
  useRemoveDiscountCampaignMutation,
  useRemoveDiscountCampaignProductsMutation,
} = discountCampaignApi;
