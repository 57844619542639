import { Card } from 'flowbite-react';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

function DiscountCampaignSubscriptionAlert() {
  const navigate = useNavigate()
  return (
    <Card className="w-full max-w-4xl mb-7 mx-auto" onClick={()=>navigate("/account/tariffs")}>
      <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white text-center w-full">
        <p>Перейдите на премиум</p>
      </h5>
      <div>
        <p className="font-normal text-gray-700 dark:text-gray-400 w-full text-center">
          Чтобы использовать все возможности калькулятора акций, перейдите на
          премиум подписку.
        </p>
        <p className="font-normal text-gray-700 dark:text-gray-400 w-full text-center">
          На базовом тарифе, максимальное количество товаров в акциях ограничено
          до 5.
        </p>
      </div>

      <Link
        to="/account/tariffs"
        className="text-white bg-amber-500 hover:bg-amber-600 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-primary-900 w-max mx-auto">
        Перейти на премиум
      </Link>
    </Card>
  );
}

export default DiscountCampaignSubscriptionAlert;
