import { Button, Modal } from 'flowbite-react';
import React, { useEffect, useRef } from 'react';
import { CreateDiscountCampaignType } from '../../api/discount_campaign_api';
import { useFormik } from 'formik';
import { ProductUnitEconomicResearchSchema } from './validationSchema';
import {
  IFtechSupplierProducts,
  useLazyFetchSupplierProductsQuery,
} from '../../api/supplier_product_api';
import { IProductUnitEconomicResearch } from '../../api/unit_economy_api';
import NewProductUnitEconomicResearchForm from './ProductUnitResearchForm';
import trackEvent, { MetricEventType } from '../../utils/trackEvent';

export interface ICreateNewProductUnitEconomicResearch {
  competitor_url: string;
  analytics_url: string;
  marketplace_fee: number;
  marketplace_logistics_expense: number;
  usd_rub: number;
  cny_rub: number;
  marketplace_product_price: number;
  product_count: number;
  product: {
    name: string;
    weight: number;
    product_url: string;
    logistics_rate: number;
    currency: string;
    product_price: number;
  };
}

const formInitValues: ICreateNewProductUnitEconomicResearch = {
  competitor_url: '',
  analytics_url: '',
  marketplace_fee: 0,
  marketplace_logistics_expense: 0,
  usd_rub: 0,
  cny_rub: 0,
  marketplace_product_price: 0,
  product_count: 0,
  product: {
    name: '',
    weight: 0,
    product_url: '',
    logistics_rate: 0,
    currency: '',
    product_price: 0,
  },
};

interface INewDiscountCampaignModalProps {
  onClose: () => void;
  onSuccess?: (form: CreateDiscountCampaignType) => void;
  isOpen: boolean;
  onSubmit: (
    values: ICreateNewProductUnitEconomicResearch,
    isEdit: boolean,
  ) => void;
  unitResearch: IProductUnitEconomicResearch | null;
  isLoading: boolean;
}

function NewProductUnitModal({
  onClose,
  isOpen,
  onSuccess,
  onSubmit,
  unitResearch,
  isLoading,
}: INewDiscountCampaignModalProps) {
  //const [fetchSupplierProducts, { data }] = useLazyFetchSupplierProductsQuery();

  const isEdit = !!unitResearch;
  const modalTitle = isEdit
    ? 'Редактирование расчёта товара'
    : 'Новый расчёт товара';

  const buttonTitle = isEdit ? 'Сохранить расчёт' : 'Добавить расчёт';
  const rootRef = useRef<HTMLDivElement>(null);
  const isStartForm = useRef<boolean>(false);

  const formik = useFormik<ICreateNewProductUnitEconomicResearch>({
    initialValues: formInitValues,
    onSubmit: (values) => onSubmit(values, isEdit),
    validationSchema: ProductUnitEconomicResearchSchema,
    isInitialValid: false,
  });

  //form error event
  useEffect(() => {
    let isError = false,
      isStart = false;
    for (const [key, value] of Object.entries(formik.errors)) {
      if (
        value &&
        formik.touched[key as keyof ICreateNewProductUnitEconomicResearch]
      ) {
        isError = true;
        break;
      }
    }

    for (const [key, isTouched] of Object.entries(formik.touched)) {
      if (
        isTouched &&
        !formik.errors[key as keyof ICreateNewProductUnitEconomicResearch] &&
        !isStartForm.current
      ) {
        (isStartForm as any).current = true;
        isStart = true;
        break;
      }
    }

    if (isError) {
      trackEvent(MetricEventType.SUPPLY_ADD_FORM, { error: true });
    }

    // if (isStart) {
    //   console.log("isStart")
    //   trackEvent(MetricEventType.SUPPLY_ADD_FORM, { start: true });
    // }
  }, [formik.errors, formik.touched]);

  //select currency event
  useEffect(() => {
    if (formik.values.product.currency) {
      trackEvent(MetricEventType.SUPPLY_ADD_FORM, { currency: true });
    }
  }, [formik.values.product.currency]);

  useEffect(() => {
    if (!unitResearch) {
      formik.resetForm();
      return;
    }

    const {
      competitor_url,
      analytics_url,
      cny_rub,
      usd_rub,
      marketplace_fee,
      marketplace_product_price,
      marketplace_logistics_expense,
      product_count,
      supplier_product,
    } = unitResearch;

    formik.setValues({
      competitor_url,
      analytics_url,
      cny_rub,
      usd_rub,
      marketplace_fee,
      marketplace_product_price,
      marketplace_logistics_expense,
      product_count,
      product: supplier_product,
    });
  }, [unitResearch, isOpen]);

  async function handleClose() {
    await formik.resetForm();
    onClose();
  }

  return (
    <div ref={rootRef}>
      <Modal
        theme={{
          root: { base: 'z-[10000]' },
        }}
        show={isOpen}
        onClose={handleClose}
        root={rootRef.current ?? undefined}>
        <Modal.Header>{modalTitle}</Modal.Header>
        <Modal.Body>
          <NewProductUnitEconomicResearchForm
            values={formik.values}
            errors={formik.errors}
            handleChange={formik.handleChange}
            isEdit={isEdit}
            touched={formik.touched}
          />
        </Modal.Body>
        <Modal.Footer className="flex justify-end">
          <Button onClick={onClose} color="gray">
            Отмена
          </Button>
          <Button
            className="bg-primary-600 enabled:hover:bg-primary-500"
            onClick={() => formik.handleSubmit()}
            disabled={isLoading}
            isProcessing={isLoading}>
            {buttonTitle}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default NewProductUnitModal;
