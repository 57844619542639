import { TLogisticianForm } from '../components/procurement/LogisticianModal/LogisticianModal';
import { appApi } from './api_index';
import { ILogistician } from './procurement_api';

const logisticianApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    createLogistician: builder.mutation<ILogistician, TLogisticianForm>({
      query: (data) => ({
        url: `logistician`,
        credentials: 'include',
        body: data,
        method: 'POST',
      }),
      invalidatesTags: ['Buyer'],
    }),
    updateLogistician: builder.mutation<
      ILogistician,
      { data: Partial<TLogisticianForm>; id: number }
    >({
      query: ({ data, id }) => ({
        url: `logistician/${id}`,
        credentials: 'include',
        body: data,
        method: 'PATCH',
      }),
      invalidatesTags: ['Buyer'],
    }),
    fetchAllLogisticians: builder.query<ILogistician[], void>({
      query: () => ({
        url: `logistician`,
        credentials: 'include',
      }),
      providesTags: ['Buyer'],
    }),
    deleteLogistician: builder.mutation<ILogistician, number>({
      query: (logisticianId) => ({
        url: `logistician/${logisticianId}`,
        credentials: 'include',
        method: 'DELETE',
      }),
      invalidatesTags: ['Buyer'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyFetchAllLogisticiansQuery,
  useCreateLogisticianMutation,
  useDeleteLogisticianMutation,
  useUpdateLogisticianMutation,
} = logisticianApi;
