import { Button } from 'flowbite-react';
import React, { useContext, useState } from 'react';
import { MdDelete, MdEdit } from 'react-icons/md';
import { IProductUnitEconomicResearch } from '../../api/unit_economy_api';
import DeleteButton from '../ui/DeleteButton';
import { EntityActionProcurementContext } from './EntityActionContext';
import { FaPlus } from 'react-icons/fa6';


interface IEntityActionProcurementProps {
  row: any;
  handleDeleteProductUnitResearch?: (id: number) => void;
  handleUpdateProductUnitResearch?: (
    data: IProductUnitEconomicResearch,
  ) => void;
}

function EntityActionProcurement(props: IEntityActionProcurementProps) {
  const {
    handleChange,
    handleDelete,
    deleteLoading,
    showDeleteButton = false,
    handleAddToProcurement
  } = useContext(EntityActionProcurementContext);
  const { row } = props;
  const isEditButtonDisabled = !!row.original.is_enabled_by_default;

  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);

  async function handleAccept() {
    await handleDelete(row.original.id);
    setConfirmOpen(false);
  }

  return (
    <div className="flex">
      {showDeleteButton && (
        <DeleteButton
          confirmOpen={confirmOpen}
          setConfirmOpen={setConfirmOpen}
          className="text-red-600 bg-transparent enabled:hover:bg-red-200"
          onAccept={handleAccept}
          isLoading={deleteLoading}>
          <MdDelete className="w-5 h-5 text-red-600" />
        </DeleteButton>
      )}
      <Button
        disabled={isEditButtonDisabled}
        className="text-gray-800 bg-transparent enabled:hover:bg-gray-200"
        onClick={() => handleChange(row.original.id)}>
        <MdEdit className="w-5 h-5 text-gray-800" />
      </Button>
      <Button
        disabled={isEditButtonDisabled}
        className="text-gray-800 bg-transparent enabled:hover:bg-gray-200"
        onClick={() => handleAddToProcurement(row.original.id)}>
        <FaPlus className="w-5 h-5 text-gray-800" />
      </Button>
    </div>
  );
}

export default EntityActionProcurement;
