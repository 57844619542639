import { createColumnHelper } from '@tanstack/react-table';
import moment from 'moment';
import React from 'react';
import { DiscountCampaignSliceState } from '../../redux/discount_campaign/slice';
import EntityAction from '../EntityAction/EntityAction';
import Table from '../ui/Table';

export type DiscountCampaignType = Omit<DiscountCampaignSliceState, 'products'>;
interface ISalesTableProps extends React.ComponentPropsWithoutRef<'div'> {
  data: DiscountCampaignType[];
  paginator?: React.ReactNode;
}

function DiscountCampaignTable({
  data,
  paginator,
  ...props
}: ISalesTableProps) {
  const columnHelper = createColumnHelper<DiscountCampaignType>();

  const columns = [
    columnHelper.display({
      id: 'action',
      header: 'Действия',
      cell: (props: any) => (
        <div className="flex justify-center items-center">
          <EntityAction row={props.row} />
        </div>
      ),
      size: 100,
      maxSize: 100,
    }),
    columnHelper.accessor('name', {
      header: () => <span className="block w-full text-left">Акция</span>,
      cell: (info) => (
        <span className="block w-full text-left">{info.getValue()}</span>
      ),
      size: 300,
      minSize: 250,
    }),
    columnHelper.accessor('start_date', {
      header: 'Начало акции',
      cell: (info) =>
        moment(new Date(info.getValue() as string)).format('DD.MM.YYYY'),
    }),
    columnHelper.accessor('end_date', {
      header: 'Конец акции',
      cell: (info) =>
        moment(new Date(info.getValue() as string)).format('DD.MM.YYYY'),
    }),
    columnHelper.accessor('marketplace.name', {
      header: 'Маркетплейс',
    }),
  ];

  return (
    <Table
      data={data || []}
      columns={columns}
      paginator={paginator}
      className="table-fixed w-full"
      {...props}
    />
  );
}

export default DiscountCampaignTable;
