function getPriceFormatter(currency: string, format: string) {
  return new Intl.NumberFormat(format, {
    style: 'currency',
    currency: currency,
  });
}

function priceLocalFormat(number: number, currency: string, format: string) {
  return number.toLocaleString(format, {
    currency: currency,
    style: 'currency',
    maximumFractionDigits: 0
  });
}

export { getPriceFormatter, priceLocalFormat };
