import { TBuyerForm } from '../components/procurement/BuyerModal/BuyerModal';
import { appApi } from './api_index';
import { IBuyer } from './procurement_api';


const buyerApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    createBuyer: builder.mutation<IBuyer, TBuyerForm>({
      query: (data) => ({
        url: `buyer`,
        credentials: 'include',
        body: data,
        method: 'POST',
      }),
      invalidatesTags: ['Buyer'],
    }),
    updateBuyer: builder.mutation<
      IBuyer,
      { data: Partial<TBuyerForm>; id: number }
    >({
      query: ({ data, id }) => ({
        url: `buyer/${id}`,
        credentials: 'include',
        body: data,
        method: 'PATCH',
      }),
      invalidatesTags: ['Buyer'],
    }),
    fetchAllBuyers: builder.query<
      IBuyer[],
      void
    >({
      query: () => ({
        url: `buyer`,
        credentials: 'include',
      }),
      providesTags: ['Buyer'],
    }),
    deleteBuyer: builder.mutation<IBuyer, number>({
      query: (buyerId) => ({
        url: `buyer/${buyerId}`,
        credentials: 'include',
        method: 'DELETE',
      }),
      invalidatesTags: ['Buyer'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyFetchAllBuyersQuery,
  useDeleteBuyerMutation,
  useCreateBuyerMutation,
  useUpdateBuyerMutation
} = buyerApi;
