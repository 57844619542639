import { Button, Modal } from 'flowbite-react';
import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { IProcurementProduct } from '../../../api/procurement_api';
import ProcurementProductModalForm from './ProcurementProductModalForm';
import {
  ProcurementProductFormSchema,
  ProcurementProductWithProcurementIdFormSchema,
} from './validationSchema';

export interface ICreateProcurementProduct {
  procurement_id: number | null;
  name: string;
  competitor_url: string;
  marketplace_product_price: number;
  analytics_url: string;
  marketplace_fee: number;
  marketplace_logistics_expense: number;
  supplier_logistic_expense: number;
  usd_rub: number;
  cny_rub: number;
  product_count: number;
  procurement_fact: number;
  supplier_logistic_expense_fact: number;
  currency: string;
}

const formInitValues: ICreateProcurementProduct = {
  procurement_id: null,
  competitor_url: '',
  analytics_url: '',
  marketplace_fee: 0,
  marketplace_logistics_expense: 0,
  usd_rub: 0,
  cny_rub: 0,
  marketplace_product_price: 0,
  product_count: 0,
  currency: '',
  name: '',
  procurement_fact: 0,
  supplier_logistic_expense: 0,
  supplier_logistic_expense_fact: 0,
};

interface IProcurementProductModalProps {
  onClose: () => void;
  onSuccess?: (form: ICreateProcurementProduct) => void;
  isOpen: boolean;
  onSubmit: (values: ICreateProcurementProduct, isEdit: boolean) => void;
  procurementProduct: IProcurementProduct | null;
  isLoading: boolean;
  isProcurementIdKnown?: boolean;
}

function ProcurementProductModal({
  onClose,
  isOpen,
  onSuccess,
  onSubmit,
  procurementProduct,
  isLoading,
  isProcurementIdKnown = false,
}: IProcurementProductModalProps) {
  const [isVisible, setIsVisible] = useState(true);

  const isEdit = !!procurementProduct;
  const modalTitle = isEdit
    ? 'Редактирование товара закупки'
    : 'Новый товар закупки';

  const buttonTitle = isEdit ? 'Сохранить товар' : 'Добавить товар';
  const rootRef = useRef<HTMLDivElement>(null);
  const isStartForm = useRef<boolean>(false);

  const formik = useFormik<ICreateProcurementProduct>({
    initialValues: formInitValues,
    onSubmit: (values) => onSubmit(values, isEdit),
    validationSchema: isProcurementIdKnown
      ? ProcurementProductFormSchema
      : ProcurementProductWithProcurementIdFormSchema,
    isInitialValid: false,
  });

  //form error event
  useEffect(() => {
    let isError = false,
      isStart = false;
    for (const [key, value] of Object.entries(formik.errors)) {
      if (value && formik.touched[key as keyof ICreateProcurementProduct]) {
        isError = true;
        break;
      }
    }

    for (const [key, isTouched] of Object.entries(formik.touched)) {
      if (
        isTouched &&
        !formik.errors[key as keyof ICreateProcurementProduct] &&
        !isStartForm.current
      ) {
        (isStartForm as any).current = true;
        isStart = true;
        break;
      }
    }
  }, [formik.errors, formik.touched]);

  useEffect(() => {
    if (!procurementProduct) return;

    const {
      id,
      margin_per_product,
      margin_rate,
      soldout_margin,
      procurement,
      procurement_id,
      ...form_data
    } = procurementProduct;

    formik.setValues({
      ...form_data,
      procurement_id: isProcurementIdKnown ? procurement_id : null,
    });
  }, [procurementProduct, isOpen]);

  async function handleClose() {
    formik.resetForm();
    onClose();
  }

  return (
    <div ref={rootRef}>
      <Modal
        className={isVisible ? 'visible' : 'invisible'}
        theme={{
          root: { base: 'z-[10000]' },
        }}
        show={isOpen}
        onClose={handleClose}
        root={rootRef.current ?? undefined}>
        <Modal.Header>{modalTitle}</Modal.Header>
        <Modal.Body>
          <ProcurementProductModalForm
            values={formik.values}
            errors={formik.errors}
            handleChange={formik.handleChange}
            isEdit={isEdit}
            touched={formik.touched}
            isProcurementIdKnown={isProcurementIdKnown}
            setFieldValue={formik.setFieldValue}
            setIsParentModalVisible={setIsVisible}
          />
        </Modal.Body>
        <Modal.Footer className="flex justify-end">
          <Button onClick={handleClose} color="gray">
            Отмена
          </Button>
          <Button
            className="bg-primary-600 enabled:hover:bg-primary-500"
            onClick={() => formik.handleSubmit()}
            disabled={isLoading}
            isProcessing={isLoading}>
            {buttonTitle}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ProcurementProductModal;
