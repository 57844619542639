import React, { useMemo, useState } from 'react';
import Datepicker from 'tailwind-datepicker-react';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
interface IDatePicker {
  onChange: (date: Date) => void;
  title: string;
  minDate?: Date;
  maxDate?: Date;
}

function DatePicker({ onChange, title }: IDatePicker) {
  const [show, setShow] = useState<boolean>(false);

  const handleClose = (state: boolean) => {
    setShow(state);
  };

  //max date
  const nextyear = new Date();
  nextyear.setFullYear(nextyear.getFullYear() + 1);

  //min date
  const today = new Date();
  today.setDate(new Date().getDate() - 1);

  const options = useMemo(
    () => ({
      title,
      autoHide: true,
      todayBtn: true,
      todayBtnText: 'Сегодня',
      clearBtnText: 'Очистить',
      
      clearBtn: false,
      theme: {
        background: '',
        todayBtn: '',
        clearBtn: '',
        icons: '',
        text: '',
        disabledText: '',
        input: '',
        inputIcon: '',
        selected: 'hover:bg-primary-600',
      },
      icons: {
        // () => ReactElement | JSX.Element
        prev: () => <BsChevronLeft />,
        next: () => <BsChevronRight />,
      },
      datepickerClassNames: 'top-12',
      language: 'ru',
    }),
    [],
  );

  return (
    <div>
      <Datepicker
        options={options}
        onChange={onChange}
        show={show}
        setShow={handleClose}
      />
    </div>
  );
}

export default DatePicker;
