import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useTypedDispatch } from './redux/hooks';
import { useLazyRefreshAccessQuery } from './api/api_index';
import { IUser, setUser } from './redux/auth/slice';
import jwtDecode from 'jwt-decode';
import { Spinner } from 'flowbite-react';

function ProtectedRoute() {
  const [isSuccess, setIsSuccess] = useState(false);
  const [refreshToken] = useLazyRefreshAccessQuery();
  const dispatch = useTypedDispatch();

  //refresh token on initial render (before DOM is initialised)
  useEffect(() => {
    //check if the user is authenticated
    refreshAccess();
  }, []);

  async function refreshAccess() {
    try {
      const at = window.sessionStorage.getItem('access_token');
      if (!at) throw new Error();
      const user: any = jwtDecode(at as string);
      dispatch(setUser(user));

      //check if the token is still valid
      if (Date.now() < user.exp * 1000) {
        setIsSuccess(true);
        return;
      }
    } catch {}

    try {
      const res = await refreshToken().unwrap();
      window.sessionStorage.setItem('access_token', res.access_token);
      const user: IUser = jwtDecode(res.access_token);
      dispatch(setUser(user));
      setIsSuccess(true);
    } catch (e) {
      console.log(e);
    }
  }

  if (!isSuccess) {
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <Spinner
          size="xl"
          className='fill-primary-600'
        />
      </div>
    );
  }

  return <Outlet />;
}

export default ProtectedRoute;
