import { NavigateFunction } from 'react-router-dom';
import {
  IOnboardingTourSliceState,
  pauseTour,
  setCollapseOpen,
  setStepIndex,
  startTour,
  stopTour,
} from '../redux/onboarding_tour/slice';
import { OnboardingTourStep, onboardingTourSteps } from '../redux/onboarding_tour/steps';
import waitForElement from './waitForElement';

// export async function moveToOnbordingTourStep(
//   dispatch: Function,
//   navigateFn: NavigateFunction,
//   stepIndex: number,
// ) {
//   console.log("Move to tour step!")
//   let nextStepIndex = stepIndex;
//   let allowToStartTour = false;
//   switch (stepIndex) {
//     case OnboardingTourStep.STEP1_OPEN_DISCOUNT_COLLAPSE:
//       allowToStartTour = true;
//       break;
//     case OnboardingTourStep.STEP2_CLICK_NEW_DISCOUNT_CAMPAIGN:
//       dispatch(
//         setCollapseOpen({
//           type: 'research',
//           open: true,
//         }),
//       );
//       allowToStartTour = true;
//       break;
//     case OnboardingTourStep.STEP3_ADD_DISCOUNT_CAMPAIGN:
//       console.log("pauseTour in init");
//       dispatch(pauseTour());
//       navigateFn?.('/account/discount-campaigns?create=true', {
//         replace: true,
//       });
//       break;
//     case OnboardingTourStep.STEP4_FULFILL_PRODUCT_COST:
//       console.log("pauseTour in init");
//       dispatch(pauseTour());
//       // navigateFn?.('/account/discount-campaigns?create=true', {
//       //   replace: true,
//       // });
//       break;
//     case OnboardingTourStep.STEP5_FULFILL_PRODUCT_LOGISTIC_EXPENSE:
//       break;
//     case OnboardingTourStep.STEP6_FULFILL_PRODUCT_COMMISION_REDUCTION:
//       break;
//     case OnboardingTourStep.STEP7_ANALYZE_MARGIN_RATE:
//       break;
//     case OnboardingTourStep.STEP8_DOWNLOAD_FILE:
//       break;
//   }

//   dispatch(setStepIndex(nextStepIndex));
//   if (allowToStartTour) {
//     setTimeout(() => {
//       dispatch(startTour());
//     }, 1000);
//   }
// }

export default async function initOnBoardingTour(
  dispatch: Function,
  navigateFn: NavigateFunction,
  onboardingState: IOnboardingTourSliceState,
) {
  console.log(onboardingState);
  const storedDataString = localStorage.getItem('onboarding_tour_data');
  if (!storedDataString) {
    //start the tour for new user
    dispatch(setStepIndex(OnboardingTourStep.STEP0_OPEN_SIDEBAR));
    dispatch(startTour());

    console.log('!storedDataString');
    return;
  }

  const storedData = JSON.parse(storedDataString);
  console.log(storedData);

  //don't start tour if it is not active
  if (!storedData.active) {
    dispatch(stopTour());
    return;
  }

  let nextStepIndex = OnboardingTourStep.STEP0_OPEN_SIDEBAR;

  if (
    !onboardingState.sidebarOpen &&
    storedData.stepIndex <= OnboardingTourStep.STEP2_CLICK_NEW_DISCOUNT_CAMPAIGN
  ) {
    nextStepIndex = OnboardingTourStep.STEP0_OPEN_SIDEBAR;
    console.log('STEP0_OPEN_SIDEBAR');
    dispatch(startTour());
  } else if (
    onboardingState.sidebarOpen &&
    storedData.stepIndex ===
      OnboardingTourStep.STEP2_CLICK_NEW_DISCOUNT_CAMPAIGN &&
    !onboardingState.collapseOpen.research
  ) {
    nextStepIndex = OnboardingTourStep.STEP1_OPEN_DISCOUNT_COLLAPSE;
    console.log('STEP1_OPEN_DISCOUNT_COLLAPSE');
    dispatch(startTour());
  } else if (
    storedData.stepIndex === OnboardingTourStep.STEP3_ADD_DISCOUNT_CAMPAIGN
  ) {
    nextStepIndex = OnboardingTourStep.STEP3_ADD_DISCOUNT_CAMPAIGN;
    navigateFn?.('/account/discount-campaigns?create=true');
    dispatch(pauseTour());
  } else if (
    storedData.stepIndex >= OnboardingTourStep.STEP3_ADD_DISCOUNT_CAMPAIGN
  ) {
    nextStepIndex = OnboardingTourStep.STEP3_ADD_DISCOUNT_CAMPAIGN;
    navigateFn?.('/account/discount-campaigns?create=true');
    dispatch(pauseTour());
  } else {
    nextStepIndex = storedData.stepIndex;

    if (
      nextStepIndex === OnboardingTourStep.STEP0_OPEN_SIDEBAR &&
      onboardingState.collapseOpen
    ) {
      nextStepIndex = OnboardingTourStep.STEP1_OPEN_DISCOUNT_COLLAPSE;
    }
    
    waitForElement(onboardingTourSteps[OnboardingTourStep.STEP1_OPEN_DISCOUNT_COLLAPSE].target as string).then(()=>{
      dispatch(startTour())
    })
  }

  dispatch(setStepIndex(nextStepIndex));
}
