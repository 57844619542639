import React from 'react';
import trackEvent, { MetricEventType } from '../../../utils/trackEvent';

/* Компонент-обертка для кнопок входа OAuth
1. Кнопка яндекса скрыта, вместо неё показывается кастомная кнопка, при нажатии, делается клик на настоящую кнопку (метод click)
2. Кнопка Гугл скрыта, вместо неё показывается кастомная кнопка, при нажатии, делается клик на настоящую кнопку (метод click)
3. Кнопка ВК не скрыта, обработчик клика на нее инициализируется в месте загрузки скрипта VKIDSDK
*/
export default function AuthButtonWrapper({
  type,
  id,
  button,
}: {
  type: 'yandex' | 'google' | 'vk';
  id: string;
  button: React.ReactElement;
}) {
  function handleFakeClick() {
    // У контейнера с кнопками идентификатор id (начало селектора)
    trackEvent(MetricEventType.OAUTH_BUTTON, { type });
    let query: string = `#${id} `;

    // найти настоящую кнопку yandex или google
    switch (type) {
      case 'yandex':
        query += 'button';
        break;
      case 'google':
        query += 'div[role=button]';
        break;
    }

    let btn_el = document.querySelector(query) as HTMLButtonElement;
    // Сделать клик на настоящей кнопке при клике на кастомную
    if (btn_el) {
      btn_el.click();
    }
  }

  // Передать колбэк обработки клика в дочерний компонент
  return React.cloneElement(button, { onClick: handleFakeClick });
}
