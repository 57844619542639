import { Button as FlowButton, FlowbiteColors } from 'flowbite-react';
import React from 'react';

interface IButtonProps {
  children: React.ReactNode;
  color?: keyof FlowbiteColors;
}

function Button({
  children,
  onClick,
  className,
  color,
}: IButtonProps & React.HTMLAttributes<HTMLButtonElement>) {
  const class_name = color
    ? className
    : `bg-primary-600 hover:bg-primary-500 transition-all ${className}`;
  return (
    <FlowButton color={color} className={class_name} onClick={onClick}>
      {children}
    </FlowButton>
  );
}

export default Button;
