import { Button, Modal } from 'flowbite-react';
import React from 'react';

interface IConfirmDialogProps {
  actionButton: React.ReactNode;
  open: boolean;
  onClose: () => void;
  title?: string;
  info?: React.ReactNode | string;
}
function ConfirmDialog({
  open,
  actionButton,
  onClose,
  title,
  info,
}: IConfirmDialogProps) {
  return (
    <Modal
      show={open}
      onClose={onClose}
      size="md"
      theme={{
        root: {
          base: 'z-[10000]',
        },
      }}>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Body>{info}</Modal.Body>
      <Modal.Footer className="flex justify-end">
        <Button onClick={onClose} color="gray">
          Отмена
        </Button>
        {actionButton}
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmDialog;
