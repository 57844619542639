'use client';

import { Button, Sidebar } from 'flowbite-react';
import moment from 'moment';
import { ComponentProps, useMemo } from 'react';
import { FaChevronLeft } from 'react-icons/fa';
import {
  HiChartPie,
  HiInbox,
  HiViewBoards,
  HiViewGridAdd,
} from 'react-icons/hi';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { useLocation, useNavigate } from 'react-router-dom';
import { DiscountCampaignSliceState } from '../redux/discount_campaign/slice';
import { useTypedDispatch, useTypedSelector } from '../redux/hooks';
import {
  ICollapseOpen,
  pauseTour,
  resetCollapse,
  setCollapseOpen,
  setStepIndex,
  startTour,
} from '../redux/onboarding_tour/slice';
import { OnboardingTourStep } from '../redux/onboarding_tour/steps';
//import { moveToOnbordingTourStep } from '../utils/initOnboardingTour';
import { IProcurement } from '../api/procurement_api';
import trackEvent, { MetricEventType } from '../utils/trackEvent';

interface IAppSidebar {
  className?: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  sales?: DiscountCampaignSliceState[];
  procurements?: IProcurement[];
}

export default function AppSidebar({
  className = '',
  sales,
  procurements,
  open,
  setOpen,
}: IAppSidebar) {
  const navigate = useNavigate();
  const location = useLocation();

  console.log('Render sidebar');

  const dispatch = useTypedDispatch();
  const { collapseOpen: collapse, stepIndex } = useTypedSelector(
    (state) => state.oboarding_tour,
  );

  function handleToggleMenu() {
    const wasOpen = open;
    //collapse the menu
    setOpen((st) => !st);
    //close all menu collapse components
    dispatch(resetCollapse());

    // onboarding control
    if (stepIndex == 0 && !wasOpen) {
      //if stepindex is 0 and we open collapsed menu? then next step
      //open the
      dispatch(setStepIndex(stepIndex + 1));
    } else if (wasOpen) {
      //if menu is closed, move to step zero
      dispatch(setStepIndex(0));
    }
  }

  function handleToggleCollapse(field: keyof ICollapseOpen) {
    if (field === 'research') {
      const nextTourStepIndex = collapse.research
        ? OnboardingTourStep.STEP1_OPEN_DISCOUNT_COLLAPSE
        : OnboardingTourStep.STEP2_CLICK_NEW_DISCOUNT_CAMPAIGN;

      // onboarding control
      dispatch(pauseTour());
      dispatch(setStepIndex(nextTourStepIndex));

      setTimeout(() => {
        dispatch(startTour());
      }, 200);
    }

    if (open) {
      dispatch(setCollapseOpen({ type: field, open: !collapse[field] }));
      return;
    }

    let url = '';
    switch (field) {
      case 'research':
        url = 'discount-campaigns';
        break;
    }

    navigate(`/account/${url}`);
  }

  function handleAddNewDiscountCampaignLocal() {
    // onboarding control
    dispatch(setStepIndex(OnboardingTourStep.STEP3_ADD_DISCOUNT_CAMPAIGN));
    dispatch(pauseTour());

    navigate('/account/discount-campaigns?create=true', {
      replace: true,
    });

    // metrics control
    trackEvent(MetricEventType.ADDPROMO_BUTTON_MENU);
    trackEvent(MetricEventType.ADDPROMO_FORM_START);
  }

  function handleAddNewProcurementLocal() {
    navigate('/account/procurement?create=true', {
      replace: true,
    });
  }

  //current menu tab
  const currentTab = useMemo(() => {
    return location.pathname.replace('/account', '');
  }, [location]);

  //open help page
  function handleHelpClick() {
    trackEvent(MetricEventType.HELP_PAGE_LINK);
    window.open(
      'https://gleaming-find-c09.notion.site/Sellerator-a346f8427b9f4fefb47f24f9937bebda',
      '_blank',
    );
  }

  return (
    <Sidebar collapsed={!open} className={className}>
      <div
        className={`flex items-center ${
          open ? 'justify-end' : 'justify-center'
        }`}>
        <Button
          id="onboarding-step-0"
          onClick={handleToggleMenu}
          color="gray"
          className="bg-transparent hover:bg-gray-300 focus:ring-0 enabled:hover:text-black enabled:focus:text-black border-0">
          <FaChevronLeft
            size={15}
            className={`${open ? 'roatate-0' : 'rotate-180'} transition-all`}
          />
        </Button>
      </div>
      <Sidebar.Items>
        <Sidebar.ItemGroup>
          <Sidebar.Item
            onClick={() => navigate('/account/')}
            icon={HiChartPie}
            className={`cursor-pointer ${
              currentTab === '/'
                ? 'bg-primary-100  hover:bg-primary-100'
                : 'bg-transparent'
            }`}>
            Дашборд
          </Sidebar.Item>
          <Sidebar.Item
            className={`cursor-pointer ${
              currentTab === '/unit-economy'
                ? 'bg-primary-100  hover:bg-primary-100'
                : 'bg-transparent'
            }`}
            icon={HiViewBoards}
            onClick={() => navigate(`/account/unit-economy`)}>
            Юнит-экономика
          </Sidebar.Item>
          <Sidebar.Collapse
            id="onboarding-step-1"
            className={
              currentTab === '/discount-campaigns'
                ? 'bg-primary-100 hover:bg-primary-100'
                : 'bg-transparent'
            }
            icon={HiInbox}
            label="Расчёт по акциям"
            open={collapse.research}
            onClick={() => handleToggleCollapse('research')}>
            {open && (
              <>
                {sales?.map((s) => (
                  <Sidebar.Item
                    key={s.id}
                    className="cursor-pointer"
                    onClick={() =>
                      navigate(`/account/discount-campaigns/${s.id}`)
                    }>
                    {s.name}{' '}
                    {moment(new Date(s.start_date as string)).format('DD.MM')}
                  </Sidebar.Item>
                ))}
              </>
            )}

            <Sidebar.Item
              className="cursor-pointer"
              onClick={() => navigate(`/account/discount-campaigns`)}>
              Все акции
            </Sidebar.Item>
            <Button
              id="onboarding-step-2"
              onClick={handleAddNewDiscountCampaignLocal}
              className="bg-primary-600 mx-auto w-full enabled:hover:bg-primary-500">
              <IoMdAddCircleOutline className="h-5 w-5 mr-2" />
              <span>Добавить акцию</span>
            </Button>
          </Sidebar.Collapse>

          <Sidebar.Collapse
            className={
              currentTab === '/procurement'
                ? 'bg-primary-100 hover:bg-primary-100'
                : 'bg-transparent'
            }
            icon={HiViewGridAdd}
            label="Закупки"
            open={collapse.procurement}
            onClick={() => handleToggleCollapse('procurement')}>
            {open && (
              <>
                {procurements?.map((procurement) => (
                  <Sidebar.Item
                    key={procurement.id}
                    className="cursor-pointer"
                    onClick={() =>
                      navigate(`/account/procurement/${procurement.id}`)
                    }>
                    <span>{procurement.name}</span>
                    <span className="ml-3 text-sm font-light">
                      {moment(procurement.created_at).format('DD.MM')}
                    </span>
                  </Sidebar.Item>
                ))}
              </>
            )}

            <Sidebar.Item
              className="cursor-pointer"
              onClick={() => navigate(`/account/procurement`)}>
              Все закупки
            </Sidebar.Item>
            <Button
              onClick={handleAddNewProcurementLocal}
              className="bg-primary-600 mx-auto w-full enabled:hover:bg-primary-500">
              <IoMdAddCircleOutline className="h-5 w-5 mr-2" />
              <span>Добавить закупку</span>
            </Button>
          </Sidebar.Collapse>

          {/* <Sidebar.Collapse
            href="#"
            icon={HiUser}
            label="Задачи"
            open={collapse.task}
            onClick={() => handleToggleCollapse('task')}></Sidebar.Collapse>

          <Sidebar.Collapse
            href="#"
            icon={HiShoppingBag}
            label="Файлы"
            open={collapse.file}
            onClick={() => handleToggleCollapse('file')}></Sidebar.Collapse>

          <Sidebar.Collapse
            href="#"
            icon={HiArrowSmRight}
            label="Добавить"
            open={collapse.add}
                  onClick={() => handleToggleCollapse('add')}></Sidebar.Collapse>*/}
        </Sidebar.ItemGroup>
        <Sidebar.ItemGroup>
          <MenuItem
            icon={HiViewBoards}
            title="Как пользоваться?"
            collapse={!open}
            onClick={handleHelpClick}
          />
        </Sidebar.ItemGroup>
      </Sidebar.Items>
    </Sidebar>
  );
}

interface IMenuItemProps extends React.ComponentPropsWithoutRef<'button'> {
  title: string;
  icon?: React.FC<ComponentProps<'svg'>>;
  collapse: boolean;
}

function MenuItem({ title, icon, collapse, ...props }: IMenuItemProps) {
  return (
    <Sidebar.Item href="#" icon={icon} {...props}>
      {!collapse ? title : ''}
    </Sidebar.Item>
  );
}

function MenuCollapse({ icon, collapse, children, ...props }: IMenuItemProps) {
  if (collapse) {
    return (
      <Sidebar.Item icon={icon} collapse={collapse} {...props}>
        {children}
      </Sidebar.Item>
    );
  }

  return (
    <Sidebar.Collapse icon={icon} {...props}>
      {children}
    </Sidebar.Collapse>
  );
}
