import { TableBody } from 'flowbite-react/lib/esm/components/Table/TableBody';
import React, { useState } from 'react';

interface IPopoverProps {
  button: React.ReactElement;
  title: string;
  body: React.ReactElement
}
function Popover({ button, title, body }: IPopoverProps) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div onMouseEnter={handleOpen} onMouseLeave={handleClose}>
      {React.cloneElement(button)}
      <div
        role="tooltip"
        className={`absolute z-10 inline-block w-64 text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm dark:text-gray-400 dark:border-gray-600 dark:bg-gray-800 ${open ? "visble opacity-100": "invisible opacity-0"}`}>
        <div className="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-lg dark:border-gray-600 dark:bg-gray-700">
          <h3 className="font-semibold text-gray-900 dark:text-white">
            {title}
          </h3>
        </div>
        <div className="px-3 py-2 whitespace-normal">
          {body}
        </div>
      </div>
    </div>
  );
}

export default Popover;
