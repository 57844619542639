import React, { useState, useEffect } from 'react';

type TColumnVisibility = Record<string, boolean>;

type TableMode = 'compact' | 'full';
// Наш хук
export default function useColumnVisibility(columnsToHide: string[]) {
  const [mode, setMode] = useState<TableMode>('compact');
  const [columnVisibility, setColumnVisibility] =
    React.useState<TColumnVisibility>({});

  //switch to compact mode on mount
  useEffect(() => {
    onCompactMode();
  }, []);

  //switch to full mode (unhide all columns)
  function onFullMode() {
    setColumnVisibility({});
    setMode('full');
  }

  //switch to compact mode (hide columns)
  function onCompactMode() {
    const tmp: Record<string, boolean> = {};
    columnsToHide.forEach((column) => {
      tmp[column] = false;
    });

    setColumnVisibility(tmp);
    setMode('compact');
  }

  //toggle function to pass to switch component
  function onToggle(checked: boolean) {
    if (checked) {
      onFullMode();
    } else {
      onCompactMode();
    }
  }

  return { columnVisibility, onFullMode, onCompactMode, mode, onToggle };
}
