import React from 'react';

const marketplaceHelpers = {
  wb: {
    text: 'Файл находится в кабинете WB: ',
    link: 'https://seller.wildberries.ru/dp-promo-calendar',
  },
  ozon: {
    text: 'Файл находится в кабинете Ozon: ',
    link: 'https://seller.ozon.ru/app/highlights/list',
  },
};

export default function FileInputHelperText({
  marketplace,
  onLinkClick,
}: {
  marketplace: 'wb' | 'ozon';
  onLinkClick?: () => void;
}) {
  const { text, link } = marketplaceHelpers[marketplace];

  return (
    <span>
      {text}
      <a
        href={link}
        target="_blank"
        className="text-primary-500"
        onClick={onLinkClick}>
        {link}
      </a>
    </span>
  );
}
