import { appApi } from './api_index';

export interface ISubscription {
  id: number;
  organization_id: number;
  tariff_plan_id: number;
  transaction_id: number;
  start_date: Date;
  end_date: Date;
  trial: boolean;
  tariff_plan: ITariffPlan;
}

export interface ITariffPlan {
  code: string;
  title: string;
  features: string[];
}

export interface InitPaymentBody {
  code: string;
  period: "month" | "year"
}

const subscriptionApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchSubscription: builder.query<ISubscription, void>({
      query: () => ({
        url: `subscription`,
        credentials: 'include',
      }),
    }),
    initSubscriptionPayment: builder.mutation<
      any,
      InitPaymentBody
    >({
      query: (body) => ({
        url: `subscription/init-payment`,
        credentials: 'include',
        body: body,
        method: 'POST',
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useLazyFetchSubscriptionQuery, useFetchSubscriptionQuery, useInitSubscriptionPaymentMutation } =
  subscriptionApi;
