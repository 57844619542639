import { Button, Spinner, TextInput } from 'flowbite-react';

import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { BsSearch } from 'react-icons/bs';
import { IoClose } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  CreateDiscountCampaignProduct,
  FetchDiscountCampaignProduct,
  useCreateDiscountCampaignProductMutation,
  useLazyGetDiscountCampaignQuery,
  useRemoveDiscountCampaignProductsMutation,
  useUpdateDiscountCampaignProductMutation,
} from '../../api/discount_campaign_api';
import { useFetchSubscriptionQuery } from '../../api/subscription';
import DiscountCampagnProductModal, {
  DiscountCampaignProductForm,
} from '../../components/discount_campaign/DiscountCampagnProductModal';
import DiscountCampaignSubscriptionAlert from '../../components/discount_campaign/DiscountCampaignSubscriptionAlert';
import ProductTable from '../../components/discount_campaign/ProductTable';
import { EntityActionContext } from '../../components/EntityAction/EntityActionContext';
import TablePaginator from '../../components/ui/TablePaginator';
import useDebounce from '../../hooks/useDebounce';
import usePaginate from '../../hooks/usePaginate';
import { useTypedDispatch, useTypedSelector } from '../../redux/hooks';
import { startTour, stopTour } from '../../redux/onboarding_tour/slice';
import { OnboardingTourStep } from '../../redux/onboarding_tour/steps';
import trackEvent, { MetricEventType } from '../../utils/trackEvent';
import ConfirmDialog from '../../components/ui/ConfirmDialog';

function DiscountCampaignEditView() {
  const { discountCampaignId } = useParams();
  const subscription = useFetchSubscriptionQuery();
  const {
    stepIndex: onboardingStepIndex,
    run,
    active,
  } = useTypedSelector((state) => state.oboarding_tour);
  const dispatch = useTypedDispatch();
  const isSubscriptonActive = subscription.data;

  const [
    fetchDiscountCampaign,
    { data: discountCampaignData, isLoading: isFetchDiscountCampaignLoading },
  ] = useLazyGetDiscountCampaignQuery();
  const [updateDiscountCampaignProduct, { isLoading: isUpdateProductLoading }] =
    useUpdateDiscountCampaignProductMutation();
  const [createDiscountCampaignProduct, { isLoading: isCreateProductLoading }] =
    useCreateDiscountCampaignProductMutation();
  const [removeProducts, { isLoading: isRemoveProductsLoading }] =
    useRemoveDiscountCampaignProductsMutation();

  const formRef = useRef<HTMLFormElement>(null);
  const inputTokenRef = useRef<HTMLInputElement>(null);

  const [search, setSearch] = useState('');

  const [modalOpen, setModalOpen] = useState(false);
  const [downloadWarningModalOpen, setDownloadWarningModalOpen] =
    useState(false);

  const [discountCampaignProduct, setDiscountCampaignProduct] =
    useState<FetchDiscountCampaignProduct | null>(null);

  const debouncedSearch = useDebounce(search, 500);
  const { paginationState, handleChangePage, resetState } = usePaginate({
    handlePaginate,
  });

  useEffect(() => {
    getDiscountCampaign(discountCampaignId as string, {
      take: 10,
      page: 0,
      search: debouncedSearch,
    });
    resetState();
  }, [debouncedSearch, discountCampaignId]);

  // onboarding control
  useEffect(() => {
    if (
      onboardingStepIndex > OnboardingTourStep.STEP3_ADD_DISCOUNT_CAMPAIGN &&
      !isFetchDiscountCampaignLoading &&
      !run &&
      active
    ) {
      setTimeout(() => dispatch(startTour()), 200);
    }
  }, [onboardingStepIndex, isFetchDiscountCampaignLoading]);

  async function getDiscountCampaign(id: string, params: any) {
    try {
      const res = await fetchDiscountCampaign({ id: id, ...params }).unwrap();
      return res;
    } catch (e) {
      console.log(e);
    }
  }

  function handleCloseModal() {
    setDiscountCampaignProduct(null);
    setModalOpen(false);
  }

  //open modal to create new discount campaign product
  function handleAddProductToDiscountCampaign() {
    setModalOpen(true);
  }

  async function handleRemoveProduct(idx: number) {
    try {
      await removeProducts({
        id: +(discountCampaignId as string),
        productsIds: [idx],
      }).unwrap();

      trackEvent(MetricEventType.ADDPROMO_WORK_DELETE);
    } catch (err) {
      toast.error('Произошла ошибка при удалении товара акции');
    }
  }

  async function handleSubmit(
    values: DiscountCampaignProductForm,
    isEdit: boolean,
  ) {
    try {
      //update entity
      if (isEdit) {
        await updateDiscountCampaignProduct({
          product: values,
          discountCampaignId: discountCampaignId as any,
        }).unwrap();
      } else {
        //create entity
        await createDiscountCampaignProduct({
          id: +(discountCampaignId as string),
          product: values,
        }).unwrap();
      }
      toast.success(
        isEdit ? 'Товар успешно обновлён' : 'Товар успешно сохранён',
        { position: 'bottom-right' },
      );

      setModalOpen(false);
      setDiscountCampaignProduct(null);
    } catch (err) {
      console.log(err);
    }
  }

  async function handleUpdateDiscountCampaignProduct(id: number) {
    setModalOpen(true);
    const data = discountCampaignData?.data.products.find((el) => el.id === id);

    if (data) {
      setDiscountCampaignProduct(data as any as FetchDiscountCampaignProduct);
    }

    trackEvent(MetricEventType.ADDPROMO_WORK_EDIT);
  }

  async function handlePaginate(take: number, page: number) {
    try {
      const params = { take, page };
      if (search) {
        (params as any).search = search;
      }
      const resp = await getDiscountCampaign(
        discountCampaignId as string,
        params,
      );

      return (resp as any).data?.max_page;
    } catch (err) {
      console.log(err);
    }
  }

  function handleSubmitExportForm() {
    //get up to date access token from session storage
    const token = sessionStorage.getItem('access_token');

    //finish onboarding tour
    dispatch(stopTour());

    //update access tokrn inside of form
    if (inputTokenRef.current) {
      inputTokenRef.current.value = token as string;
    }

    trackEvent(MetricEventType.ADDPROMO_WORK_SUCCESS);

    //submit the form
    formRef.current?.submit();
  }

  function handleClickDownloadButton() {
    const hasUserDownloadedFiles = localStorage.getItem(
      'has_user_downloaded_files',
    );
    if (!hasUserDownloadedFiles) {
      //open warning popup
      setDownloadWarningModalOpen(true);
      localStorage.setItem('has_user_downloaded_files', 'true');
      return;
    }

    handleSubmitExportForm();
  }

  async function handlePatchProduct(
    data: Partial<CreateDiscountCampaignProduct> & { id: number },
  ) {
    try {
      await updateDiscountCampaignProduct({
        discountCampaignId: discountCampaignId as any,
        product: data,
      }).unwrap();
    } catch (err) {}
  }

  function handleClickDownloadButtonInModal() {
    setDownloadWarningModalOpen(false);
    handleSubmitExportForm();
  }
  const howTo = () => {
    trackEvent(MetricEventType.HELP_PAGE_LINK, { source: 'edit_campaign' });
    window.open(
      'https://gleaming-find-c09.notion.site/Sellerator-a346f8427b9f4fefb47f24f9937bebda',
      '_blank',
    );
  };
  const ActionButtons = () => (
    <div className="flex gap-4">
      {/* <Button
        onClick={handleAddProductToDiscountCampaign}
        className="bg-primary-600 enabled:hover:bg-primary-500">
        <IoMdAddCircleOutline className="h-5 w-5 mr-2" />
        <span>Добавить товар</span>
      </Button> */}

      <form
        id="onboarding-step-8"
        ref={formRef}
        className="flex gap-8"
        method="POST"
        target="_blank"
        action={`${process.env.REACT_APP_BACKEND_URL}/api/discount-campaign/${discountCampaignId}/export`}>
        <input
          ref={inputTokenRef}
          type="hidden"
          value={sessionStorage.getItem('access_token') as string}
          name="access_token"
        />
        <button
          onClick={() => handleClickDownloadButton()}
          className="bg-primary-600 hover:bg-primary-500 text-white flex justify-center items-center rounded-lg px-5 text-sm font-medium py-3">
          Скачать файл
        </button>
      </form>
      <Button
        className="bg-green-700 w-auto ml-auto enabled:hover:bg-green-600"
        onClick={howTo}>
        Как пользоваться?
      </Button>
    </div>
  );

  return (
    <div>
      <div className="flex flex-row justify-between mb-7 items-center">
        <div className="flex items-center">
          <h2 className="font-bold text-3xl mr-5">
            {discountCampaignData?.data.name}
          </h2>
          <span className="text-lg font-light">{`${discountCampaignData?.data.marketplace.name}`}</span>
        </div>

        <div className="flex gap-8 items-center">
          <span>
            Начало:{' '}
            {moment(
              new Date(discountCampaignData?.data?.start_date as string),
            ).format('DD.MM.YYYY')}
          </span>
          <span>
            Конец:{' '}
            {moment(
              new Date(discountCampaignData?.data?.end_date as string),
            ).format('DD.MM.YYYY')}
          </span>
        </div>
      </div>

      <div className="flex flex-row gap-4 my-7">
        <TextInput
          icon={BsSearch}
          id="search"
          placeholder="Поиск"
          required
          type="text"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Button
          onClick={() => setSearch('')}
          style={{ height: 42, width: 42 }}
          className="!focus:ring-0 bg-gray-50 enabled:hover:bg-gray-300 !ring-0 !ring-transparent !outline-none !border-gray-300">
          <IoClose size={20} color="black" />
        </Button>
      </div>

      {/* No active subscription alert */}
      {!isSubscriptonActive && !subscription.isLoading && (
        <DiscountCampaignSubscriptionAlert />
      )}

      <>
        {(() => {
          if (isFetchDiscountCampaignLoading) {
            return (
              <div className="w-full flex justify-center gap-4 items-center">
                <Spinner size="xl" />
                <span>Загрузка</span>
              </div>
            );
          }

          return (
            <EntityActionContext.Provider
              value={{
                deleteLoading: false,
                handleChange: handleUpdateDiscountCampaignProduct,
                handleDelete: handleRemoveProduct,
              }}>
              <ProductTable
                data={discountCampaignData?.data.products || []}
                addonComponent={<ActionButtons />}
                handlePatchProduct={handlePatchProduct}
                paginator={
                  <TablePaginator
                    handleChangePage={handleChangePage}
                    paginationState={paginationState}
                    maxPage={discountCampaignData?.max_page}
                  />
                }
              />
            </EntityActionContext.Provider>
          );
        })()}
      </>

      <DiscountCampagnProductModal
        isOpen={modalOpen}
        onClose={handleCloseModal}
        onSubmit={handleSubmit}
        discountCampaignProduct={discountCampaignProduct}
        isLoading={isCreateProductLoading}
      />

      <ConfirmDialog
        open={downloadWarningModalOpen}
        onClose={() => setDownloadWarningModalOpen(false)}
        title="Предупреждение"
        info="Выгрузка с файлом акций находится в тестовом режиме, пожалуйста, перепроверяйте данные вручную, как установились скидки, чтобы новые применились, а старые не отменились"
        actionButton={
          <Button
            onClick={handleClickDownloadButtonInModal}
            className="bg-primary-600 enabled:hover:bg-primary-500">
            Загрузить файл
          </Button>
        }
      />
    </div>
  );
}

export default DiscountCampaignEditView;
