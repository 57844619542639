import { Label, Select, SelectProps, TextInput, TextInputProps } from 'flowbite-react';
import React from 'react';
import FormErrorMessage from './FormErrorMessage';

interface IFormInputProps extends SelectProps {
  error?: string;
  isTouched: boolean;
}

function FormSelectInput({ error, isTouched, ...rest }: IFormInputProps) {
  return (
    <div>
      <div className="mb-2 block">
        <Label htmlFor={rest.name} value={rest.title} />
      </div>
      <Select {...rest} />
      <FormErrorMessage error={error} touched={isTouched} />
    </div>
  );
}

export default FormSelectInput;
