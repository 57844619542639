import { FormikErrors, FormikTouched } from 'formik';
import FormInput from '../ui/FormInput';
import { CreateDiscountCampaignProduct } from '../../api/discount_campaign_api';
import FormCheckboxInput from '../ui/FormCheckboxInput';

interface IDiscountCampaigProductFormProps {
  values: CreateDiscountCampaignProduct;
  errors: FormikErrors<CreateDiscountCampaignProduct>;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleChooseProduct?: (id: number) => void;
  isEdit: boolean;
  touched: FormikTouched<CreateDiscountCampaignProduct>;
}

const currency_options = ['usd', 'rub', 'cny'];

export default function DiscountCampaigProductForm({
  values,
  errors,
  handleChange,
  handleChooseProduct,
  isEdit,
  touched,
}: IDiscountCampaigProductFormProps) {
  return (
    <form>
      <div className="grid grid-cols-2 gap-x-8 gap-y-3">
        {/* Product's name */}
        <FormInput
          name="title"
          title="Наименование товара"
          id="title"
          placeholder="Товар"
          required
          type="text"
          onChange={handleChange}
          value={values.title as string}
          error={errors.title}
          isTouched={!!touched.title}
        />

        <FormInput
          name="brand"
          title="Бренд товара"
          id="brand"
          placeholder="Бренд"
          type="text"
          onChange={handleChange}
          value={values.brand as string}
          error={errors.brand}
          isTouched={!!touched.brand}
        />

        <FormInput
          name="collection"
          title="Коллекция товара"
          id="collection"
          placeholder="Коллекция"
          type="text"
          onChange={handleChange}
          value={values.collection as string}
          error={errors.collection}
          isTouched={!!touched.collection}
        />

        {/* Product's weight */}
        <FormInput
          id="marketplace_in_stock_amount"
          title="Остаток, шт"
          placeholder="Остаток"
          required
          type="number"
          name="marketplace_in_stock_amount"
          min={0}
          onChange={handleChange}
          value={values.marketplace_in_stock_amount}
          //disabled={!newProduct}
          error={errors.marketplace_in_stock_amount}
          isTouched={!!touched.marketplace_in_stock_amount}
        />

        {/* Product's url */}
        <FormInput
          id="supplier_num"
          title="Артикул поставщика"
          placeholder="Артикул поставщика"
          type="text"
          name="supplier_num"
          onChange={handleChange}
          value={values.supplier_num}
          error={errors.supplier_num}
          isTouched={!!touched.supplier_num}
        />

        {/* Product's logistics_rate */}
        <FormInput
          id="nomenclature"
          title="Номенклатура"
          placeholder="Номенклатура"
          required
          type="number"
          name="nomenclature"
          min={0}
          onChange={handleChange}
          value={values.nomenclature}
          //disabled={!newProduct}
          error={errors.nomenclature}
          isTouched={!!touched.nomenclature}
        />

        <FormInput
          id="barcode"
          title="Штрихкод"
          placeholder="Штрихкод"
          required
          type="text"
          name="barcode"
          onChange={handleChange}
          value={values.barcode}
          error={errors.barcode}
          isTouched={!!touched.barcode}
        />

        {/* Products's price */}
        <FormInput
          title="Себетоимость *"
          id="price"
          placeholder=""
          required
          type="number"
          min={0}
          name="price"
          onChange={handleChange}
          value={values.price}
          //disabled={!newProduct}
          error={errors.price}
          isTouched={!!touched.price}
        />
      </div>

      <hr className="mt-4 mb-2" />

      <div className="grid grid-cols-2 gap-x-8 gap-y-3">
        {/* Competitor URL */}
        <FormInput
          title="Комиссия маркетплейса, %"
          id="commision_rate"
          placeholder=""
          required
          type="number"
          name="commision_rate"
          onChange={handleChange}
          value={values.commision_rate}
          error={errors.commision_rate}
          isTouched={!!touched.commision_rate}
        />

        {/* Analytics URL */}
        <FormInput
          title="Логистика *"
          id="logistic_expense"
          placeholder=""
          required
          type="number"
          name="logistic_expense"
          onChange={handleChange}
          value={values.logistic_expense}
          error={errors.logistic_expense}
          isTouched={!!touched.logistic_expense}
        />

        {/* Marketplace fee */}
        <FormInput
          title="Снижение комиссии маркетплейса, %"
          id="commision_reduction"
          placeholder=""
          required
          type="number"
          max={100}
          min={0}
          name="commision_reduction"
          onChange={handleChange}
          value={values.commision_reduction}
          error={errors.commision_reduction}
          isTouched={!!touched.commision_reduction}
        />

        {/* usd to rub */}
        <FormInput
          title="Плановая цена для акции"
          id="planned_discount_price"
          placeholder=""
          required
          type="number"
          name="planned_discount_price"
          min={0}
          onChange={handleChange}
          value={values.planned_discount_price}
          error={errors.planned_discount_price}
          isTouched={!!touched.planned_discount_price}
        />

        {/* cny to rub */}
        <FormInput
          title="Текущая розничная цена"
          id="current_retail_price"
          placeholder=""
          required
          type="number"
          name="current_retail_price"
          min={0}
          onChange={handleChange}
          value={values.current_retail_price}
          error={errors.current_retail_price}
          isTouched={!!touched.current_retail_price}
        />

        {/* Marketplace product price */}
        <FormInput
          title="Текущая скидка на сайте, %"
          id="current_discount"
          placeholder=""
          required
          type="number"
          min={0}
          name="current_discount"
          onChange={handleChange}
          value={values.current_discount}
          error={errors.current_discount}
          isTouched={!!touched.current_discount}
        />

        {/* Product count */}
        <FormInput
          title="Загружаемая скидка для участия в акции, %"
          id="final_discount"
          placeholder=""
          required
          type="number"
          name="final_discount"
          min={0}
          onChange={handleChange}
          value={values.final_discount}
          error={errors.final_discount}
          isTouched={!!touched.final_discount}
        />

        <FormCheckboxInput
          title="Участие в акции"
          id="is_enabled"
          placeholder=""
          required
          name="is_enabled"
          onChange={handleChange}
          checked={values.is_enabled}
          error={errors.is_enabled}
          isTouched={!!touched.is_enabled}
        />
      </div>
    </form>
  );
}
