import React, { useLayoutEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

function YandexTokenPage() {
  useLayoutEffect(() => {
    const script = document.createElement('script');
    script.src =
      'https://yastatic.net/s3/passport-sdk/autofill/v1/sdk-suggest-token-with-polyfills-latest.js';
    script.onload = function () {
      (window as any).YaSendSuggestToken(process.env.REACT_APP_SITE_ORIGIN);
    };
    document.head.appendChild(script);
  });

  return <div>YandexTokenPage</div>;
}

export default YandexTokenPage;
