import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IUser {
  username: String;
  uid: number;
  name: String;
  orgId?: number;
}
interface AuthSliceState {
  auth: boolean;
  user: IUser | null;
}

const initialState: AuthSliceState = {
  auth: false,
  user: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUser>) => {
      state.auth = true;
      state.user = action.payload;
    },
    logout: (state, action: PayloadAction<void>) => {
      window.sessionStorage.removeItem('access_token');
      state.user = null;
      state.auth = false;
    },
  },
});

export const { setUser, logout } = authSlice.actions;

export default authSlice.reducer;
