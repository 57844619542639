import React from 'react';

function TableProductCostHint() {
  return (
    <div
      style={{
        fontSize: 14,
        fontWeight: 'normal',
        textTransform: 'none',
      }}
      className="text-left">
      <p>
        Находим быстро примерную цену у поставщика/ производителя Китай -
        alibaba.com.
      </p>
      <p>РФ:</p>
      <ul className="list-decimal list-inside">
        <li className="py-1">
          Московские рынки(Садовод, Люблино, Южные Ворота)
        </li>
        <li className="py-1">"Купить оптом [товар]" в Yandex.ru</li>
      </ul>
      <br />
      <p>
        Глубокую проработку себестомости будем делать позже при работе с
        поставщиками.
      </p>
    </div>
  );
}

function TableComissionHint() {
  return (
    <div
      className="py-3 text-left"
      style={{
        fontSize: 14,
        fontWeight: 'normal',
        textTransform: 'none',
      }}>
      <a
        target="_blank"
        href="https://seller.wildberries.ru/dynamic-product-categories/delivery"
        className="text-primary-500 hover:underline block mb-2">
        Wildberries
      </a>
      <a
        target="_blank"
        href="https://seller-edu.ozon.ru/commissions-tariffs/commissions-tariffs-ozon/komissii-tovary-uslugi"
        className="text-primary-500 hover:underline block">
        Ozon
      </a>
    </div>
  );
}

function TableLogisticsHint() {
  return (
    <div>
      <p
        style={{
          fontSize: 14,
          fontWeight: 'normal',
          textTransform: 'none',
        }}
        className="text-left">
        В формуле посчитана доставка по тарифу 4$ за 1 кг с официальными
        документами до МСК из Китая. <br />
        Для более точного расчёта вы можете кликнуть перейти на тарифы,
        прикинуть общий вес партии товара. И по выбранному тарифу умножаете цену
        за кг на вес. <br />
        Дальше в уроках мы рассмотрим подробный расчёт логистики из Китая.
      </p>
    </div>
  );
}

export { TableProductCostHint, TableComissionHint, TableLogisticsHint };
