import React from 'react';

function PaymentSuccessView() {
  return (
    <div>
      <section className="bg-white dark:bg-gray-900 w-full h-screen flex justify-center items-center">
        <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
          <div className="mx-auto max-w-screen-sm text-center">
            <h1 className="mb-8 text-3xl tracking-tight font-extrabold lg:text-5xl text-primary-600">
              Вы успешно оплатили премиум подписку
            </h1>
            <p className="mb-4 text-xl font-light text-gray-500 md:text-2xl">
              Спасибо, что оплатили подписку в Sellerator.
            </p>
            <p className="mb-4 text-xl font-light text-gray-500 md:text-2xl">
              Теперь вы сможете использовать функциональность вашей премиум
              подписки!
            </p>
            <a
              href="/account"
              className="mt-4 inline-flex text-white bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-8 py-4 text-center dark:focus:ring-primary-900 my-4">
              Перейти в личный кабинет
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PaymentSuccessView;
