import React, { useState, useEffect } from 'react';

interface IUsePaginationProps {
  handlePaginate: (take: number, page: number) => Promise<any>;
}
export default function usePaginate({ handlePaginate }: IUsePaginationProps) {
  const [paginationState, setPaginationState] = useState({
    take: 10,
    page: 0,
  });

  async function handleChangePage(dir: number) {
    const tmp = paginationState;
    tmp.page += dir;
    await handlePaginate(tmp.take, tmp.page);
    setPaginationState(tmp);
  }

  function resetState() {
    setPaginationState({ take: 10, page: 0 });
  }

  return {paginationState, handleChangePage, resetState}
}
