import { Button, ButtonProps } from 'flowbite-react';
import React from 'react';
import ConfirmDialog from './ConfirmDialog';

interface IDeleteButtonProps extends ButtonProps {
  acceptText?: string;
  title?: string;
  onAccept: () => Promise<void>;
  isLoading?: boolean;
  confirmOpen: boolean;
  setConfirmOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function DeleteButton({
  acceptText,
  isLoading,
  title,
  onAccept,
  setConfirmOpen,
  confirmOpen,
  ...rest
}: IDeleteButtonProps) {
  return (
    <div>
      <Button {...rest} onClick={() => setConfirmOpen(true)} />
      <ConfirmDialog
        open={!!confirmOpen}
        onClose={() => setConfirmOpen(false)}
        title={title ?? 'Удалить'}
        info={acceptText ?? 'Вы уверены, что хотите удалить?'}
        actionButton={
          <Button
            className="bg-red-500 text-white hover:bg-red-400 enabled:hover:bg-red-400"
            isProcessing={isLoading}
            disabled={isLoading}
            onClick={onAccept}>
            Удалить
          </Button>
        }
      />
    </div>
  );
}

export default DeleteButton;
